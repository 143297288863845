import { useState, useEffect } from "react";

const useClickCounter = () => {
  const [clickedcounter, setClickedcounter] = useState(0);
  const [finalcounter, setFinalcounter] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(clickedcounter);
      if (clickedcounter === 0) {
        console.log("Once");
        setFinalcounter("Once");
      }

      setClickedcounter(0);
    }, 250);

    if (clickedcounter === 1) {
      console.log("Twice");
      setFinalcounter("Twice");
    }

    return () => clearTimeout(timer);
  }, [clickedcounter]);

  return () => {
    setClickedcounter((prevvalue) => prevvalue + 1);
    return finalcounter;
  };
};

export default useClickCounter;
