//no functions just useffect usestates and DOM

import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "./Firebase.js";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
  collectionGroup,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import "./Connect.css";
import useClickCounter from "./useClickCounter.js";

function Connect({ ImageUrl }) {
  const [droptext, setDroptext] = useState("");
  const [posts, setPosts] = useState([]);
  const [showdrop, setShowdrop] = useState(true);
  const [send, setSend] = useState("");
  const [optionid, setOptionId] = useState("");
  const [redropid, setRedropid] = useState([]);
  const [unredropid, setUnredropid] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [back, setBack] = useState(false);
  const [clickedcomment, setClickedcomment] = useState("");
  const [alldisplay, setAlldisplay] = useState([]);
  const [redroppoint, setRedroppoint] = useState(false);
  const [unredroppoint, setUnredroppoint] = useState(false);
  const [userinfo, setUserinfo] = useState("");
  const [simdisplays, setSimdisplays] = useState([]);
  const [tempfiles, setTempfiles] = useState([]);
  const [fullscreened, setFullscreened] = useState("");
  const [paused, setPaused] = useState("");
  const [controlon, setControlon] = useState(false);
  const [showtime, setShowtime] = useState(false);
  const [videolocation, setVideolocation] = useState(0);
  const [videotime, setVideotime] = useState(0);
  const [progresspercent, setProgresspercent] = useState(0);
  const [twominutevideo, setTwominutevideo] = useState(0);
  const [videorefload, setVideorefload] = useState(false);
  const [blockid, setBlockid] = useState("");
  const [commentbackid, setCommentbackid] = useState("");
  const [disablepress, setDisablepress] = useState(false);
  const [promotion, setPromotion] = useState(true);

  //const dropref = useRef(true);
  const droppedvideoref = useRef([]);
  const scrollref = useRef(null);
  //const dropref = useRef(true);
  const Clicker = useClickCounter();

  useEffect(() => {
    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Rank", "!=", false)
    );

    const querySnapshot = getDocs(
      query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
    ).then((querySnapshot) => {
      let tempposts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        /* year: doc.data().Timestamp.toDate().getFullYear(),
            month: ("0" + (doc.data().Timestamp.toDate().getMonth() + 1)).slice(
              -2
            ),
            date: ("0" + doc.data().Timestamp.toDate().getDate()).slice(-2),
            hour: doc.data().Timestamp.toDate().getHours(),
            minutes: ("0" + doc.data().Timestamp.toDate().getMinutes()).slice(
              -2
            ),*/

        id: doc.id,
      }));

      let finaltempposts = tempposts.filter(
        (post) =>
          !post.Redropper_id ||
          (!post.Sender_id && post.Redropper_id === post.Directory)
      );

      let uniqueranks = [];
      finaltempposts.forEach((post) => {
        uniqueranks.push(post.Rank);
        if (!post.Redropper_id) {
          post.year = post.Timestamp.toDate().getFullYear();
          post.month = ("0" + (post.Timestamp.toDate().getMonth() + 1)).slice(
            -2
          );
          post.date = ("0" + post.Timestamp.toDate().getDate()).slice(-2);
          post.hour = post.Timestamp.toDate().getHours();
          post.minutes = ("0" + post.Timestamp.toDate().getMinutes()).slice(-2);
        }
      });
      console.log([...new Set(uniqueranks)]);
      let finaluniqueranks = [...new Set(uniqueranks)];
      let rankindex = [];
      finaluniqueranks.forEach((finaluniquerank) => {
        rankindex.push(
          finaltempposts.findIndex(
            (element) => finaluniquerank === element.Rank
          )
        );
      });

      console.log(rankindex);
      let finalposts = [];
      let num = 1;
      rankindex.forEach((index) => {
        if (rankindex[num] - index >= 2) {
          finalposts.push(finaltempposts[index], finaltempposts[index + 1]);
        } else if (rankindex[num] - index < 2) {
          finalposts.push(finaltempposts[index]);
        } else {
          console.log(rankindex[num] - index);
          if (finaltempposts.length - index >= 2) {
            finalposts.push(finaltempposts[index], finaltempposts[index + 1]);
          } else {
            finalposts.push(finaltempposts[index]);
          }
        }

        num++;
      });
      console.log(finalposts);

      setTimeout(() => {
        setPosts(finalposts);
        droppedvideoref.current = new Array(finalposts.length).fill(null);
      });
    });

    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 1000);
  }, []);

  useEffect(() => {
    if (showtime) {
      setShowtime(false); /*
      setTimeout(() => {
        setShowtime(false);
      }, 200);*/
    }
  }, [showtime]);

  useEffect(() => {
    console.log(droppedvideoref.current);
    let filteredref = droppedvideoref.current.filter(
      (element) => element !== null
    );
    console.log(filteredref);
    if (filteredref.length > 0) {
      let num = -1;
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.95,
      };
      const observer = new IntersectionObserver((entries) => {
        console.log(entries);
        console.log(entries[0]);

        entries.forEach((entry) => {
          console.log(entry);

          if (entry.isIntersecting) {
            console.log(entry.target);

            entry.target.play();
            num++;
          } else {
            if (num > -1) {
              //might need to pause other 2 3 4 video
              console.log(num);
              //what if the video is already paused
              try {
                if (!entry.target.paused) {
                  entry.target.pause();
                } else if (!filteredref[num][1]?.paused) {
                  filteredref[num][1].pause();
                } else if (!filteredref[num][2]?.paused) {
                  filteredref[num][2].pause();
                } else {
                  filteredref[num][3].pause();
                }
              } catch (e) {
                console.log(e);
                //"Its already paused","move on to the rest of function"
              }

              num--;
            }
          }
        });
      }, options);

      console.log(observer);

      filteredref.forEach((element) => {
        let observertarget = Array.isArray(element) ? element[0] : element;

        observer.observe(observertarget);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [videorefload]);

  useEffect(() => {
    if (back === true) {
      console.log(simdisplays);
      console.log(simdisplays.at(-1));
      const element = scrollref.current;
      console.log(element);
      element.scrollIntoView();
      if (clickedcomment) setClickedcomment("");
      else if (commentbackid) setCommentbackid("");
      else if (alldisplay.length === 1) setAlldisplay([]);
      else setAlldisplay(alldisplay.slice(0, -1));
      setBack(false);
    }
  }, [back]);

  const displayback = async () => {
    if (alldisplay.length === 1) {
      setSimdisplays([]);
      setShowdrop(true);
      setTimeout(() => {
        droppedvideoref.current = new Array(posts.length).fill(null);
        setBack(true);
        setVideorefload(!videorefload);
      });
    } else {
      setSimdisplays(simdisplays.slice(0, -1));
      setTimeout(() => {
        droppedvideoref.current = new Array(simdisplays.at(-2).length + 1).fill(
          null
        );
        console.log(droppedvideoref.current);
        setBack(true);
        setVideorefload(!videorefload);
      });
    }
  };

  const display = async (post) => {
    setAlldisplay([...alldisplay, post]);

    setShowdrop(false);

    const dropsRef = query(
      collectionGroup(db, "Comments"),
      where("Directory", "==", post.id)
    );

    getDocs(
      query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
    ).then((querySnapshot) => {
      let simposts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(simposts);

      simposts.map((simpost) => {
        if (!simpost.Redropper_id) {
          simpost.year = simpost.Timestamp.toDate().getFullYear();
          simpost.month = (
            "0" +
            (simpost.Timestamp.toDate().getMonth() + 1)
          ).slice(-2);
          simpost.date = ("0" + simpost.Timestamp.toDate().getDate()).slice(-2);
          simpost.hour = simpost.Timestamp.toDate().getHours();
          simpost.minutes = (
            "0" + simpost.Timestamp.toDate().getMinutes()
          ).slice(-2);
        }

        return simpost;
      });
      console.log(simposts);

      setSimdisplays([...simdisplays, simposts]);
      setTimeout(() => {
        droppedvideoref.current = new Array(simposts.length + 1).fill(null);
        console.log(droppedvideoref.current);
      });
    });

    window.scroll({ top: 0 });
    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  };

  const fullscreen = (e) => {
    if (!fullscreened) {
      console.log(e.target.id);
      console.log(e.detail);

      let matchtarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      console.log(matchtarget);
      setFullscreened(true);
    } else if (!controlon) {
      setControlon(true);

      setVideotime(
        Math.floor(
          document.fullscreenElement.children[0].children[0].children[1]
            .currentTime
        )
      );

      setVideolocation(3);

      setShowtime(true);
    } else {
      setControlon(false);
    }
  };

  const Fullscreencancel = () => {
    setFullscreened(false);
    setControlon(false);
    document.exitFullscreen();

    setShowtime(true);
  };

  const fullscreenside = (e) => {
    let clickcount = Clicker();
    if (clickcount === "Once") {
      console.log("YOONE");

      if (!controlon) {
        setControlon(true);
        setVideolocation(3);

        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[1]
              .currentTime
          )
        );

        setTimeout(() => {
          setVideolocation(4);
        }, 100);
      } else {
        setControlon(false);
      }
    }

    if (clickcount === "Twice") {
      console.log(e.target.id.split(","));
      let tempid = e.target.id.split(",");
      let matchtarget = tempfiles.findIndex(
        (element) => tempid[0] === element.id
      );
      console.log(matchtarget);
      console.log(tempid[1]);
      console.log("YOTWO");
      console.log("durationaction");

      let timeadjustnumber;
      if (tempid[1] === "left") {
        timeadjustnumber = 5;
      } else {
        timeadjustnumber = -5;
      }
    }
  };

  const Videotimechange = (e) => {
    console.log(e.target.value);
    let matchtarget = tempfiles.findIndex(
      (element) => e.target.id === element.id
    );

    let timeadjust = parseInt(e.target.value) - videotime;

    setVideotime(parseInt(e.target.value));

    setShowtime(true);
  };

  const Videotimeupdate = (e) => {
    console.log(e.target.id);

    if (controlon || !fullscreened) setShowtime(true); //setTimeout(()=>{setShowtime(true)},100/*200*/);

    let fullscreenedid;

    if (controlon) {
      fullscreenedid = document.fullscreenElement?.children[0].children[0]
        .children[4]?.id
        ? document.fullscreenElement.children[0].children[0].children[4].id
        : "";

      if (e.target.id === fullscreenedid) {
        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[4]
              .currentTime
          )
        );
      }
    }
  };

  const Droppedvideotimeupdate = (e, index) => {
    console.log(e.target.id);
    console.log(index);
    console.log(droppedvideoref.current[index]);
    console.log(droppedvideoref.current[index].length);
    console.log(droppedvideoref.current[index][0].id);

    if (Array.isArray(droppedvideoref.current[index])) {
      //this finds which video is being played right now
      let playtarget = droppedvideoref.current[index].findIndex(
        (element) => element.id === e.target.id
      );
      console.log(playtarget);
      console.log(playtarget !== droppedvideoref.current[index].length - 1);
      if (playtarget !== droppedvideoref.current[index].length - 1) {
        droppedvideoref.current[index][playtarget + 1].play();
      } else {
        droppedvideoref.current[index][0].play();
      }
    } else {
      droppedvideoref.current[index].play();
    }

    //put the video to currentime 0?
  };

  const imagehtml = (post, post1, post2) => {
    const images = {
      width: 0,
      height: 0,
      marginLeft: 0,
      marginTop: 0,
    };
    //if (post1.id === ){}

    images.width = post2?.path
      ? 130
      : post2?.path !== "" && post2?.path !== undefined
      ? 110
      : ((post1?.path !== "" && post1?.path !== undefined) ||
          post?.path !== "") &&
        150;
    images.height =
      post2?.path !== "" && post2?.path !== undefined
        ? 95
        : post1?.path !== "" && post2?.path === ""
        ? 195
        : post?.path !== "" && 150;

    images.marginLeft =
      post1?.path !== "" && post2?.path === "" ? 20 : post?.path !== "" && 5;
    images.marginTop = post?.path !== "" && 20;

    return images;
  };

  const videohtml = (post, post1, post2) => {
    /*
      controls: tempfiles.length < 2 && true,
      width: fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175,
      height: fullscreened ? "40%" : tempfiles.length === 1 ? "200" : 175,
      playsInline,
      autoPlay,
      muted,
      loop,
      */

    const videos = {
      objectFit: "cover",
      borderRadius: 20,
      marginLeft:
        !fullscreened && post1?.path !== "" && post2?.path === ""
          ? 20
          : post?.path && !fullscreened && 5,
      marginTop: post?.path !== "" && 20,
      position: fullscreened && "absolute",
      top: fullscreened && "30%",
    };
    return videos;
  };

  const determinevideoref = (post, post1, post2, index) => {
    let finalvideos = [];
    let num = 0;

    let videoornot = [];

    post === "videos" && videoornot.push("videos");
    post1 === "videos" && videoornot.push("videos");
    post2 === "videos" && videoornot.push("videos");

    if (videoornot.length === 1) {
      finalvideos[0] = droppedvideoref.current[index];
    } else {
      for (const video of videoornot) {
        if (video === "videos") {
          finalvideos.push(droppedvideoref.current[index][num]);
          num++;
        }
      }
    }
    return finalvideos;
  };

  const determinevideoindex = (file1, file2, file4, index) => {
    if (file1 === "videos" && file2 === "videos")
      return droppedvideoref.current[index][2];
    else if (file1 === "videos" || file2 === "videos")
      return droppedvideoref.current[index][1];
    else if (file4 === "videos") return droppedvideoref.current[index][0];
    else return droppedvideoref.current[index];
  };

  const determinefourthvideoindex = (file1, file2, file3, index) => {
    let allfiles = [file1, file2, file3];
    let num = 0;
    for (const file of allfiles) {
      if (file === "videos") num++;
    }
    if (num > 0) return droppedvideoref.current[index][num];
    else return droppedvideoref.current[index];
  };

  return (
    <div>
      {/* {promotion && location.state !== "SignedUp" ? (
        <div>
          <FullScreenPromotion />
        </div>}
      {/*) : (}
        {/*<>*/}

      {/*///////////////////////////////////////////////*/}
      {/*<div
            style={{
              width: "100%",
              height: 56,
              display: "block",
              background: "#005280",
            }}
          ></div>

          <div className="topline">
            {(alldisplay.length > 0 || clickedcomment) && (
              <img
                style={{ zIndex: 200, marginLeft: 5 }}
                src="/Arrow1.svg"
                onClick={clickedcomment ? commentcancel : displayback}
              />
            )}

            {alldisplay.length === 0 && !clickedcomment && (
              <>
               
                  <img
                    style={{
                      marginLeft: 5,
                      marginTop: 2,
                      background: "linear-gradient(#121112, #42b1e0)",
                    }}
                    className="current"
                    src={userinfo.Profile_image}
                  />
             

                <img
                  src="./Planet1.svg"
                  className="Planet"
                  style={{ width: 30 }}
                />
              </>
            )}

            <img src="/Connect_icon.svg" style={{ height: 50 }} />

            {alldisplay.length === 0 && !clickedcomment && (
              <img
                src="./DropView.svg"
                className="dropb"
                style={{
                  width: 28,
                }}
                onClick={turn}
              />
            )}

            <img
              style={{ width: 40, height: 50, marginRight: 5 }}
              src="./option.svg"
            />
          
          </div>
*/}

      <div>
        {showdrop ? (
          <>
            {posts.map((post, index) => (
              <div
                className="drops"
                key={post.id}
                ref={
                  (clickedcomment.id === post.id && scrollref) ||
                  alldisplay[0]?.id === post.id
                    ? scrollref
                    : null
                }
                style={{
                  height:
                    (post.image2 && 310) ||
                    ((post.image1 ||
                      (post.file1?.path !== "" &&
                        post.file1?.path !== undefined)) &&
                      330),
                }}
              >
                <div className="postlinetop">
                  <img
                    className={
                      post.Profile_image === "/Profile3.svg"
                        ? "current3"
                        : "current1"
                    }
                    src={post.Profile_image}
                  />

                  {(post.image1 || post.file1?.path) && (
                    <div
                      style={{
                        fontSize: post.text.length >= 142 && 10.5,
                        //width: post.text.length >= 142 && "80%",
                        //marginTop: post.text.length >= 142 && 8,
                        position: optionid[0] === post.id && "absolute",
                        left:
                          optionid[0] === post.id && post.text.length >= 23
                            ? "20%"
                            : `${49.5 - post.text.length}%`,
                      }}
                      className={post.text.length >= 23 ? "text" : "text1"}
                    >
                      {post.text}
                    </div>
                  )}
                  {optionid[0] !== post.id && (
                    <img
                      className="postoption"
                      src="/DropOption.svg"
                      id={[post.id, post.Directory]}
                    />
                  )}

                  {deleteid === post.id && (
                    <span className="Delete">
                      <button id={post.id}>〇</button>
                      <button onClick={(e) => setDeleteid("")}>X</button>
                    </span>
                  )}
                </div>

                <span className="textline">
                  <span
                    className="stylerank"
                    style={{
                      marginLeft:
                        post.Rank <= 9
                          ? 19.4
                          : post.Rank === 11
                          ? 16
                          : post.Rank <= 99
                          ? 14.5
                          : post.Rank > 99 && 10,
                      flex:
                        post.text.length >= 25
                          ? 0.18
                          : post.text.length === 20
                          ? 0.14
                          : 0.5,
                      marginTop:
                        post.text.length > 65 &&
                        (post.image1 || post.file1?.path)
                          ? -25
                          : 5,
                    }}
                  >
                    {post.Rank}
                  </span>
                  {!post.image1 && !post.file1?.path && (
                    <div className={post.text.length >= 23 ? "text" : "text1"}>
                      {post.text}
                    </div>
                  )}
                </span>

                <div
                  className="alldrops"
                  //id={[post.id, post.Directory, post.Comment_Counter]}
                  //onClick={
                  //  blockid !== post.id ? (e) => display(post) : undefined
                  // }
                  style={{
                    height:
                      (post.image2 && 275) ||
                      ((post.image1 || post.file1?.path) && 295),
                  }}
                ></div>

                <div
                  style={{
                    marginLeft:
                      !post.file3?.path && post.file2?.path
                        ? 40
                        : post.file3?.path && 60,
                    marginTop: post.file3?.path && -10,
                    justifyContent:
                      post.file3?.path && !post.file4?.path && "flex-start",
                  }}
                  className="postphotoline"
                >
                  {post.file1?.filetype === "videos" ? (
                    <>
                      <video
                        controls={!post.file2.path && true}
                        width={
                          fullscreened
                            ? "100%"
                            : post.file2.path === ""
                            ? 200
                            : post.file4.path !== ""
                            ? 130
                            : 150
                        }
                        height={
                          fullscreened
                            ? "40%"
                            : post.file2.path === ""
                            ? 200
                            : post.file4?.path !== ""
                            ? 95
                            : post.file3.path !== ""
                            ? 195
                            : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[index] = element)
                        }
                        onTimeUpdate={Videotimeupdate}
                        onEnded={
                          post.file2.path
                            ? (e) => Droppedvideotimeupdate(e, index)
                            : undefined
                        }
                        playsInline
                        muted
                        loop={!post.file2.path && true}
                        // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                        id={post.file1.id}
                        style={videohtml(post?.file2, post.file3, post.file4)}
                      >
                        <source type="video/mp4" src={post.file1?.path} />
                      </video>
                      {console.log(droppedvideoref.current)}
                      {!isNaN(
                        Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]?.duration
                          : droppedvideoref.current[index]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: !post.file2.filetype
                              ? "18%"
                              : !post.file3.filetype
                              ? "27%"
                              : post.file4.filetype
                              ? "46%"
                              : "16%",
                            left:
                              !post.file2.filetype || post.file4.filetype
                                ? "25%"
                                : !post.file3.filetype
                                ? "17%"
                                : "23%",
                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              (Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]
                                : droppedvideoref.current[index]
                              )?.currentTime / 60
                            )}
                          :
                          {(Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.currentTime < 10 && 0}
                          {(Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.currentTime >= 60
                            ? Math.floor(
                                (Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                (Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              (Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]
                                : droppedvideoref.current[index]
                              )?.duration / 60
                            )}
                          :
                          {(Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.duration < 10 && 0}
                          {(Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.duration >= 60
                            ? Math.floor(
                                (Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.duration % 60
                              )
                            : Math.floor(
                                (Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.duration
                              )}
                        </span>
                      )}
                    </>
                  ) : (
                    post.file1?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={post.file1?.path}
                        id={post.file1?.id}
                        style={imagehtml(post.file2, post.file3, post.file4)}
                      />
                    )
                  )}

                  {!post.file4?.path ? (
                    <div
                      style={{
                        display:
                          post.file3?.path && !post.file4?.path && "flex",
                        flexDirection:
                          post.file3?.path && !post.file4?.path && "column",
                      }}
                    >
                      {post.file2?.filetype === "videos" ? (
                        <>
                          {console.log(droppedvideoref.current)}
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : post.file3?.path
                                ? 110
                                : 150
                            }
                            height={
                              fullscreened ? "40%" : post.file3?.path ? 95 : 150
                            }
                            ref={(element) =>
                              (droppedvideoref.current[index] =
                                post.file1.filetype === "videos"
                                  ? [droppedvideoref.current[index], element]
                                  : element)
                            }
                            playsInline
                            muted
                            id={post.file2.id}
                            style={videohtml(post.file1)}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, index)}
                          >
                            <source type="video/mp4" src={post.file2?.path} />
                          </video>

                          {droppedvideoref?.current[index] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]?.duration
                                : droppedvideoref.current[index]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: !post.file3.filetype ? "27%" : "46%",
                                  left: !post.file3.filetype ? "59%" : "65%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (post.file1.filetype === "videos"
                                      ? droppedvideoref?.current[index][1]
                                      : Array.isArray(
                                          droppedvideoref.current[index]
                                        )
                                      ? droppedvideoref?.current[index][0]
                                      : droppedvideoref?.current[index]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime < 10 && 0}
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (post.file1.filetype === "videos"
                                      ? droppedvideoref?.current[index][1]
                                      : Array.isArray(
                                          droppedvideoref.current[index]
                                        )
                                      ? droppedvideoref?.current[index][0]
                                      : droppedvideoref?.current[index]
                                    )?.duration / 60
                                  )}
                                :
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration < 10 && 0}
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        post.file2?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={post.file2?.path}
                            id={post.file2?.id}
                            style={
                              post.file3?.path
                                ? {
                                    ...imagehtml(post.file1),
                                    width: 110,
                                    height: post.file3.path && 95,
                                  }
                                : imagehtml(post.file1)
                            }
                          />
                        )
                      )}

                      {post.file3?.filetype === "videos" ? (
                        <>
                          <video
                            width={fullscreened ? "100%" : 110}
                            height={fullscreened ? "40%" : 95}
                            ref={(element) =>
                              (droppedvideoref.current[index] =
                                post.file1.filetype === "videos" &&
                                post.file2.filetype === "videos"
                                  ? [
                                      droppedvideoref.current[index][0],
                                      droppedvideoref.current[index][1],
                                      element,
                                    ]
                                  : post.file1.filetype !== "videos" &&
                                    post.file2.filetype !== "videos"
                                  ? element
                                  : post.file1.filetype !== "videos" ||
                                    post.file2.filetype !== "videos"
                                  ? [droppedvideoref?.current[index], element]
                                  : [])
                            }
                            playsInline
                            muted
                            id={post.file3.id}
                            style={{
                              ...videohtml(post.file1),
                              marginTop: 4,
                            }}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, index)}
                          >
                            <source type="video/mp4" src={post.file3?.path} />
                          </video>

                          {droppedvideoref?.current[index] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]?.duration
                                : droppedvideoref.current[index]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "16%",
                                  left: "65%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      post.file1.filetype,
                                      post.file2.filetype,
                                      post.file4.filetype,
                                      index
                                    )?.currentTime / 60
                                  )}
                                :
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime < 10 && 0}
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      post.file1.filetype,
                                      post.file2.filetype,
                                      post.file4.filetype,
                                      index
                                    )?.duration / 60
                                  )}
                                :
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration < 10 && 0}
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        post.file3?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={post.file3?.path}
                            id={post.file3?.id}
                            style={{
                              ...imagehtml(post.file1),
                              width: !post.file4?.path ? 110 : 130,
                              height: 95,
                              marginTop: 4,
                            }}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    <>
                      {post.file2?.filetype === "videos" ? (
                        <>
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : post.file4.path !== ""
                                ? 130
                                : post.file3.path
                                ? 110
                                : 150
                            }
                            height={
                              fullscreened ? "40%" : post.file3.path ? 95 : 150
                            }
                            ref={(element) =>
                              (droppedvideoref.current[index] =
                                post.file1.filetype === "videos"
                                  ? [droppedvideoref.current[index], element]
                                  : element)
                            }
                            playsInline
                            muted
                            id={post.file2.id}
                            style={videohtml(post.file1)}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, index)}
                          >
                            <source type="video/mp4" src={post.file2?.path} />
                          </video>

                          {droppedvideoref?.current[index] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]?.duration
                                : droppedvideoref.current[index]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "46%",
                                  left: "62%",

                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (post.file1.filetype === "videos"
                                      ? droppedvideoref?.current[index][1]
                                      : Array.isArray(
                                          droppedvideoref.current[index]
                                        )
                                      ? droppedvideoref?.current[index][0]
                                      : droppedvideoref?.current[index]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime < 10 && 0}
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (post.file1.filetype === "videos"
                                      ? droppedvideoref?.current[index][1]
                                      : Array.isArray(
                                          droppedvideoref.current[index]
                                        )
                                      ? droppedvideoref?.current[index][0]
                                      : droppedvideoref?.current[index]
                                    )?.duration / 60
                                  )}
                                :
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration < 10 && 0}
                                {(post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        post.file2?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={post.file2?.path}
                            id={post.file2?.id}
                            style={
                              post.file3?.path
                                ? {
                                    ...imagehtml(post.file1),
                                    width:
                                      (post.file4?.path && 130) ||
                                      (post.file3.path && 110),
                                    height: post.file3.path && 95,
                                  }
                                : imagehtml(post.file1)
                            }
                          />
                        )
                      )}
                      {post.file3?.filetype === "videos" ? (
                        <>
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : post.file4.path !== ""
                                ? 130
                                : 110
                            }
                            height={fullscreened ? "40%" : 95}
                            ref={(element) =>
                              (droppedvideoref.current[index] =
                                post.file1.filetype === "videos" &&
                                post.file2.filetype === "videos"
                                  ? [
                                      droppedvideoref.current[index][0],
                                      droppedvideoref.current[index][1],
                                      element,
                                    ]
                                  : post.file1.filetype !== "videos" &&
                                    post.file2.filetype !== "videos"
                                  ? element
                                  : post.file1.filetype !== "videos" ||
                                    post.file2.filetype !== "videos"
                                  ? [droppedvideoref?.current[index], element]
                                  : [])
                            }
                            playsInline
                            muted
                            id={post.file3.id}
                            style={{
                              ...videohtml(post.file1),

                              marginTop: 4,
                            }}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, index)}
                          >
                            <source type="video/mp4" src={post.file3?.path} />
                          </video>

                          {droppedvideoref?.current[index] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]?.duration
                                : droppedvideoref.current[index]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "16%",
                                  left: "26%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      post.file1.filetype,
                                      post.file2.filetype,
                                      post.file4.filetype,
                                      index
                                    )?.currentTime / 60
                                  )}
                                :
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime < 10 && 0}
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      post.file1.filetype,
                                      post.file2.filetype,
                                      post.file4.filetype,
                                      index
                                    )?.duration / 60
                                  )}
                                :
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration < 10 && 0}
                                {determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        post.file3?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={post.file3?.path}
                            id={post.file3?.id}
                            style={{
                              ...imagehtml(post.file1),
                              width: !post.file4?.path ? 110 : 130,
                              height: 95,
                              marginTop: 4,
                            }}
                          />
                        )
                      )}
                    </>
                  )}

                  {post.file4?.filetype === "videos" ? (
                    <>
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : post.file4.path !== ""
                            ? 130
                            : 110
                        }
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[index] =
                            post.file1.filetype === "videos" &&
                            post.file2.filetype === "videos" &&
                            post.file3.filetype === "videos"
                              ? [
                                  droppedvideoref.current[index][0],
                                  droppedvideoref.current[index][1],
                                  droppedvideoref.current[index][2],
                                  element,
                                ]
                              : post.file1.filetype !== "videos" &&
                                post.file2.filetype !== "videos" &&
                                post.file3.filetype !== "videos"
                              ? element
                              : post.file1.filetype !== "videos" ||
                                post.file2.filetype !== "videos" ||
                                post.file3.filetype !== "videos"
                              ? [
                                  ...determinevideoref(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  ),
                                  element,
                                ]
                              : [])
                        }
                        playsInline
                        muted
                        id={post.file4.id}
                        style={{
                          ...videohtml(post.file1),

                          marginTop: 4,
                        }}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, index)}
                      >
                        <source type="video/mp4" src={post.file4?.path} />
                      </video>

                      {droppedvideoref?.current[index] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]?.duration
                            : droppedvideoref.current[index]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: "16%",
                              left: "61.5%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                determinefourthvideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file3.filetype,
                                  index
                                )?.currentTime / 60
                              )}
                            :
                            {determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.currentTime < 10 && 0}
                            {determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.currentTime >= 60
                              ? Math.floor(
                                  determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.currentTime
                                )}
                            <span style={{ marginLeft: 5, marginRight: 5 }}>
                              /
                            </span>
                            {0 +
                              Math.floor(
                                determinefourthvideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file3.filetype,
                                  index
                                )?.duration / 60
                              )}
                            :
                            {determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.duration < 10 && 0}
                            {determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.duration >= 60
                              ? Math.floor(
                                  determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    post.file4?.filetype === "images" && (
                      <img
                        src={post.file4?.path}
                        className="postphoto"
                        id={post.file4?.id}
                        style={{
                          ...imagehtml(post.file1),
                          width: 130,
                          height: 95,
                          marginTop: 4,
                        }}
                      />
                    )
                  )}

                  {post.image1 && (
                    <img
                      className="postphoto"
                      src={post.image1}
                      style={{
                        width: post.image2 && 150,
                        height: post.image2 && 150,
                        marginLeft: post.image2 && 50,
                        marginTop: post.image2 && 20,
                      }}
                    />
                  )}
                </div>
                <div className="date">
                  {post.year}/{post.month}/{post.date}
                  <span className="Timesplit">|</span>
                  <span className="Time">
                    {post.hour}:{post.minutes}
                  </span>
                </div>
                <img
                  className="Rankmarked"
                  src="/RankMark.svg"
                  style={{ top: (post.image1 || post.file1?.path) && 51 }}
                />

                <span className="dropoption">
                  <span className="commentgroup">
                    <img
                      className="commenttap"
                      src="./Comment1.svg"
                      id={[post.id, post.Directory]}
                    />
                    <span className="commentcount">
                      {post.Comment_Counter == 0 ? null : post.Comment_Counter}
                    </span>
                  </span>
                  <span className="redropline">
                    {post.Redropped == "done" ? (
                      <img
                        src="./Redrop.svg"
                        className="Redrop"
                        //couldnt we combine the id for redrop and askunredrop

                        id={[post.id, post.Directory]}
                      />
                    ) : (
                      <img
                        className="Redrop"
                        src="./PlaneRedrop4.svg"
                        id={[post.id, post.Directory]}
                      />
                    )}
                    <span className="commentcount">
                      {post.Redropped_Counter == 0
                        ? null
                        : post.Redropped_Counter}
                    </span>
                  </span>
                </span>

                {redroppoint && post.id === redropid[0] && (
                  <span className="Pselect">
                    {
                      <button
                        style={{
                          left: "30%",
                          fontSize: 9,
                          color: "white",
                          bottom: post.file1?.path ? "3%" : "5%",
                        }}
                      >
                        リドロップ
                      </button>
                    }
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        marginLeft: 85,
                        bottom: post.file1?.path ? "3%" : "5%",
                      }}
                      onClick={(e) => {
                        setRedroppoint(false);
                      }}
                    >
                      キャンセル
                    </button>
                  </span>
                )}
                {unredroppoint && post.id === unredropid[0] && (
                  <span style={{ bottom: "5%" }} className="Pselect">
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        color: "white",
                        bottom: post.file1?.path ? "3%" : "5%",
                      }}
                    >
                      リドロップ解除
                    </button>
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        marginLeft: 85,
                        bottom: post.file1?.path ? "3%" : "5%",
                      }}
                    >
                      キャンセル
                    </button>
                  </span>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            {!clickedcomment ? (
              <>
                <div
                  className="drops"
                  id={alldisplay.at(-1).id}
                  style={{
                    height:
                      (alldisplay.at(-1).image2 && 310) ||
                      ((alldisplay.at(-1).image1 ||
                        (alldisplay.at(-1).file1?.path !== "" &&
                          alldisplay.at(-1).file1?.path !== undefined)) &&
                        330),
                  }}
                >
                  <div className="postlinetop">
                    <img
                      className={
                        alldisplay.at(-1).Profile_image === "/Profile3.svg"
                          ? "current3"
                          : "current1"
                      }
                      src={alldisplay.at(-1).Profile_image}
                    />

                    {(alldisplay.at(-1).image1 ||
                      alldisplay.at(-1).file1?.path) && (
                      <div
                        style={{
                          fontSize:
                            (
                              alldisplay.at(-1).text ||
                              alldisplay.at(-1).Comment
                            )?.length >= 142 && 10.5,
                          position:
                            optionid[0] === alldisplay.at(-1).id && "absolute",
                          left:
                            optionid[0] === alldisplay.at(-1).id &&
                            (
                              alldisplay.at(-1).text ||
                              alldisplay.at(-1).Comment
                            )?.length >= 23
                              ? "20%"
                              : `${
                                  49.5 -
                                  (
                                    alldisplay.at(-1).text ||
                                    alldisplay.at(-1).Comment
                                  )?.length
                                }%`,
                        }}
                        className={
                          (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                            ?.length >= 23
                            ? "text"
                            : "text1"
                        }
                      >
                        {alldisplay.at(-1).text || alldisplay.at(-1).Comment}
                      </div>
                    )}

                    {optionid[0] !== alldisplay.at(-1).id && (
                      <img
                        src="/DropOption.svg"
                        className="postoption"
                        id={[
                          alldisplay.at(-1).id,
                          alldisplay.at(-1).Sender_id
                            ? alldisplay.at(-1).Sender_id
                            : alldisplay.at(-1).Directory,
                        ]}
                      />
                    )}

                    {!deleteid && optionid[0] === alldisplay.at(-1).id && (
                      <span className="Delete">
                        <button
                          onClick={(e) => {
                            setOptionId("");
                            setDeleteid("");
                          }}
                        >
                          Cancel
                        </button>
                      </span>
                    )}
                  </div>
                  <span className="textline">
                    <span
                      className="stylerank"
                      style={{
                        marginLeft:
                          alldisplay.at(-1).Rank <= 9
                            ? 19.4
                            : alldisplay.at(-1).Rank === 11
                            ? 16
                            : alldisplay.at(-1).Rank <= 99
                            ? 14.5
                            : alldisplay.at(-1).Rank > 99 && 10,
                        flex:
                          (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                            ?.length >= 25
                            ? 0.18
                            : (
                                alldisplay.at(-1).text ||
                                alldisplay.at(-1).Comment
                              )?.length === 20
                            ? 0.14
                            : 0.5,
                        marginTop:
                          (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                            ?.length > 65 &&
                          (alldisplay.at(-1).image1 ||
                            alldisplay.at(-1).file1?.path)
                            ? -25
                            : null,
                      }}
                    >
                      {alldisplay.at(-1).Rank}
                    </span>
                    {!alldisplay.at(-1).image1 &&
                      !alldisplay.at(-1).file1?.path && (
                        <div
                          className={
                            alldisplay.at(-1).text?.length >= 23 ||
                            alldisplay.at(-1).Comment?.length >= 23
                              ? "text"
                              : "text1"
                          }
                        >
                          {alldisplay.at(-1)?.text ||
                            alldisplay.at(-1)?.Comment}
                        </div>
                      )}
                  </span>
                  <div
                    className="alldrops"
                    /*id={[
                        alldisplay.at(-1).id,
                        alldisplay.at(-1).Directory,
                        alldisplay.at(-1).Comment_Counter,
                      ]}*/
                    style={{
                      height:
                        (alldisplay.at(-1).image2 && 275) ||
                        ((alldisplay.at(-1).image1 ||
                          alldisplay.at(-1).file1?.path) &&
                          295),
                    }}
                  ></div>

                  <div
                    style={{
                      marginLeft:
                        !alldisplay.at(-1).file3?.path &&
                        alldisplay.at(-1).file2?.path
                          ? 40
                          : alldisplay.at(-1).file3?.path && 60,
                      marginTop: alldisplay.at(-1).file3?.path && -10,
                      justifyContent:
                        alldisplay.at(-1).file3?.path &&
                        !alldisplay.at(-1).file4?.path &&
                        "flex-start",
                    }}
                    className="postphotoline"
                  >
                    {alldisplay.at(-1).file1?.filetype === "videos" ? (
                      <>
                        <video
                          controls={!alldisplay.at(-1).file2.path && true}
                          width={
                            fullscreened
                              ? "100%"
                              : alldisplay.at(-1).file2.path === ""
                              ? 200
                              : alldisplay.at(-1).file4.path !== ""
                              ? 130
                              : 150
                          }
                          height={
                            fullscreened
                              ? "40%"
                              : alldisplay.at(-1).file2.path === ""
                              ? 200
                              : alldisplay.at(-1).file4?.path !== ""
                              ? 95
                              : alldisplay.at(-1).file3.path !== ""
                              ? 195
                              : 150
                          }
                          ref={(element) =>
                            (droppedvideoref.current[0] = element)
                          }
                          onTimeUpdate={Videotimeupdate}
                          onEnded={
                            alldisplay.at(-1).file2.path
                              ? (e) => Droppedvideotimeupdate(e, 0)
                              : undefined
                          }
                          playsInline
                          muted
                          loop={!alldisplay.at(-1).file2.path && true}
                          // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                          id={alldisplay.at(-1).file1.id}
                          style={videohtml(
                            alldisplay.at(-1)?.file2,
                            alldisplay.at(-1).file3,
                            alldisplay.at(-1).file4
                          )}
                        >
                          <source
                            type="video/mp4"
                            src={alldisplay.at(-1).file1?.path}
                          />
                        </video>
                        {!isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: !alldisplay.at(-1).file2.filetype
                                ? "18%"
                                : !alldisplay.at(-1).file3.filetype
                                ? "27%"
                                : alldisplay.at(-1).file4.filetype
                                ? "46%"
                                : "16%",
                              left:
                                !alldisplay.at(-1).file2.filetype ||
                                alldisplay.at(-1).file4.filetype
                                  ? "25%"
                                  : !alldisplay.at(-1).file3.filetype
                                  ? "17%"
                                  : "23%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.currentTime / 60
                              )}
                            :
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime < 10 && 0}
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime >= 60
                              ? Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime
                                )}
                            <span style={{ marginLeft: 5, marginRight: 5 }}>
                              /
                            </span>
                            {0 +
                              Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.duration / 60
                              )}
                            :
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration < 10 && 0}
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration >= 60
                              ? Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration
                                )}
                          </span>
                        )}
                      </>
                    ) : (
                      alldisplay.at(-1).file1?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={alldisplay.at(-1).file1?.path}
                          id={alldisplay.at(-1).file1?.id}
                          style={imagehtml(
                            alldisplay.at(-1).file2,
                            alldisplay.at(-1).file3,
                            alldisplay.at(-1).file4
                          )}
                        />
                      )
                    )}

                    {!alldisplay.at(-1).file4?.path ? (
                      <div
                        style={{
                          display:
                            alldisplay.at(-1).file3?.path &&
                            !alldisplay.at(-1).file4?.path &&
                            "flex",
                          flexDirection:
                            alldisplay.at(-1).file3?.path &&
                            !alldisplay.at(-1).file4?.path &&
                            "column",
                        }}
                      >
                        {alldisplay.at(-1).file2?.filetype === "videos" ? (
                          <>
                            {console.log(droppedvideoref.current)}
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : alldisplay.at(-1).file3?.path
                                  ? 110
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : alldisplay.at(-1).file3?.path
                                  ? 95
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  alldisplay.at(-1).file1.filetype === "videos"
                                    ? [droppedvideoref.current[0], element]
                                    : element)
                              }
                              playsInline
                              muted
                              id={alldisplay.at(-1).file2.id}
                              style={videohtml(alldisplay.at(-1).file1)}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={alldisplay.at(-1).file2?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: !alldisplay.at(-1).file3.filetype
                                      ? "27%"
                                      : "46%",
                                    left: !alldisplay.at(-1).file3.filetype
                                      ? "59%"
                                      : "65%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration < 10 && 0}
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          alldisplay.at(-1).file2?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={alldisplay.at(-1).file2?.path}
                              id={alldisplay.at(-1).file2?.id}
                              style={
                                alldisplay.at(-1).file3?.path
                                  ? {
                                      ...imagehtml(alldisplay.at(-1).file1),
                                      width: 110,
                                      height:
                                        alldisplay.at(-1).file3.path && 95,
                                    }
                                  : imagehtml(alldisplay.at(-1).file1)
                              }
                            />
                          )
                        )}

                        {alldisplay.at(-1).file3?.filetype === "videos" ? (
                          <>
                            <video
                              width={fullscreened ? "100%" : 110}
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  alldisplay.at(-1).file1.filetype ===
                                    "videos" &&
                                  alldisplay.at(-1).file2.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[0][0],
                                        droppedvideoref.current[0][1],
                                        element,
                                      ]
                                    : alldisplay.at(-1).file1.filetype !==
                                        "videos" &&
                                      alldisplay.at(-1).file2.filetype !==
                                        "videos"
                                    ? element
                                    : alldisplay.at(-1).file1.filetype !==
                                        "videos" ||
                                      alldisplay.at(-1).file2.filetype !==
                                        "videos"
                                    ? [droppedvideoref?.current[0], element]
                                    : [])
                              }
                              playsInline
                              muted
                              id={alldisplay.at(-1).file3.id}
                              style={{
                                ...videohtml(alldisplay.at(-1).file1),
                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={alldisplay.at(-1).file3?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "65%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.currentTime < 10 && 0}
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.duration < 10 && 0}
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          alldisplay.at(-1).file3?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={alldisplay.at(-1).file3?.path}
                              id={alldisplay.at(-1).file3?.id}
                              style={{
                                ...imagehtml(alldisplay.at(-1).file1),
                                width: !alldisplay.at(-1).file4?.path
                                  ? 110
                                  : 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </div>
                    ) : (
                      <>
                        {alldisplay.at(-1).file2?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : alldisplay.at(-1).file4.path !== ""
                                  ? 130
                                  : alldisplay.at(-1).file3.path
                                  ? 110
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : alldisplay.at(-1).file3.path
                                  ? 95
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  alldisplay.at(-1).file1.filetype === "videos"
                                    ? [droppedvideoref.current[0], element]
                                    : element)
                              }
                              playsInline
                              muted
                              id={alldisplay.at(-1).file2.id}
                              style={videohtml(alldisplay.at(-1).file1)}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={alldisplay.at(-1).file2?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "46%",
                                    left: "62%",

                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration < 10 && 0}
                                  {(alldisplay.at(-1).file1.filetype ===
                                  "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          alldisplay.at(-1).file2?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={alldisplay.at(-1).file2?.path}
                              id={alldisplay.at(-1).file2?.id}
                              style={
                                alldisplay.at(-1).file3?.path
                                  ? {
                                      ...imagehtml(alldisplay.at(-1).file1),
                                      width:
                                        (alldisplay.at(-1).file4?.path &&
                                          130) ||
                                        (alldisplay.at(-1).file3.path && 110),
                                      height:
                                        alldisplay.at(-1).file3.path && 95,
                                    }
                                  : imagehtml(alldisplay.at(-1).file1)
                              }
                            />
                          )
                        )}
                        {alldisplay.at(-1).file3?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : alldisplay.at(-1).file4.path !== ""
                                  ? 130
                                  : 110
                              }
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  alldisplay.at(-1).file1.filetype ===
                                    "videos" &&
                                  alldisplay.at(-1).file2.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[0][0],
                                        droppedvideoref.current[0][1],
                                        element,
                                      ]
                                    : alldisplay.at(-1).file1.filetype !==
                                        "videos" &&
                                      alldisplay.at(-1).file2.filetype !==
                                        "videos"
                                    ? element
                                    : alldisplay.at(-1).file1.filetype !==
                                        "videos" ||
                                      alldisplay.at(-1).file2.filetype !==
                                        "videos"
                                    ? [droppedvideoref?.current[0], element]
                                    : [])
                              }
                              playsInline
                              muted
                              id={alldisplay.at(-1).file3.id}
                              style={{
                                ...videohtml(alldisplay.at(-1).file1),

                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={alldisplay.at(-1).file3?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "26%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.currentTime < 10 && 0}
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.duration < 10 && 0}
                                  {determinevideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file4.filetype,
                                    0
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          alldisplay.at(-1).file3?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={alldisplay.at(-1).file3?.path}
                              id={alldisplay.at(-1).file3?.id}
                              style={{
                                ...imagehtml(alldisplay.at(-1).file1),
                                width: !alldisplay.at(-1).file4?.path
                                  ? 110
                                  : 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </>
                    )}

                    {alldisplay.at(-1).file4?.filetype === "videos" ? (
                      <>
                        <video
                          width={
                            fullscreened
                              ? "100%"
                              : alldisplay.at(-1).file4.path !== ""
                              ? 130
                              : 110
                          }
                          height={fullscreened ? "40%" : 95}
                          ref={(element) =>
                            (droppedvideoref.current[0] =
                              alldisplay.at(-1).file1.filetype === "videos" &&
                              alldisplay.at(-1).file2.filetype === "videos" &&
                              alldisplay.at(-1).file3.filetype === "videos"
                                ? [
                                    droppedvideoref.current[0][0],
                                    droppedvideoref.current[0][1],
                                    droppedvideoref.current[0][2],
                                    element,
                                  ]
                                : alldisplay.at(-1).file1.filetype !==
                                    "videos" &&
                                  alldisplay.at(-1).file2.filetype !==
                                    "videos" &&
                                  alldisplay.at(-1).file3.filetype !== "videos"
                                ? element
                                : alldisplay.at(-1).file1.filetype !==
                                    "videos" ||
                                  alldisplay.at(-1).file2.filetype !==
                                    "videos" ||
                                  alldisplay.at(-1).file3.filetype !== "videos"
                                ? [
                                    ...determinevideoref(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    ),
                                    element,
                                  ]
                                : [])
                          }
                          playsInline
                          muted
                          id={alldisplay.at(-1).file4.id}
                          style={{
                            ...videohtml(alldisplay.at(-1).file1),

                            marginTop: 4,
                          }}
                          onTimeUpdate={Videotimeupdate}
                          onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                        >
                          <source
                            type="video/mp4"
                            src={alldisplay.at(-1).file4?.path}
                          />
                        </video>

                        {droppedvideoref?.current[0] !== null &&
                          !isNaN(
                            Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]?.duration
                              : droppedvideoref.current[0]?.duration
                          ) && (
                            <span
                              //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                              style={{
                                bottom: "16%",
                                left: "61.5%",
                                zIndex: 1,
                                position: "absolute",
                              }}
                              className="videoduration"
                            >
                              {0 +
                                Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.currentTime / 60
                                )}
                              :
                              {determinefourthvideoindex(
                                alldisplay.at(-1).file1.filetype,
                                alldisplay.at(-1).file2.filetype,
                                alldisplay.at(-1).file3.filetype,
                                0
                              )?.currentTime < 10 && 0}
                              {determinefourthvideoindex(
                                alldisplay.at(-1).file1.filetype,
                                alldisplay.at(-1).file2.filetype,
                                alldisplay.at(-1).file3.filetype,
                                0
                              )?.currentTime >= 60
                                ? Math.floor(
                                    determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.currentTime % 60
                                  )
                                : Math.floor(
                                    determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.currentTime
                                  )}
                              <span style={{ marginLeft: 5, marginRight: 5 }}>
                                /
                              </span>
                              {0 +
                                Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.duration / 60
                                )}
                              :
                              {determinefourthvideoindex(
                                alldisplay.at(-1).file1.filetype,
                                alldisplay.at(-1).file2.filetype,
                                alldisplay.at(-1).file3.filetype,
                                0
                              )?.duration < 10 && 0}
                              {determinefourthvideoindex(
                                alldisplay.at(-1).file1.filetype,
                                alldisplay.at(-1).file2.filetype,
                                alldisplay.at(-1).file3.filetype,
                                0
                              )?.duration >= 60
                                ? Math.floor(
                                    determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.duration % 60
                                  )
                                : Math.floor(
                                    determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.duration
                                  )}
                            </span>
                          )}
                      </>
                    ) : (
                      alldisplay.at(-1).file4?.filetype === "images" && (
                        <img
                          src={alldisplay.at(-1).file4?.path}
                          className="postphoto"
                          id={alldisplay.at(-1).file4?.id}
                          style={{
                            ...imagehtml(alldisplay.at(-1).file1),
                            width: 130,
                            height: 95,
                            marginTop: 4,
                          }}
                        />
                      )
                    )}

                    {alldisplay.at(-1).image1 && (
                      <img
                        className="postphoto"
                        src={alldisplay.at(-1).image1}
                        style={{
                          width: alldisplay.at(-1).image2 && 150,
                          height: alldisplay.at(-1).image2 && 150,
                          marginLeft: alldisplay.at(-1).image2 && 50,
                          marginTop: alldisplay.at(-1).image2 && 20,
                        }}
                      />
                    )}
                  </div>

                  <div className="date">
                    {alldisplay.at(-1).year}/{alldisplay.at(-1).month}/
                    {alldisplay.at(-1).date}
                    <span className="Timesplit">|</span>
                    <span className="Time">
                      {alldisplay.at(-1).hour}:{alldisplay.at(-1).minutes}
                    </span>
                  </div>
                  <img
                    className="Rankmarked"
                    src="/RankMark.svg"
                    style={{
                      top:
                        (alldisplay.at(-1).image1 ||
                          alldisplay.at(-1).file1?.path) &&
                        51,
                    }}
                  />
                  <span
                    className="dropoption"
                    style={{ height: clickedcomment && 29 }}
                  >
                    {!clickedcomment && !showdrop && (
                      <span className="commentgroup">
                        <img
                          className="commenttap"
                          src="./Comment1.svg"
                          id={[
                            alldisplay.at(-1).id,
                            alldisplay.length === 1
                              ? alldisplay.at(-1).Directory
                              : alldisplay.at(-1).Sender_id,
                          ]}
                        />

                        <span className="commentcount">
                          {alldisplay.at(-1).Comment_Counter > 0 &&
                            alldisplay.at(-1).Comment_Counter}
                        </span>
                      </span>
                    )}

                    <span className="redropline">
                      {alldisplay.at(-1).Redropped === "done" &&
                        !clickedcomment && (
                          <img
                            src="./Redrop.svg"
                            className="Redrop"
                            //couldnt we combine the id for redrop and askunredrop

                            id={[
                              alldisplay.at(-1).id,
                              alldisplay.length === 1
                                ? alldisplay.at(-1).Directory
                                : alldisplay.at(-1).Sender_id,
                            ]}
                          />
                        )}
                      {alldisplay.at(-1).Redropped !== "done" &&
                        !clickedcomment && (
                          <img
                            className="Redrop"
                            src="./PlaneRedrop4.svg"
                            id={[
                              alldisplay.at(-1).id,
                              alldisplay.length === 1
                                ? alldisplay.at(-1).Directory
                                : alldisplay.at(-1).Sender_id,
                            ]}
                          />
                        )}
                      <span className="commentcount">
                        {alldisplay.at(-1).Redropped_Counter == 0 ||
                        clickedcomment
                          ? null
                          : alldisplay.at(-1).Redropped_Counter}
                      </span>
                    </span>
                  </span>
                  {redroppoint && alldisplay.at(-1).id === redropid[0] && (
                    <span
                      style={{ fontSize: 8, bottom: "5%" }}
                      className="Pselect"
                    >
                      <button
                        style={{
                          left: "30%",
                          fontSize: 9,
                          color: "white",
                          bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                        }}
                      >
                        リドロップ
                      </button>
                      <button
                        style={{
                          left: "30%",
                          fontSize: 9,
                          marginLeft: 85,
                          bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                        }}
                        onClick={(e) => {
                          setRedroppoint(false);
                        }}
                      >
                        キャンセル
                      </button>
                    </span>
                  )}
                  {unredroppoint && alldisplay.at(-1).id === unredropid[0] && (
                    <span
                      style={{ fontSize: 8, bottom: "5%" }}
                      className="Pselect"
                    >
                      <button
                        style={{
                          left: "30%",
                          fontSize: 9,
                          color: "white",
                          bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                        }}
                      >
                        リドロップ解除
                      </button>
                      <button
                        style={{
                          left: "30%",
                          fontSize: 9,
                          marginLeft: 85,
                          bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                        }}
                      >
                        キャンセル
                      </button>
                    </span>
                  )}
                </div>

                {simdisplays[simdisplays.length - 1]?.map(
                  (simdisplay, index) => (
                    <div
                      id={simdisplay.id}
                      className="drops"
                      key={simdisplay.id}
                      ref={
                        (commentbackid === simdisplay.id && scrollref) ||
                        alldisplay[alldisplay.length - 1]?.id === simdisplay.id
                          ? scrollref
                          : null
                      }
                      style={{
                        height:
                          simdisplay.file1?.path !== "" &&
                          simdisplay.file1?.path !== undefined &&
                          330,
                      }}
                    >
                      <div className="postlinetop">
                        <img
                          src={simdisplay.Profile_image}
                          className={
                            simdisplay.Profile_image === "/Profile3.svg"
                              ? "current3"
                              : "current1"
                          }
                        />

                        {simdisplay.Comment.length >= 152 ||
                          (simdisplay.file1?.path && (
                            <div
                              className={
                                simdisplay.Comment.length >= 20
                                  ? "text"
                                  : "text1"
                              }
                              style={{
                                fontSize:
                                  simdisplay.Comment.length >= 152 && 12,
                                width:
                                  simdisplay.Comment.length >= 152 && "80%",
                                marginTop:
                                  simdisplay.Comment.length >= 152 && 8,
                                position:
                                  optionid[0] === simdisplay.id && "absolute",
                                left:
                                  optionid[0] === simdisplay.id &&
                                  simdisplay.Comment.length >= 20
                                    ? "20%"
                                    : `${49.5 - simdisplay.Comment.length}%`,
                              }}
                            >
                              {simdisplay.Comment}
                            </div>
                          ))}

                        {optionid[0] !== simdisplay.id && (
                          <img
                            src="/DropOption.svg"
                            className="postoption"
                            id={[simdisplay.id, simdisplay.Sender_id]}
                          />
                        )}

                        {!deleteid && optionid[0] === simdisplay.id && (
                          <span className="Delete">
                            <button
                              onClick={(e) => {
                                setOptionId("");
                                setDeleteid("");
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        )}

                        {deleteid === simdisplay.id && (
                          <span className="Delete">
                            <button onClick={(e) => setDeleteid("")}>X</button>
                          </span>
                        )}
                      </div>
                      <span className="textline">
                        <span
                          className="stylerank"
                          style={{
                            marginLeft:
                              simdisplay.Rank <= 9
                                ? 19.4
                                : simdisplay.Rank === 11
                                ? 16
                                : simdisplay.Rank <= 99
                                ? 14.5
                                : simdisplay.Rank > 99 && 10,
                            flex:
                              simdisplay.Comment.length >= 25
                                ? 0.18
                                : simdisplay.Comment.length === 20
                                ? 0.14
                                : 0.5,
                            marginTop:
                              simdisplay.Comment.length > 65 &&
                              simdisplay.file1?.path
                                ? -25
                                : simdisplay.Comment.length >= 152
                                ? -86.5
                                : null,
                          }}
                        >
                          {simdisplay.Rank}
                        </span>
                        {simdisplay.Comment.length < 152 &&
                          !simdisplay.file1?.path && (
                            <div
                              className={
                                simdisplay.Comment.length >= 20
                                  ? "text"
                                  : "text1"
                              }
                            >
                              {simdisplay.Comment}
                            </div>
                          )}
                      </span>
                      <div
                        className="alldrops"
                        //onClick={(e) => display(simdisplay)}
                        style={{
                          height:
                            //(post.image2 && 275) ||
                            simdisplay.file1?.path && 295,
                        }}
                      ></div>

                      <div
                        style={{
                          marginLeft:
                            !simdisplay.file3?.path && simdisplay.file2?.path
                              ? 40
                              : simdisplay.file3?.path && 60,
                          marginTop: simdisplay.file3?.path && -10,
                          justifyContent:
                            simdisplay.file3?.path &&
                            !simdisplay.file4?.path &&
                            "flex-start",
                        }}
                        className="postphotoline"
                      >
                        {simdisplay.file1?.filetype === "videos" ? (
                          <>
                            <video
                              controls={!simdisplay.file2.path && true}
                              width={
                                fullscreened
                                  ? "100%"
                                  : simdisplay.file2.path === ""
                                  ? 200
                                  : simdisplay.file4.path !== ""
                                  ? 130
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : simdisplay.file2.path === ""
                                  ? 200
                                  : simdisplay.file4?.path !== ""
                                  ? 95
                                  : simdisplay.file3.path !== ""
                                  ? 195
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[index + 1] = element)
                              }
                              onTimeUpdate={Videotimeupdate}
                              onEnded={
                                simdisplay.file2.path
                                  ? (e) => Droppedvideotimeupdate(e, index + 1)
                                  : undefined
                              }
                              playsInline
                              muted
                              loop={!simdisplay.file2.path && true}
                              // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                              id={simdisplay.file1.id}
                              style={videohtml(
                                simdisplay?.file2,
                                simdisplay.file3,
                                simdisplay.file4
                              )}
                            >
                              <source
                                type="video/mp4"
                                src={simdisplay.file1?.path}
                              />
                            </video>
                            {!isNaN(
                              Array.isArray(droppedvideoref.current[index + 1])
                                ? droppedvideoref.current[index + 1][0]
                                    ?.duration
                                : droppedvideoref.current[index + 1]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: !simdisplay.file2.filetype
                                    ? "18%"
                                    : !simdisplay.file3.filetype
                                    ? "27%"
                                    : simdisplay.file4.filetype
                                    ? "46%"
                                    : "16%",
                                  left:
                                    !simdisplay.file2.filetype ||
                                    simdisplay.file4.filetype
                                      ? "25%"
                                      : !simdisplay.file3.filetype
                                      ? "17%"
                                      : "23%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                      : droppedvideoref.current[index + 1]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(Array.isArray(
                                  droppedvideoref.current[index + 1]
                                )
                                  ? droppedvideoref.current[index + 1][0]
                                  : droppedvideoref.current[index + 1]
                                )?.currentTime < 10 && 0}
                                {(Array.isArray(
                                  droppedvideoref.current[index + 1]
                                )
                                  ? droppedvideoref.current[index + 1][0]
                                  : droppedvideoref.current[index + 1]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                      : droppedvideoref.current[index + 1]
                                    )?.duration / 60
                                  )}
                                :
                                {(Array.isArray(
                                  droppedvideoref.current[index + 1]
                                )
                                  ? droppedvideoref.current[index + 1][0]
                                  : droppedvideoref.current[index + 1]
                                )?.duration < 10 && 0}
                                {(Array.isArray(
                                  droppedvideoref.current[index + 1]
                                )
                                  ? droppedvideoref.current[index + 1][0]
                                  : droppedvideoref.current[index + 1]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.duration
                                    )}
                              </span>
                            )}
                          </>
                        ) : (
                          simdisplay.file1?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={simdisplay.file1?.path}
                              id={simdisplay.file1?.id}
                              style={imagehtml(
                                simdisplay.file2,
                                simdisplay.file3,
                                simdisplay.file4
                              )}
                            />
                          )
                        )}

                        {!simdisplay.file4?.path ? (
                          <div
                            style={{
                              display:
                                simdisplay.file3?.path &&
                                !simdisplay.file4?.path &&
                                "flex",
                              flexDirection:
                                simdisplay.file3?.path &&
                                !simdisplay.file4?.path &&
                                "column",
                            }}
                          >
                            {simdisplay.file2?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file3?.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : simdisplay.file3?.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[index + 1] =
                                      simdisplay.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index + 1],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file2.id}
                                  style={videohtml(simdisplay.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index + 1)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index + 1] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                          ?.duration
                                      : droppedvideoref.current[index + 1]
                                          ?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: !simdisplay.file3.filetype
                                          ? "27%"
                                          : "46%",
                                        left: !simdisplay.file3.filetype
                                          ? "59%"
                                          : "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index + 1
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1
                                              ]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.currentTime < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index + 1
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1
                                              ]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.duration < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file2?.path}
                                  id={simdisplay.file2?.id}
                                  style={
                                    simdisplay.file3?.path
                                      ? {
                                          ...imagehtml(simdisplay.file1),
                                          width: 110,
                                          height: simdisplay.file3.path && 95,
                                        }
                                      : imagehtml(simdisplay.file1)
                                  }
                                />
                              )
                            )}

                            {simdisplay.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={fullscreened ? "100%" : 110}
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[index + 1] =
                                      simdisplay.file1.filetype === "videos" &&
                                      simdisplay.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1
                                            ][0],
                                            droppedvideoref.current[
                                              index + 1
                                            ][1],
                                            element,
                                          ]
                                        : simdisplay.file1.filetype !==
                                            "videos" &&
                                          simdisplay.file2.filetype !== "videos"
                                        ? element
                                        : simdisplay.file1.filetype !==
                                            "videos" ||
                                          simdisplay.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[index + 1],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file3.id}
                                  style={{
                                    ...videohtml(simdisplay.file1),
                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index + 1)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index + 1] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                          ?.duration
                                      : droppedvideoref.current[index + 1]
                                          ?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file3?.path}
                                  id={simdisplay.file3?.id}
                                  style={{
                                    ...imagehtml(simdisplay.file1),
                                    width: !simdisplay.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <>
                            {simdisplay.file2?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file4.path !== ""
                                      ? 130
                                      : simdisplay.file3.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : simdisplay.file3.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[index + 1] =
                                      simdisplay.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index + 1],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file2.id}
                                  style={videohtml(simdisplay.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index + 1)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index + 1] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                          ?.duration
                                      : droppedvideoref.current[index + 1]
                                          ?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "46%",
                                        left: "62%",

                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index + 1
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1
                                              ]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.currentTime < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index + 1
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1
                                              ]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.duration < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index + 1][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                        ? droppedvideoref?.current[index + 1][0]
                                        : droppedvideoref?.current[index + 1]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file2?.path}
                                  id={simdisplay.file2?.id}
                                  style={
                                    simdisplay.file3?.path
                                      ? {
                                          ...imagehtml(simdisplay.file1),
                                          width:
                                            (simdisplay.file4?.path && 130) ||
                                            (simdisplay.file3.path && 110),
                                          height: simdisplay.file3.path && 95,
                                        }
                                      : imagehtml(simdisplay.file1)
                                  }
                                />
                              )
                            )}
                            {simdisplay.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file4.path !== ""
                                      ? 130
                                      : 110
                                  }
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[index + 1] =
                                      simdisplay.file1.filetype === "videos" &&
                                      simdisplay.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1
                                            ][0],
                                            droppedvideoref.current[
                                              index + 1
                                            ][1],
                                            element,
                                          ]
                                        : simdisplay.file1.filetype !==
                                            "videos" &&
                                          simdisplay.file2.filetype !== "videos"
                                        ? element
                                        : simdisplay.file1.filetype !==
                                            "videos" ||
                                          simdisplay.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[index + 1],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file3.id}
                                  style={{
                                    ...videohtml(simdisplay.file1),

                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index + 1)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index + 1] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                          ?.duration
                                      : droppedvideoref.current[index + 1]
                                          ?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "26%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file3?.path}
                                  id={simdisplay.file3?.id}
                                  style={{
                                    ...imagehtml(simdisplay.file1),
                                    width: !simdisplay.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </>
                        )}

                        {simdisplay.file4?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : simdisplay.file4.path !== ""
                                  ? 130
                                  : 110
                              }
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[index + 1] =
                                  simdisplay.file1.filetype === "videos" &&
                                  simdisplay.file2.filetype === "videos" &&
                                  simdisplay.file3.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[index + 1][0],
                                        droppedvideoref.current[index + 1][1],
                                        droppedvideoref.current[index + 1][2],
                                        element,
                                      ]
                                    : simdisplay.file1.filetype !== "videos" &&
                                      simdisplay.file2.filetype !== "videos" &&
                                      simdisplay.file3.filetype !== "videos"
                                    ? element
                                    : simdisplay.file1.filetype !== "videos" ||
                                      simdisplay.file2.filetype !== "videos" ||
                                      simdisplay.file3.filetype !== "videos"
                                    ? [
                                        ...determinevideoref(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        ),
                                        element,
                                      ]
                                    : [])
                              }
                              playsInline
                              muted
                              id={simdisplay.file4.id}
                              style={{
                                ...videohtml(simdisplay.file1),

                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) =>
                                Droppedvideotimeupdate(e, index + 1)
                              }
                            >
                              <source
                                type="video/mp4"
                                src={simdisplay.file4?.path}
                              />
                            </video>

                            {droppedvideoref?.current[index + 1] !== null &&
                              !isNaN(
                                Array.isArray(
                                  droppedvideoref.current[index + 1]
                                )
                                  ? droppedvideoref.current[index + 1][0]
                                      ?.duration
                                  : droppedvideoref.current[index + 1]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "61.5%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file3.filetype,
                                        index + 1
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1
                                  )?.currentTime < 10 && 0}
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file3.filetype,
                                        index + 1
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1
                                  )?.duration < 10 && 0}
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          simdisplay.file4?.filetype === "images" && (
                            <img
                              src={simdisplay.file4?.path}
                              className="postphoto"
                              id={simdisplay.file4?.id}
                              style={{
                                ...imagehtml(simdisplay.file1),
                                width: 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </div>

                      <div className="date">
                        {simdisplay.year}/{simdisplay.month}/{simdisplay.date}
                        <span className="Timesplit">|</span>
                        <span className="Time">
                          {simdisplay.hour}:{simdisplay.minutes}
                        </span>
                      </div>

                      <img
                        style={{ top: simdisplay.file1?.path && 51 }}
                        className="Rankmarked"
                        src="/RankMark.svg"
                      />
                      <span className="dropoption">
                        <span className="commentgroup">
                          <img
                            className="commenttap"
                            src="./Comment1.svg"
                            id={[simdisplay.id, simdisplay.Sender_id]}
                          />
                          <span className="commentcount">
                            {simdisplay.Comment_Counter == 0
                              ? null
                              : simdisplay.Comment_Counter}
                          </span>
                        </span>
                        <span className="redropline">
                          {simdisplay.Redropped == "done" ? (
                            <>
                              <img
                                src="./Redrop.svg"
                                className="Redrop"
                                //couldnt we combine the id for redrop and askunredrop

                                id={[simdisplay.id, simdisplay.Sender_id]}
                              />
                            </>
                          ) : (
                            <img
                              className="Redrop"
                              src="./PlaneRedrop4.svg"
                              id={[
                                simdisplay.id,
                                simdisplay.Sender_id,
                                /*
                            simpost.Rank,
                            simpost.Profile_image,
                            simpost.Comment,
                            simpost.Comment_Counter,
                            simpost.hour,
                            simpost.minutes,
                            simpost.year,
                            simpost.month,
                            simpost.date,
                            simpost.image1,
                            simpost.image2,
                            simpost.image3,
                            simpost.image4,
                            simpost.video,
                            simpost.Redropper_id,
                            simpost.Redropped_Counter,
                            simpost.Sent_id,
                            simpost.Directory,
                            simpost.This_Directory,
                            */
                              ]}
                            />
                          )}
                          <span className="commentcount">
                            {simdisplay.Redropped_Counter == 0
                              ? null
                              : simdisplay.Redropped_Counter}
                          </span>
                        </span>
                      </span>
                      {redroppoint && simdisplay.id === redropid[0] && (
                        <span
                          style={{ fontSize: 8, bottom: "5%" }}
                          className="Pselect"
                        >
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              color: "white",
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                          >
                            リドロップ
                          </button>
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) => {
                              setRedroppoint(false);
                            }}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                      {unredroppoint && simdisplay.id === unredropid[0] && (
                        <span
                          style={{ fontSize: 8, bottom: "5%" }}
                          className="Pselect"
                        >
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              color: "white",
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                          >
                            リドロップ解除
                          </button>
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <div
                  id={clickedcomment.id}
                  className="drops"
                  // key={clickedcomment.id}
                  style={{
                    height:
                      (clickedcomment.image2 && 310) ||
                      ((clickedcomment.image1 ||
                        (clickedcomment.file1?.path !== "" &&
                          clickedcomment.file1?.path !== undefined)) &&
                        330),
                  }}
                >
                  <div className="postlinetop">
                    <img
                      className={
                        clickedcomment.Profile_image === "/Profile3.svg"
                          ? "current3"
                          : "current1"
                      }
                      src={clickedcomment.Profile_image}
                    />

                    {(clickedcomment.image1 || clickedcomment.file1?.path) && (
                      <div
                        style={{
                          fontSize:
                            (clickedcomment.text || clickedcomment.Comment)
                              ?.length >= 142 && 10.5,
                          position:
                            optionid[0] === clickedcomment.id && "absolute",
                          left:
                            optionid[0] === clickedcomment.id &&
                            (clickedcomment.text || clickedcomment.Comment)
                              ?.length >= 23
                              ? "20%"
                              : `${
                                  49.5 -
                                  (
                                    clickedcomment.text ||
                                    clickedcomment.Comment
                                  )?.length
                                }%`,
                        }}
                        className={
                          (clickedcomment.text || clickedcomment.Comment)
                            ?.length >= 23
                            ? "text"
                            : "text1"
                        }
                      >
                        {clickedcomment.text || clickedcomment.Comment}
                      </div>
                    )}

                    {optionid[0] !== clickedcomment.id && (
                      <img
                        src="/DropOption.svg"
                        id={[clickedcomment.id, clickedcomment.Sender_id]}
                        className="postoption"
                      />
                    )}

                    {!deleteid && optionid[0] === clickedcomment.id && (
                      <span className="Delete">
                        <button
                          onClick={(e) => {
                            setOptionId("");
                            setDeleteid("");
                          }}
                        >
                          Cancel
                        </button>
                      </span>
                    )}
                  </div>
                  <span className="textline">
                    <span
                      className="stylerank"
                      style={{
                        marginLeft:
                          clickedcomment.Rank <= 9
                            ? 19.4
                            : clickedcomment.Rank === 11
                            ? 16
                            : null,
                        flex:
                          (clickedcomment.text || clickedcomment.Comment)
                            ?.length >= 25
                            ? 0.18
                            : (clickedcomment.text || clickedcomment.Comment)
                                ?.length === 20
                            ? 0.14
                            : 0.5,
                        marginTop:
                          (clickedcomment.text || clickedcomment.Comment)
                            ?.length > 65 &&
                          (clickedcomment.image1 || clickedcomment.file1?.path)
                            ? -25
                            : null,
                      }}
                    >
                      {clickedcomment.Rank}
                    </span>

                    {!clickedcomment.image1 &&
                      !clickedcomment.file1?.path &&
                      (clickedcomment.text || clickedcomment.Comment)?.length <
                        152 && (
                        <div
                          className={
                            (clickedcomment.text || clickedcomment.Comment)
                              ?.length >= 23
                              ? "text"
                              : "text1"
                          }
                        >
                          {clickedcomment.text || clickedcomment.Comment}
                        </div>
                      )}
                  </span>

                  <div
                    style={{
                      marginLeft:
                        !clickedcomment.file3?.path &&
                        clickedcomment.file2?.path
                          ? 40
                          : clickedcomment.file3?.path && 60,
                      marginTop: clickedcomment.file3?.path && -10,
                      justifyContent:
                        clickedcomment.file3?.path &&
                        !clickedcomment.file4?.path &&
                        "flex-start",
                    }}
                    className="postphotoline"
                  >
                    {clickedcomment.file1?.filetype === "videos" ? (
                      <>
                        <video
                          controls={!clickedcomment.file2.path && true}
                          width={
                            fullscreened
                              ? "100%"
                              : clickedcomment.file2.path === ""
                              ? 200
                              : clickedcomment.file4.path !== ""
                              ? 130
                              : 150
                          }
                          height={
                            fullscreened
                              ? "40%"
                              : clickedcomment.file2.path === ""
                              ? 200
                              : clickedcomment.file4?.path !== ""
                              ? 95
                              : clickedcomment.file3.path !== ""
                              ? 195
                              : 150
                          }
                          ref={(element) =>
                            (droppedvideoref.current[0] = element)
                          }
                          onTimeUpdate={Videotimeupdate}
                          onEnded={
                            clickedcomment.file2.path
                              ? (e) => Droppedvideotimeupdate(e, 0)
                              : undefined
                          }
                          playsInline
                          muted
                          loop={!clickedcomment.file2.path && true}
                          // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                          id={clickedcomment.file1.id}
                          style={videohtml(
                            clickedcomment.file2,
                            clickedcomment.file3,
                            clickedcomment.file4
                          )}
                        >
                          <source
                            type="video/mp4"
                            src={clickedcomment.file1?.path}
                          />
                        </video>
                        {!isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: !clickedcomment.file2.filetype
                                ? "18%"
                                : !clickedcomment.file3.filetype
                                ? "27%"
                                : clickedcomment.file4.filetype
                                ? "46%"
                                : "16%",
                              left:
                                !clickedcomment.file2.filetype ||
                                clickedcomment.file4.filetype
                                  ? "25%"
                                  : !clickedcomment.file3.filetype
                                  ? "17%"
                                  : "23%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.currentTime / 60
                              )}
                            :
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime < 10 && 0}
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime >= 60
                              ? Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime
                                )}
                            <span style={{ marginLeft: 5, marginRight: 5 }}>
                              /
                            </span>
                            {0 +
                              Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.duration / 60
                              )}
                            :
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration < 10 && 0}
                            {(Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration >= 60
                              ? Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  (Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration
                                )}
                          </span>
                        )}
                      </>
                    ) : (
                      clickedcomment.file1?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={clickedcomment.file1?.path}
                          id={clickedcomment.file1?.id}
                          style={imagehtml(
                            clickedcomment.file2,
                            clickedcomment.file3,
                            clickedcomment.file4
                          )}
                        />
                      )
                    )}

                    {!clickedcomment.file4?.path ? (
                      <div
                        style={{
                          display:
                            clickedcomment.file3?.path &&
                            !clickedcomment.file4?.path &&
                            "flex",
                          flexDirection:
                            clickedcomment.file3?.path &&
                            !clickedcomment.file4?.path &&
                            "column",
                        }}
                      >
                        {clickedcomment.file2?.filetype === "videos" ? (
                          <>
                            {console.log(droppedvideoref.current)}
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : clickedcomment.file3?.path
                                  ? 110
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : clickedcomment.file3?.path
                                  ? 95
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  clickedcomment.file1.filetype === "videos"
                                    ? [droppedvideoref.current[0], element]
                                    : element)
                              }
                              playsInline
                              muted
                              id={clickedcomment.file2.id}
                              style={videohtml(clickedcomment.file1)}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={clickedcomment.file2?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: !clickedcomment.file3.filetype
                                      ? "27%"
                                      : "46%",
                                    left: !clickedcomment.file3.filetype
                                      ? "59%"
                                      : "65%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (clickedcomment.file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (clickedcomment.file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration < 10 && 0}
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          clickedcomment.file2?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={clickedcomment.file2?.path}
                              id={clickedcomment.file2?.id}
                              style={
                                clickedcomment.file3?.path
                                  ? {
                                      ...imagehtml(clickedcomment.file1),
                                      width: 110,
                                      height: clickedcomment.file3.path && 95,
                                    }
                                  : imagehtml(clickedcomment.file1)
                              }
                            />
                          )
                        )}

                        {clickedcomment.file3?.filetype === "videos" ? (
                          <>
                            <video
                              width={fullscreened ? "100%" : 110}
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  clickedcomment.file1.filetype === "videos" &&
                                  clickedcomment.file2.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[0][0],
                                        droppedvideoref.current[0][1],
                                        element,
                                      ]
                                    : clickedcomment.file1.filetype !==
                                        "videos" &&
                                      clickedcomment.file2.filetype !== "videos"
                                    ? element
                                    : clickedcomment.file1.filetype !==
                                        "videos" ||
                                      clickedcomment.file2.filetype !== "videos"
                                    ? [droppedvideoref?.current[0], element]
                                    : [])
                              }
                              playsInline
                              muted
                              id={clickedcomment.file3.id}
                              style={{
                                ...videohtml(clickedcomment.file1),
                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={clickedcomment.file3?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "65%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        clickedcomment.file1.filetype,
                                        clickedcomment.file2.filetype,
                                        clickedcomment.file4.filetype,
                                        0
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime < 10 && 0}
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        clickedcomment.file1.filetype,
                                        clickedcomment.file2.filetype,
                                        clickedcomment.file4.filetype,
                                        0
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration < 10 && 0}
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          clickedcomment.file3?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={clickedcomment.file3?.path}
                              id={clickedcomment.file3?.id}
                              style={{
                                ...imagehtml(clickedcomment.file1),
                                width: !clickedcomment.file4?.path ? 110 : 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </div>
                    ) : (
                      <>
                        {clickedcomment.file2?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : clickedcomment.file4.path !== ""
                                  ? 130
                                  : clickedcomment.file3.path
                                  ? 110
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : clickedcomment.file3.path
                                  ? 95
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  clickedcomment.file1.filetype === "videos"
                                    ? [droppedvideoref.current[0], element]
                                    : element)
                              }
                              playsInline
                              muted
                              id={clickedcomment.file2.id}
                              style={videohtml(clickedcomment.file1)}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={clickedcomment.file2?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "46%",
                                    left: "62%",

                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (clickedcomment.file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (clickedcomment.file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration < 10 && 0}
                                  {(clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          clickedcomment.file2?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={clickedcomment.file2?.path}
                              id={clickedcomment.file2?.id}
                              style={
                                clickedcomment.file3?.path
                                  ? {
                                      ...imagehtml(clickedcomment.file1),
                                      width:
                                        (clickedcomment.file4?.path && 130) ||
                                        (clickedcomment.file3.path && 110),
                                      height: clickedcomment.file3.path && 95,
                                    }
                                  : imagehtml(clickedcomment.file1)
                              }
                            />
                          )
                        )}
                        {clickedcomment.file3?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : clickedcomment.file4.path !== ""
                                  ? 130
                                  : 110
                              }
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[0] =
                                  clickedcomment.file1.filetype === "videos" &&
                                  clickedcomment.file2.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[0][0],
                                        droppedvideoref.current[0][1],
                                        element,
                                      ]
                                    : clickedcomment.file1.filetype !==
                                        "videos" &&
                                      clickedcomment.file2.filetype !== "videos"
                                    ? element
                                    : clickedcomment.file1.filetype !==
                                        "videos" ||
                                      clickedcomment.file2.filetype !== "videos"
                                    ? [droppedvideoref?.current[0], element]
                                    : [])
                              }
                              playsInline
                              muted
                              id={clickedcomment.file3.id}
                              style={{
                                ...videohtml(clickedcomment.file1),

                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                            >
                              <source
                                type="video/mp4"
                                src={clickedcomment.file3?.path}
                              />
                            </video>

                            {droppedvideoref?.current[0] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "26%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        clickedcomment.file1.filetype,
                                        clickedcomment.file2.filetype,
                                        clickedcomment.file4.filetype,
                                        0
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime < 10 && 0}
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinevideoindex(
                                        clickedcomment.file1.filetype,
                                        clickedcomment.file2.filetype,
                                        clickedcomment.file4.filetype,
                                        0
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration < 10 && 0}
                                  {determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          clickedcomment.file3?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={clickedcomment.file3?.path}
                              id={clickedcomment.file3?.id}
                              style={{
                                ...imagehtml(clickedcomment.file1),
                                width: !clickedcomment.file4?.path ? 110 : 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </>
                    )}

                    {clickedcomment.file4?.filetype === "videos" ? (
                      <>
                        <video
                          width={
                            fullscreened
                              ? "100%"
                              : clickedcomment.file4.path !== ""
                              ? 130
                              : 110
                          }
                          height={fullscreened ? "40%" : 95}
                          ref={(element) =>
                            (droppedvideoref.current[0] =
                              clickedcomment.file1.filetype === "videos" &&
                              clickedcomment.file2.filetype === "videos" &&
                              clickedcomment.file3.filetype === "videos"
                                ? [
                                    droppedvideoref.current[0][0],
                                    droppedvideoref.current[0][1],
                                    droppedvideoref.current[0][2],
                                    element,
                                  ]
                                : clickedcomment.file1.filetype !== "videos" &&
                                  clickedcomment.file2.filetype !== "videos" &&
                                  clickedcomment.file3.filetype !== "videos"
                                ? element
                                : clickedcomment.file1.filetype !== "videos" ||
                                  clickedcomment.file2.filetype !== "videos" ||
                                  clickedcomment.file3.filetype !== "videos"
                                ? [
                                    ...determinevideoref(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    ),
                                    element,
                                  ]
                                : [])
                          }
                          playsInline
                          muted
                          id={clickedcomment.file4.id}
                          style={{
                            ...videohtml(clickedcomment.file1),

                            marginTop: 4,
                          }}
                          onTimeUpdate={Videotimeupdate}
                          onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                        >
                          <source
                            type="video/mp4"
                            src={clickedcomment.file4?.path}
                          />
                        </video>

                        {droppedvideoref?.current[0] !== null &&
                          !isNaN(
                            Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]?.duration
                              : droppedvideoref.current[0]?.duration
                          ) && (
                            <span
                              //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                              style={{
                                bottom: "16%",
                                left: "61.5%",
                                zIndex: 1,
                                position: "absolute",
                              }}
                              className="videoduration"
                            >
                              {0 +
                                Math.floor(
                                  determinefourthvideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file3.filetype,
                                    0
                                  )?.currentTime / 60
                                )}
                              :
                              {determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.currentTime < 10 && 0}
                              {determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.currentTime >= 60
                                ? Math.floor(
                                    determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.currentTime % 60
                                  )
                                : Math.floor(
                                    determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.currentTime
                                  )}
                              <span style={{ marginLeft: 5, marginRight: 5 }}>
                                /
                              </span>
                              {0 +
                                Math.floor(
                                  determinefourthvideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file3.filetype,
                                    0
                                  )?.duration / 60
                                )}
                              :
                              {determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.duration < 10 && 0}
                              {determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.duration >= 60
                                ? Math.floor(
                                    determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.duration % 60
                                  )
                                : Math.floor(
                                    determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.duration
                                  )}
                            </span>
                          )}
                      </>
                    ) : (
                      clickedcomment.file4?.filetype === "images" && (
                        <img
                          src={clickedcomment.file4?.path}
                          className="postphoto"
                          id={clickedcomment.file4?.id}
                          style={{
                            ...imagehtml(clickedcomment.file1),
                            width: 130,
                            height: 95,
                            marginTop: 4,
                          }}
                        />
                      )
                    )}

                    {clickedcomment.image1 && (
                      <img
                        className="postphoto"
                        src={clickedcomment.image1}
                        /*
                          style={{
                            width: clickedcomment.image2 && 150,
                            height: clickedcomment.image2 && 150,
                            marginLeft: clickedcomment.image2 && 50,
                            marginTop: clickedcomment.image2 && 20,
                          }}
                          */
                      />
                    )}
                  </div>

                  <div className="date">
                    {clickedcomment.year}/{clickedcomment.month}/
                    {clickedcomment.date}
                    <span className="Timesplit">|</span>
                    {clickedcomment.hour}:{clickedcomment.minutes}
                  </div>

                  <img
                    style={{
                      top:
                        (clickedcomment.image1 || clickedcomment.file1?.path) &&
                        51,
                    }}
                    className="Rankmarked"
                    src="/RankMark.svg"
                  />
                </div>

                <textarea
                  className="drop"
                  placeholder="返信..."
                  value={send}
                  onChange={(e) => setSend(e.target.value)}
                  style={{
                    marginTop: 0,
                    height: tempfiles.length > 0 ? 350 : 100,
                  }}
                />

                <span
                  style={{
                    bottom: tempfiles?.length === 0 ? 38 : 30,
                    marginTop: tempfiles.length > 0 && -258,
                  }}
                  className="CommentCameraline"
                >
                  <img className="camera" src="./Photo.svg" />

                  <span style={{ marginLeft: 4, marginBottom: 4 }}>|</span>
                </span>
              </>
            )}
          </>
        )}
      </div>

      {/*posts.length > 0 && (
            <LowControl
              notification={userinfo.Notification}
              currenturl={currenturl}
              alldisplay={alldisplay.length}
              commentlength={simdisplays.at(-1)?.length}
              clickedcomment={clickedcomment}
            />
          )*/}

      {/*</>*/}
      {/*)}*/}
    </div>
  );
}

export default Connect;
