import React, { useState, useEffect, useRef } from "react";
import "./Paymentform.css";
import {
  updateDoc,
  doc,
  addDoc,
  collection,
  collectionGroup,
  increment,
  setDoc,
  writeBatch,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "./Firebase";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
const Card_Options = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto,Open Sans,Segoe UI,sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function Paymentform1({
  Paymethod,
  FinalPrice,
  Finished,
  Myid,
  Price,
  Allusers,
  Rank,
  Newrank,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const ref1 = useRef(false);

  const [carderror, setCarderror] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [client, setClient] = useState("");
  const [clientid, setClientid] = useState("");
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    if (Paymethod === "konbini") {
      console.log("YO");
      if (!ref1.current) {
        ref1.current = true;
        (async () => {
          try {
            const response = await axios.post(
              "http://localhost:4000/konbinipayment",
              {
                amount: FinalPrice,
                id: Paymethod,
              }
            );

            console.log(response.data);

            if (response.data.success) {
              console.log("konbini Ready");
              console.log(response.data.client_secret);
              console.log(response.data.id);
              setClient(response.data.client_secret);
              setClientid(response.data.id);
            }
          } catch (error) {
            console.log(error);
          }
        })();
      }
    }
  }, [Paymethod]);

  const paycard = async (e) => {
    e.preventDefault();
    setPressed(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: Paymethod,
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        console.log({ id });
        const response = await axios.post("http://localhost:4000/cardpayment", {
          amount: FinalPrice,
          id: id,
        });

        if (response.data.success) {
          Finished(true);

          console.log(Myid);

          updateDoc(doc(db, "users", Myid), {
            Total_Currency_Generated: increment(Price * 2),
          });

          if (Newrank !== Rank) {
            console.log("HEY");
            let slicedallusers = Allusers.slice(Newrank - 1, Rank);

            console.log(slicedallusers);

            let Rankupdate = [];
            slicedallusers.forEach((post) => {
              const RankRef = collection(db, "users", post.id, "Drops");
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));
                console.log(Rankposts);
                let Rankposts2 = Rankposts.filter(
                  (element) =>
                    (!element.Sender_id && element.Directory === post.id) ||
                    element.Sender_id === post.id
                );
                Rankupdate.push(Rankposts2);
              });
            });

            let Rankupdate1 = [];
            slicedallusers.forEach((post) => {
              const RankRef = collection(db, "users", post.id, "Comments");
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts1 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));
                console.log(Rankposts1);
                Rankupdate1.push(Rankposts1);
                console.log(Rankupdate1);
              });
            });

            let Rankupdate2 = [];
            slicedallusers.forEach((post) => {
              const RankRef = query(
                collectionGroup(db, "Message"),
                where("from", "==", post.id) || where("to", "==", post.id)
              );
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts2 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));

                Rankposts2 = Rankposts2.filter(
                  (element) => element.id === post.id
                );

                setTimeout(() => {
                  console.log(Rankposts2);
                  Rankupdate2.push(Rankposts2);
                  console.log(Rankupdate2);
                }, 100);
              });
            });

            let Rankupdate3 = [];
            slicedallusers.forEach((post) => {
              const RankRef = query(
                collectionGroup(db, "Drops"),
                where("Redropper_id", "!=", false),
                where("Sender_id", "==", post.id)
              );
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts3 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));

                const RankRef1 = query(
                  collectionGroup(db, "Drops"),
                  where("Redropper_id", "!=", false),
                  where("Directory", "==", post.id)
                );
                getDocs(RankRef1).then((querySnapshot) => {
                  let Rankposts4 = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                  }));

                  let FilteredRankposts = Rankposts4.filter(
                    (post) => !post.Sender_id
                  );

                  console.log(Rankposts3);
                  console.log(FilteredRankposts);
                  let ReRankposts = [...Rankposts3, ...FilteredRankposts];
                  console.log(ReRankposts);
                  setTimeout(() => {
                    Rankupdate3.push(ReRankposts);
                    console.log(Rankupdate3);
                  }, 100);
                });
              });
            });

            let Rankupdate4 = [];
            slicedallusers.forEach((post) => {
              const RankRef = query(
                collectionGroup(db, "Notification"),
                where("Sender_id", "==", post.id)
              );
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts5 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));

                console.log(Rankposts5);

                const RankRef1 = query(
                  collectionGroup(db, "Notification"),
                  where("Type", "==", "Follow"),
                  where("Directory", "==", post.id)
                );
                getDocs(RankRef1).then((querySnapshot) => {
                  let Rankposts6 = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                  }));

                  console.log(Rankposts6);

                  const RankRef2 = query(
                    collectionGroup(db, "Notification"),
                    where("Redropper_id", "==", post.id)
                  );
                  getDocs(RankRef2).then((querySnapshot) => {
                    let Rankposts7 = querySnapshot.docs.map((doc) => ({
                      ...doc.data(),
                      id: doc.id,
                    }));

                    console.log(Rankposts7);
                    setTimeout(() => {
                      Rankupdate4.push([
                        ...Rankposts5,
                        ...Rankposts6,
                        ...Rankposts7,
                      ]);
                      console.log(Rankupdate4);
                    }, 100);
                  });
                });
              });
            });

            let Rankupdate5 = [];
            slicedallusers.forEach((post) => {
              const RankRef = query(
                collectionGroup(db, "Following"),
                where("Directory", "==", post.id)
              );
              getDocs(RankRef).then((querySnapshot) => {
                let Rankposts6 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));

                console.log(Rankposts6);
                Rankupdate5.push(Rankposts6);
              });
            });

            let batchArray = [];
            batchArray.push(writeBatch(db));
            let batchCount = 0;
            let batchIndex = 0;
            let number = 0;

            console.log(slicedallusers);
            setTimeout(() => {
              console.log(slicedallusers);

              slicedallusers.forEach((post) => {
                const userDocumentRef = doc(db, "users", post.id);
                batchArray[batchIndex].update(userDocumentRef, {
                  Rank: Newrank + number,
                });

                batchCount++;
                number++;

                if (batchCount === 499) {
                  batchArray.push(writeBatch(db));
                  batchIndex++;
                  batchCount = 0;
                }
              });
              batchArray.forEach(async (batch) => await batch.commit());

              let batchArray1 = [];
              batchArray1.push(writeBatch(db));
              let batchCount1 = 0;
              let batchIndex1 = 0;
              let number1 = -1;

              Rankupdate.forEach((post) => {
                number1++;
                console.log(number1);
                post.forEach((element) => {
                  if (post.length > 0) {
                    const userDocumentRef = doc(
                      db,
                      "users",
                      element.Sender_id ? element.Sender_id : element.Directory,
                      "Drops",
                      element.id
                    );
                    batchArray1[batchIndex1].update(userDocumentRef, {
                      Rank: Newrank + number1,
                    });

                    batchCount1++;

                    if (batchCount1 === 499) {
                      batchArray1.push(writeBatch(db));
                      batchIndex1++;
                      batchCount1 = 0;
                    }
                  }
                });
              });

              batchArray1.forEach(async (batch) => await batch.commit());

              let batchArray2 = [];
              batchArray2.push(writeBatch(db));
              let batchCount2 = 0;
              let batchIndex2 = 0;
              let number2 = -1;
              Rankupdate1.forEach((post) => {
                number2++;
                console.log(number2);
                post.forEach((element) => {
                  if (post.length > 0) {
                    const userDocumentRef = doc(
                      db,
                      "users",
                      element.Sender_id,
                      "Comments",
                      element.id
                    );
                    batchArray2[batchIndex2].update(userDocumentRef, {
                      Rank: Newrank + number2,
                    });
                    batchCount2++;

                    if (batchCount2 === 499) {
                      batchArray2.push(writeBatch(db));
                      batchIndex2++;
                      batchCount2 = 0;
                    }
                  }
                });
              });

              batchArray2.forEach(async (batch) => await batch.commit());

              setTimeout(() => {
                let batchArray3 = [];
                batchArray3.push(writeBatch(db));
                let batchCount3 = 0;
                let batchIndex3 = 0;
                let number3 = -1;
                Rankupdate2.forEach((post) => {
                  number3++;
                  console.log(number3);
                  post.forEach((element) => {
                    if (post.length > 0) {
                      const userDocumentRef = doc(
                        db,
                        "users",
                        element.from !== element.id ? element.from : element.to,
                        "Message",
                        element.id
                      );
                      batchArray3[batchIndex3].update(userDocumentRef, {
                        MessageRank: Newrank + number3,
                      });
                      batchCount3++;

                      if (batchCount3 === 499) {
                        batchArray3.push(writeBatch(db));
                        batchIndex3++;
                        batchCount3 = 0;
                      }
                    }
                  });
                });

                batchArray3.forEach(async (batch) => await batch.commit());
              }, 200);

              let batchArray4 = [];
              batchArray4.push(writeBatch(db));
              let batchCount4 = 0;
              let batchIndex4 = 0;
              let number4 = -1;
              Rankupdate3.forEach((post) => {
                number4++;
                post.forEach((element) => {
                  if (post.length > 0) {
                    const userDocumentRef = doc(
                      db,
                      "users",
                      element.Redropper_id,
                      "Drops",
                      element.id
                    );
                    batchArray4[batchIndex4].update(userDocumentRef, {
                      Rank: Newrank + number4,
                    });
                    batchCount4++;

                    if (batchCount4 === 499) {
                      batchArray4.push(writeBatch(db));
                      batchIndex4++;
                      batchCount4 = 0;
                    }
                  }
                });
              });

              batchArray4.forEach(async (batch) => await batch.commit());

              setTimeout(() => {
                let batchArray5 = [];
                batchArray5.push(writeBatch(db));
                let batchCount5 = 0;
                let batchIndex5 = 0;
                let number5 = -1;
                Rankupdate4.forEach((post) => {
                  number5++;
                  post.forEach((element) => {
                    if (post.length > 0) {
                      const userDocumentRef = doc(
                        db,
                        "users",
                        element.Redropper_id
                          ? element.Directory
                          : element.Sent_id && element.Loadtype === "Drops"
                          ? element.Sent_id
                          : element.Sent_id && element.Loadtype === "Comments"
                          ? element.notification_reciever
                          : element.Followed_id,

                        "Notification",
                        element.id
                      );
                      batchArray5[batchIndex5].update(userDocumentRef, {
                        Rank: Newrank + number5,
                      });
                      batchCount5++;

                      if (batchCount5 === 499) {
                        batchArray5.push(writeBatch(db));
                        batchIndex5++;
                        batchCount5 = 0;
                      }
                    }
                  });
                });

                batchArray5.forEach(async (batch) => await batch.commit());
              }, 1000);

              let batchArray6 = [];
              batchArray6.push(writeBatch(db));
              let batchCount6 = 0;
              let batchIndex6 = 0;
              let number6 = -1;
              Rankupdate5.forEach((post) => {
                number6++;
                post.forEach((element) => {
                  if (post.length > 0) {
                    const userDocumentRef = doc(
                      db,
                      "users",
                      element.Directory,
                      "Following",
                      element.id
                    );
                    batchArray6[batchIndex6].update(userDocumentRef, {
                      Rank: Newrank + number6,
                    });
                    batchCount6++;

                    if (batchCount6 === 499) {
                      batchArray6.push(writeBatch(db));
                      batchIndex6++;
                      batchCount6 = 0;
                    }
                  }
                });
              });

              batchArray6.forEach(async (batch) => await batch.commit());
            }, 1000);
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        }
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          setPressed(false);
        });
      }
    } else {
      console.log(error.message);
      if (error.message === "Your card number is incomplete.") {
        setCarderror("カード番号が間違っています");
      } else if (
        error.message === "Your card's expiration date is incomplete."
      ) {
        setCarderror("カードの有効期限情報が間違っています");
      } else if (
        error.message === "Your card's expiration year is in the past." ||
        error.message === "Your card's expiration date is in the past."
      ) {
        setCarderror("カードの有効期限が過ぎています");
      } else if (error.message === "Your card's security code is incomplete.") {
        setCarderror("セキュリティーコードが間違っています");
      }
      setTimeout(() => {
        setPressed(false);
      });
    }
  };

  const paybanktransfer = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: Paymethod,
      card: elements.getElement(CardElement),
    });
  };

  const paykonbini = async (e) => {
    e.preventDefault();

    setPressed(true);
    console.log(name);
    console.log(email);
    console.log(phone);
    console.log(client);

    try {
      const result = await stripe.confirmKonbiniPayment(client, {
        payment_method: {
          billing_details: {
            name,
            email,
          },
        },
        payment_method_options: {
          konbini: {
            confirmation_number: phone,
          },
        },
      });
      console.log(result);
      console.log(
        result.paymentIntent.next_action.konbini_display_details.expires_at
      );

      console.log(
        result.paymentIntent.next_action.konbini_display_details.stores
          .familymart.confirmation_number
      );

      addDoc(collection(db, "users", Myid, "OutstandingPay"), {
        Price: parseInt(Price * 2),
        FullPrice: FinalPrice,

        Konbini_due:
          result.paymentIntent.next_action.konbini_display_details.expires_at,
        Stripe_id: clientid,
        Phone:
          result.paymentIntent.next_action.konbini_display_details.stores
            .familymart.confirmation_number,
        Payment_code: parseInt(
          result.paymentIntent.next_action.konbini_display_details.stores
            .familymart.payment_code
        ),
        For: "Rank",
      });

      setTimeout(() => {
        Finished(true);
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      alert("情報が正しくありません");
      setTimeout(() => {
        setPressed(false);
      });
    }
  };

  return (
    <div>
      {Paymethod === "card" && (
        <>
          {carderror}
          <form className="payoptions" onSubmit={paycard}>
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={Card_Options} />
              </div>
            </fieldset>
            <button disabled={pressed}>支払う</button>
          </form>
        </>
      )}
      {Paymethod === "jp_bank-transfer" && <div>Yo</div>}
      {Paymethod === "konbini" && (
        <div>
          <form id="payment-form" onSubmit={paykonbini}>
            <div class="form-row">
              <label for="name">Name</label>
              <input
                id="name"
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>

            <div class="form-row">
              <label for="email">Email</label>
              <input
                id="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <div class="form-row">
              <label for="phone">Phone Number</label>
              <input
                id="phone"
                name="phone"
                required
                onChange={(e) => setPhone(e.target.value)}
              ></input>
            </div>

            <div id="error-message" role="alert"></div>

            <button disabled={pressed} id="submit-button">
              コンビニ決済確定
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Paymentform1;
