import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBN6o83fK0NOU15jVnCNlKLbSp_31a4Onw",
  authDomain: "eplise.firebaseapp.com",
  projectId: "eplise",
  storageBucket: "eplise.appspot.com",
  messagingSenderId: "744028269165",
  appId: "1:744028269165:web:e7d33777ee0f9d0055ad64",
  measurementId: "G-GQREBKQBBV",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
