import React, { useState, useEffect } from "react";
import "./ErrorModal.css";

function ErrorModal({ errortype }) {
  const [progresspercent, setProgresspercent] = useState(null);

  return (
    <div>
      <div
        style={{
          color: "crimson",
          // display: "flex",
          // justifyContent: "center",
        }}
      >
        {errortype === "WrongNumber" ? (
          <div>[ 電話番号が正しくありません ]</div>
        ) : errortype === "PhoneNumberInUse" ? (
          <div>[ この電話番号は既に使われています ]</div>
        ) : errortype === "WrongCode" ? (
          <div>[ コードが違います ]</div>
        ) : errortype === "UserName" ? (
          <div>[ ユーザー名は1-25文字でなければいけません. ]</div>
        ) : errortype === "PasswordNotMatched" ? (
          <div>[ パスワードが一致していません. ]</div>
        ) : (
          errortype === "WrongInput" && <div>[ 入力情報に過ちがあります ]</div>
        )}
      </div>
      {/*<div style={{ display: "flex", justifyContent: "center" }}>
        <button className="blockboxbutton" onClick={understandblocked}>
          確認
        </button>
      </div>
      */}
    </div>
  );
}

export default ErrorModal;
