import React, { useState, useEffect } from "react";
import "./FileFullScreen.css";

function FileFullScreen({ file, type, close, shape }) {
  const [movestart, setMovestart] = useState(0);
  const [moveto, setMoveto] = useState(0);
  const [opacity, setOpacity] = useState(0);

  const screenheight = window.innerHeight;
  console.log(screenheight / 4);
  console.log(shape);

  const handleswipe = (e) => {
    console.log(e.touches[0].pageY);

    let movepercentage = window.innerHeight;
    console.log(Math.floor(movestart - e.touches[0].pageY));
    setMoveto(movestart - e.touches[0].pageY);
    setOpacity(Math.abs(movestart - e.touches[0].pageY) / 100);
  };

  const swipeend = (e) => {
    console.log(e);
    if (40 - moveto >= 90 || 40 - moveto <= -10) close();
    else {
      setMoveto(0);
      setOpacity(0);
    }
  };

  return (
    <div
      onTouchStart={(e) => {
        console.log(e.touches[0].pageY);
        setMovestart(e.touches[0].pageY);
      }}
      onTouchMove={handleswipe}
      onTouchEnd={swipeend}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        background: "linear-gradient(#17d6e7, rgb(230, 98, 194))",
        //just devide by 100?
        // opacity: 1 - Math.abs(moveto) / 100,
        opacity: 1 - opacity,
      }}
    >
      <img
        style={{
          width: 30,
          marginTop: 10,
          marginLeft: 5 /*position: "absolute"*/,
        }}
        src="/Xout.svg"
        onClick={close}
      />

      {type === "image" ? (
        <img
          style={{
            marginTop: /*screenheight / 4*/ 40 - moveto + "%",
            borderRadius: 30,
            borderRadius: shape === "circular" && "50%",
            border: shape === "circular" && "2px blueviolet solid",
          }}
          className="fileimage"
          src={file}
        />
      ) : (
        <>
          {
            //when videos are ready to be used work on videos as well
          }
          <div className="filevideo">video{file}</div>
        </>
      )}
    </div>
  );
}
export default FileFullScreen;
