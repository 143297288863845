import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  orderBy,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import LowControl from "./LowControl";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./MessageView.css";

function MessageView() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(location.state);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setId(user.uid);

        const userCollectionRef1 = collection(
          db,
          "users",
          user.uid,
          "PledgeHold"
        );
        await getDocs(userCollectionRef1).then((querySnapshot) => {
          let Pledger = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(Pledger);

          const userCollectionRef = collection(
            db,
            "users",
            user.uid,
            "Message"
          );
          getDocs(query(userCollectionRef, orderBy("Timestamp", "desc"))).then(
            (querySnapshot) => {
              let posts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));

              Pledger.forEach((post) => {
                posts.map((element) => {
                  if (post.PaidUser === element.from) {
                    element.Pledge = true;
                  }
                });
              });
              setTimeout(() => {
                setMessages(posts);
              });
            }
          );
        });

        updateDoc(doc(db, `users/${user.uid}/`), {
          MessageNotification: 0,
        });
      }
    });
    console.log(messages);
  }, []);

  const [messages, setMessages] = useState([]);
  const [pledgemessages, setPledgemessages] = useState([]);
  const [rankmessages, setRankmessages] = useState([]);
  const [id, setId] = useState("");
  const [allmessage, setAllmesssge] = useState(true);
  const [pledgemessage, setPledgemessage] = useState(false);
  const [rankmessage, setRankmessage] = useState(false);
  const [searchmessage, setSearchmessage] = useState(false);

  const read = (e) => {
    console.log(e.target.id.split(","));
    let tempid = e.target.id.split(",");
    if (tempid[3] === "false") {
      updateDoc(doc(db, `users/${id}/Message/${tempid[1]}`), {
        Opened: true,
      });
    }
    setTimeout(() => {
      navigate(`/Message/${tempid[1] === id ? tempid[2] : tempid[1]}`, {
        state: ["MessageView", location.state],
      });
    });
  };

  const filterleft = (e) => {
    if (pledgemessage) {
      setAllmesssge(true);
      setPledgemessage(false);
    } else if (rankmessage && !searchmessage) {
      setPledgemessage(true);
      setRankmessage(false);
      setPledgemessages(messages.filter((message) => message.Pledge));
    } else if (searchmessage) {
      setSearchmessage(false);
    }
  };

  const filterright = (e) => {
    if (allmessage) {
      setAllmesssge(false);
      setPledgemessage(true);

      setPledgemessages(messages.filter((message) => message.Pledge));
    } else if (pledgemessage) {
      setPledgemessage(false);
      setRankmessage(true);
      setRankmessages(
        [...messages].sort((a, b) => a.MessageRank - b.MessageRank)
      );
    } else if (rankmessage) {
      setSearchmessage(true);
    }
  };

  const backurl1 = () => {
    console.log(location.state);
    if (location.state !== "UserView" && location.state !== "User") {
      navigate(`/${location.state}`);
    } else if (location.state === "User") {
      navigate(`/${location.state}/${id}`);
    }
  };

  return (
    <div>
      <div className="allrecieve">
        {/*<img style={{ width: 20 }} src="/Arrow1.svg" />
        <span className="filterline">
          {!allmessage && <span onClick={filterleft}>arrowleft </span>}
          {allmessage && <span>all icon</span>}
          {pledgemessage && <img style={{ width: 20 }} src="/Pledge1.svg" />}
          {rankmessage && <span>rank icon</span>}
          {searchmessage && <input placeholder="🔍"></input>}
          {!searchmessage && <span onClick={filterright}>arrowright</span>}
        </span>*/}
      </div>
      <div
        style={{
          width: "100%",
          height: 56,
          display: "block",
          background: "#005280",
          zIndex: 1,
          marginBottom: 2,
        }}
      ></div>
      <img
        style={{ zIndex: 2, position: "fixed", top: 15, left: 4 }}
        src="/Arrow1.svg"
        onClick={backurl1}
      />
      {allmessage &&
        messages.map((message) => (
          <div className="drops2" key={message.id}>
            <img
              src={message.MessagePic}
              className="current4"
              onClick={(e) =>
                navigate(
                  `/UserView/${message.from === id ? message.to : message.from}`
                )
              }
              style={{
                background:
                  message.MessagePic === "/Profile3.svg" &&
                  "linear-gradient(#121112, #42b1e0)",
                marginTop: !message.text && 3,
              }}
            />
            <span
              id={[message.id, message.from, message.to, message.Opened]}
              className="alldrops1"
              onClick={read}
            ></span>

            <div className="messageline">
              <div className="messagename">
                <span
                  style={{
                    background: "linear-gradient(#b042b0, #42b1e0)",
                    borderRadius: 20,
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  {message.from === id
                    ? message.RecieveName
                    : message.MessageName}
                </span>
                {message.Pledge && (
                  <img style={{ width: 11 }} src="/Pledge1.svg" />
                )}
                {new Date().getTime() - message.Timestamp.seconds * 1000 <
                60000 ? (
                  <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                    {Math.floor(
                      (new Date().getTime() -
                        message.Timestamp.seconds * 1000) /
                        1000
                    )}
                    秒前
                  </div>
                ) : new Date().getTime() - message.Timestamp.seconds * 1000 <
                  3600000 ? (
                  <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                    {Math.floor(
                      (new Date().getTime() -
                        message.Timestamp.seconds * 1000) /
                        60000
                    )}
                    分前
                  </div>
                ) : new Date().getTime() - message.Timestamp.seconds * 1000 <
                  86400000 ? (
                  <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                    {Math.floor(
                      (new Date().getTime() -
                        message.Timestamp.seconds * 1000) /
                        3600000
                    )}
                    時間前
                  </div>
                ) : new Date().getTime() - message.Timestamp.seconds * 1000 >
                    86400000 &&
                  new Date().getTime() - message.Timestamp.seconds * 1000 <
                    604800000 ? (
                  <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                    {Math.floor(
                      (new Date().getTime() -
                        message.Timestamp.seconds * 1000) /
                        86400000
                    )}
                    日前
                  </div>
                ) : (
                  <div style={{ fontSize: 12, marginLeft: 5, marginTop: 2 }}>
                    {message.Timestamp.toDate().getFullYear() +
                      "/" +
                      ("0" + (message.Timestamp.toDate().getMonth() + 1)).slice(
                        -2
                      ) +
                      "/" +
                      ("0" + message.Timestamp.toDate().getDate()).slice(-2)}
                  </div>
                )}
              </div>
              <div className="messagetext">{message.text}</div>
              {message.image1 && (
                <div>
                  <img
                    style={{
                      width: 50,
                      borderRadius: 15,
                    }}
                    src={message.image1}
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={
                      read
                      //message.text ? read : undefined
                    }
                  />
                </div>
              )}
              {message.video && (
                <video
                  id={[message.id, message.from, message.to, message.Opened]}
                  onClick={read}
                  width="100"
                  height="70"
                  controls
                  autoPlay={message.Opened === false && true}
                  playsInline
                  muted
                  loop
                  style={{
                    objectFit: "cover",
                    borderRadius: 15,
                  }}
                >
                  <source type="video/mp4" src={message.video} />
                </video>
              )}
            </div>
            <span className="newmessage">
              {message.Opened === false && <img src="/NewMessage.svg" />}
            </span>
          </div>
        ))}
      {pledgemessage &&
        pledgemessages.map((message) => (
          <div className="drops2" key={message.id}>
            <img
              src={message.MessagePic}
              className="current4"
              onClick={(e) =>
                navigate(
                  `/UserView/${message.from === id ? message.to : message.from}`
                )
              }
              style={{
                background:
                  message.MessagePic === "/Profile3.svg" &&
                  "linear-gradient(#121112, #42b1e0)",
                marginTop: !message.text && 3,
              }}
            />
            <span
              id={[message.id, message.from, message.to, message.Opened]}
              className="alldrops1"
              onClick={read}
            ></span>

            <div className="messageline">
              <div className="messagename">
                <span
                  style={{
                    background: "linear-gradient(#b042b0, #42b1e0)",
                    borderRadius: 20,
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  {message.from === id
                    ? message.RecieveName
                    : message.MessageName}
                </span>
                {message.Pledge && (
                  <img style={{ width: 11 }} src="/Pledge1.svg" />
                )}
              </div>
              <div className="messagetext">{message.text}</div>
              {message.image1 && (
                <div>
                  <img
                    style={{
                      width: 50,
                      borderRadius: 15,
                    }}
                    src={message.image1}
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={
                      read
                      //message.text ? read : undefined
                    }
                  />
                </div>
              )}
              {message.video && (
                <video
                  id={[message.id, message.from, message.to, message.Opened]}
                  onClick={read}
                  width="100"
                  height="70"
                  controls
                  playsInline
                  muted
                  loop
                  style={{
                    objectFit: "cover",
                    borderRadius: 15,
                  }}
                >
                  <source type="video/mp4" src={message.video} />
                </video>
              )}
            </div>
            <span className="newmessage">
              {message.Opened === false && <img src="/NewMessage.svg" />}
            </span>
          </div>
        ))}
      {rankmessage &&
        rankmessages.map((message) => (
          <div className="drops2" key={message.id}>
            <img
              src={message.MessagePic}
              className="current4"
              onClick={(e) =>
                navigate(
                  `/UserView/${message.from === id ? message.to : message.from}`
                )
              }
              style={{
                background:
                  message.MessagePic === "/Profile3.svg" &&
                  "linear-gradient(#121112, #42b1e0)",
                marginTop: !message.text && 3,
              }}
            />
            <span
              id={[message.id, message.from, message.to, message.Opened]}
              className="alldrops1"
              onClick={read}
            ></span>

            <div className="messageline">
              <div className="messagename">
                <span
                  style={{
                    background: "linear-gradient(#b042b0, #42b1e0)",
                    borderRadius: 20,
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  {message.from === id
                    ? message.RecieveName
                    : message.MessageName}
                </span>
                {message.Pledge && (
                  <img style={{ width: 11 }} src="/Pledge1.svg" />
                )}
              </div>
              <div className="messagetext">{message.text}</div>
              {message.image1 && (
                <div>
                  <img
                    style={{
                      width: 50,
                      borderRadius: 15,
                    }}
                    src={message.image1}
                    id={[message.id, message.from, message.to, message.Opened]}
                    onClick={message.text && read}
                  />
                </div>
              )}
              {message.video && (
                <video
                  id={[message.id, message.from, message.to, message.Opened]}
                  onClick={read}
                  width="100"
                  height="70"
                  controls
                  playsInline
                  muted
                  loop
                  style={{
                    objectFit: "cover",
                    borderRadius: 15,
                  }}
                >
                  <source type="video/mp4" src={message.video} />
                </video>
              )}
            </div>
            <span className="newmessage">
              {message.Opened === false && <img src="/NewMessage.svg" />}
            </span>
          </div>
        ))}
      <LowControl />
    </div>
  );
}

export default MessageView;
