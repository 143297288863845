import React, { useState, useEffect } from "react";

function CreditLaws() {
  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ marginTop: 30 }}>
        <div style={{ fontSize: 30, textAlign: "center" }}>
          プライバシーポリシー
        </div>
        ＿＿＿＿＿＿＿＿（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
        第1条（個人情報）
        「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
        第2条（個人情報の収集方法）
        当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
        第3条（個人情報を収集・利用する目的）
        当社が個人情報を収集・利用する目的は，以下のとおりです。
        当社サービスの提供・運営のため
        ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
        ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
        メンテナンス，重要なお知らせなど必要に応じたご連絡のため
        利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
        ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
        有料サービスにおいて，ユーザーに利用料金を請求するため
        上記の利用目的に付随する目的 第4条（利用目的の変更）
        当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
        利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
        第5条（個人情報の第三者提供）
        当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
        公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
        国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
        利用目的に第三者への提供を含むこと 第三者に提供されるデータの項目
        第三者への提供の手段または方法
        本人の求めに応じて個人情報の第三者への提供を停止すること
        本人の求めを受け付ける方法
        前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
        当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
        合併その他の事由による事業の承継に伴って個人情報が提供される場合
        個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
        第6条（個人情報の開示）
        当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
        本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
        当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
        その他法令に違反することとなる場合
        前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
        第7条（個人情報の訂正および削除）
        ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
        当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
        当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
        第8条（個人情報の利用停止等）
        当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
        前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
        当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
        前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
        第9条（プライバシーポリシーの変更）
        本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
        当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
        {/*第10条（お問い合わせ窓口）
        本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
        住所： 社名： 代表取締役： 担当部署： Eメールアドレス：*/}
        <div style={{ marginTop: 30, fontSize: 30, textAlign: "center" }}>
          Google 広告
        </div>
        Cookie Google は、Google のパートナー ウェブサイト（Google
        広告を掲載するウェブサイトや Google
        の認定広告ネットワークに加盟するウェブサイトなど）への広告配信に、Cookie
        を使用しています。エンドユーザーが Google
        のパートナーのサイトを訪問すると、そのユーザーのブラウザに Cookie
        が保存されることがあります。 サイトのプライバシー ポリシーについて
        プライバシー ポリシーには次の情報を記載する必要があります。 Google
        などの第三者配信事業者が Cookie
        を使用して、ユーザーがそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信すること。
        Google が広告 Cookie
        を使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、Google
        やそのパートナーが適切な広告をユーザーに表示できること。
        ユーザーは、広告設定でパーソナライズ広告を無効にできること（または、www.aboutads.info
        にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者の Cookie
        を無効にできること）。
        第三者配信による広告掲載を無効にしていない場合、広告の配信時に第三者配信事業者や広告ネットワークの
        Cookie
        が使用される可能性があります。その点についても、次の方法でサイトのプライバシー
        ポリシーに明示してください。
        第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることを明記します。
        対象となる第三者配信事業者や広告ネットワークの適切なウェブサイトへのリンクを掲載します。
        これらのウェブサイトにアクセスすれば、パーソナライズド広告の掲載で使用される
        Cookie
        を無効にできることをユーザーに明示します（配信事業者や広告ネットワークがこの機能を提供している場合）。または、www.aboutads.info
        にアクセスすれば、第三者配信事業者がパーソナライズド広告の掲載で使用する
        Cookie を無効にできることを知らせます。
        サイト運営者様のサイトや法律は国ごとに異なるため、Google でプライバシー
        ポリシーに関する具体的な文面を提案することはできません。サイトのプライバシー
        ポリシーを作成する際には、Network Advertising Initiative
        などのリソースを参考にされることをおすすめします。Cookie
        使用の同意を得るための情報通知に関する詳細については、cookiechoices.org
        をご覧ください。
      </div>
      <div style={{ textAlign: "center", marginTop: 30, fontSize: 30 }}>
        特定商取引法に基づく表記;
      </div>{" "}
      <div style={{ textAlign: "center" }}>販売業社の名称:田所ジャマール</div>
      <div style={{ textAlign: "center" }}>
        事業所の住所:請求があったら遅滞なく開示します
      </div>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        電話番号:請求があったら遅滞なく開示します サポート対応時間: 10:00-17:00
      </div>
      <div style={{ textAlign: "center" }}>
        メールアドレス:Eplise@protonmail.com{" "}
      </div>
      <div style={{ textAlign: "center" }}>
        運営統括責任者 ビジネスの代表:田所ジャマール{" "}
      </div>
      <div style={{ textAlign: "center" }}>
        追加手数料等の追加料金商品・役務の代金以外に、顧客が負担する可能性のあるすべての料金
        カード払い:金額から3.6%の手数料 コンビニ決済：金額から3.6%の手数料
        税金：10%
        Epliseは20%の手数料を有料プランを提供してるユーザーからいただきます。（払う側のユーザーには20%は加算されない）
      </div>
      <div style={{ textAlign: "center" }}>
        （返金ポリシー）
        返金は入会した有料プランに記載されたサービスが実行されていないとこちらの調査で判定した場合のみ返金を受理します。
        その他の返金は行っておりません。
      </div>
      <div>
        {" "}
        クレジットカードまたは国内の銀行振込 決済期間
        顧客が商品購入代金を支払う時期
        クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から
        3 日以内にお振り込みいただく必要があります。
      </div>
    </div>
  );
}
export default CreditLaws;
