import React, { useState, useEffect } from "react";
import "./Block.css";

function Block({ understandblocked }) {
  const [progresspercent, setProgresspercent] = useState(null);

  return (
    <div className="blockbox">
      <div
        style={{
          color: "crimson",
          display: "flex",
          justifyContent: "center",
        }}
      >
        [ あなたはこのユーザーにブロックされています ]
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="blockboxbutton" onClick={understandblocked}>
          確認
        </button>
      </div>
    </div>
  );
}

export default Block;
