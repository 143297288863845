import React, { useState, useEffect } from "react";
import { auth, db } from "./Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./UserSettings.css";

function UserSettings() {
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setId(user.uid);
        const AccountRef = doc(db, "users", user.uid);
        getDoc(AccountRef).then((documentSnapshot) => {
          documentSnapshot.data().Account === "Public"
            ? setPrivateon(false)
            : setPrivateon(true);
          documentSnapshot.data().Message_From_All === "Yes"
            ? setMessageristrictions(false)
            : setMessageristrictions(true);
          documentSnapshot.data().Message_Only_From_Followers === "Yes"
            ? setMessageristrictfollowers(true)
            : setMessageristrictfollowers(false);
          documentSnapshot.data().Message_Only_From_Pledge === "Yes"
            ? setMessageristrictpledge(true)
            : setMessageristrictpledge(false);
          if (documentSnapshot.data().Message_Rank_Restrictions > 0) {
            setRealmessageristrictrank(
              documentSnapshot.data().Message_Rank_Restrictions
            );
            setMessageristrictrankon(true);
            setMessageristrictions(true);
          }
          documentSnapshot.data().Message_Rank_Restrictions == 0 &&
            setMessageristrictrankon(false);
          documentSnapshot.data().Comments_From_All === "Yes"
            ? setCommentristrictions(false)
            : setCommentristrictions(true);
          documentSnapshot.data().Comments_Only_From_Followers === "Yes"
            ? setCommentristrictfollowers(true)
            : setCommentristrictfollowers(false);
          documentSnapshot.data().Comments_Only_From_Pledge === "Yes"
            ? setCommentristrictpledge(true)
            : setCommentristrictpledge(false);
          if (documentSnapshot.data().Comments_Rank_Restrictions > 0) {
            setRealcommentristrictrank(
              documentSnapshot.data().Comments_Rank_Restrictions
            );
            setCommentristrictrankon(true);
            setCommentristrictions(true);
          }
          documentSnapshot.data().Comments_Rank_Restrictions == 0 &&
            setCommentristrictrankon(false);
        });
      }
    });
  }, []);

  const [id, setId] = useState("");
  const [privateon, setPrivateon] = useState("");
  const [messageristrictions, setMessageristrictions] = useState("");
  const [messageristrictfollowers, setMessageristrictfollowers] = useState("");
  const [messageristrictpledge, setMessageristrictpledge] = useState("");
  const [messageristrictrank, setMessageristrictrank] = useState("");
  const [rankrestrictsetting, setRankrestrictsetting] = useState(false);
  const [messageristrictrankon, setMessageristrictrankon] = useState(false);
  const [realmessageristrictrank, setRealmessageristrictrank] = useState(false);
  const [commentristrictfollowers, setCommentristrictfollowers] = useState("");
  const [commentristrictpledge, setCommentristrictpledge] = useState("");
  const [commentristrictrankon, setCommentristrictrankon] = useState(false);
  const [realcommentristrictrank, setRealcommentristrictrank] = useState(false);
  const [commentristrictions, setCommentristrictions] = useState("");
  const [rankrestrictsetting1, setRankrestrictsetting1] = useState(false);
  const [commentristrictrank, setCommentristrictrank] = useState("");

  const MakePrivate = () => {
    updateDoc(doc(db, "users", id), {
      Account: "Private",
    });
    setPrivateon(true);
  };

  const MakePublic = () => {
    updateDoc(doc(db, "users", id), {
      Account: "Public",
    });
    setPrivateon(false);
  };

  const messageunrestrict = () => {
    if (messageristrictions === true) {
      updateDoc(doc(db, "users", id), {
        Message_From_All: "Yes",
        Message_Only_From_Followers: "No",
        Message_Only_From_Pledge: "No",
        Message_Rank_Restrictions: 0,
      });
      setMessageristrictions(false);
      setMessageristrictfollowers(false);
      setMessageristrictpledge(false);
      setMessageristrictrankon(false);
      setRankrestrictsetting(false);
      setMessageristrictrank("");
    } else {
      updateDoc(doc(db, "users", id), {
        Message_From_All: "No",
      });
      setMessageristrictions(true);
    }
  };

  const messageristrict = () => {
    if (messageristrictions === false) {
      updateDoc(doc(db, "users", id), {
        Message_From_All: "No",
        Message_Only_From_Followers: "Yes",
        Message_Only_From_Pledge: "Yes",
      });
      setMessageristrictions(true);
      setMessageristrictfollowers(true);
      setMessageristrictpledge(true);
    } else if (
      messageristrictions === true &&
      messageristrictfollowers === true
    ) {
      updateDoc(doc(db, "users", id), {
        Message_Only_From_Followers: "No",
      });
      setMessageristrictfollowers(false);
    } else if (
      messageristrictfollowers === false &&
      messageristrictions === true
    ) {
      updateDoc(doc(db, "users", id), {
        Message_Only_From_Followers: "Yes",
        Message_Only_From_Pledge: "Yes",
      });
      setMessageristrictfollowers(true);
      setMessageristrictpledge(true);
    }
  };

  const pledgeristrict = () => {
    if (messageristrictions === false) {
      updateDoc(doc(db, "users", id), {
        Message_Only_From_Pledge: "Yes",
        Message_From_All: "No",
      });
      setMessageristrictpledge(true);
      setMessageristrictions(true);
    } else if (messageristrictpledge === true) {
      updateDoc(doc(db, "users", id), {
        Message_Only_From_Pledge: "No",
      });
      setMessageristrictpledge(false);
    } else if (
      messageristrictpledge === false &&
      messageristrictions === true
    ) {
      updateDoc(doc(db, "users", id), {
        Message_Only_From_Pledge: "Yes",
      });
      setMessageristrictpledge(true);
    }
  };

  const askrankset = () => {
    if (rankrestrictsetting === false) {
      setRankrestrictsetting(true);
    } else if (rankrestrictsetting === true) {
      setRankrestrictsetting(false);
      setMessageristrictrank("");
      realmessageristrictrank !== false && setMessageristrictrankon(true);
    }
  };

  const finalmessageristrictrank = () => {
    !(messageristrictrank >= 0) && alert("数字にしてください");
    messageristrictrank === "" && alert("数字にしてください");
    console.log(messageristrictrank);
    if (messageristrictrank >= 1 && messageristrictrank !== "") {
      setMessageristrictrankon(true);
      console.log(messageristrictrankon);
      setRankrestrictsetting(false);
      setMessageristrictions(true);
      setRealmessageristrictrank(messageristrictrank);
      updateDoc(doc(db, "users", id), {
        Message_Rank_Restrictions: messageristrictrank,
      }).then(() => {
        setMessageristrictrank("");
      });
    } else if (messageristrictrank == 0 && messageristrictrank !== "") {
      setMessageristrictrankon(false);
      console.log(messageristrictrankon);
      setRankrestrictsetting(false);
      setMessageristrictrank("");
      updateDoc(doc(db, "users", id), {
        Message_Rank_Restrictions: 0,
      });
    }
  };

  const cancelmessageristrictrank = () => {
    setRankrestrictsetting(false);
    setMessageristrictrank("");
    realmessageristrictrank !== false && setMessageristrictrankon(true);
  };

  const chooserank = (e) => {
    setMessageristrictrankon(false);
    setMessageristrictrank(e.target.value);
  };

  const commentunrestrict = () => {
    if (commentristrictions === true) {
      updateDoc(doc(db, "users", id), {
        Comments_From_All: "Yes",
        Comments_Only_From_Followers: "No",
        Comments_Only_From_Pledge: "No",
        Comments_Rank_Restrictions: 0,
      });
      setCommentristrictions(false);
      setCommentristrictfollowers(false);
      setCommentristrictpledge(false);
      setCommentristrictrankon(false);
      setRankrestrictsetting1(false);
      setCommentristrictrank("");
    } else {
      updateDoc(doc(db, "users", id), {
        Comments_From_All: "No",
      });
      setCommentristrictions(true);
    }
  };

  const commentristrict = () => {
    if (commentristrictions === false) {
      updateDoc(doc(db, "users", id), {
        Comments_From_All: "No",
        Comments_Only_From_Followers: "Yes",
        Comments_Only_From_Pledge: "Yes",
      });
      setCommentristrictions(true);
      setCommentristrictfollowers(true);
      setCommentristrictpledge(true);
    } else if (
      commentristrictions === true &&
      commentristrictfollowers === true
    ) {
      updateDoc(doc(db, "users", id), {
        Comments_Only_From_Followers: "No",
      });
      setCommentristrictfollowers(false);
    } else if (
      commentristrictfollowers === false &&
      commentristrictions === true
    ) {
      updateDoc(doc(db, "users", id), {
        Comments_Only_From_Followers: "Yes",
        Comments_Only_From_Pledge: "Yes",
      });
      setCommentristrictfollowers(true);
      setCommentristrictpledge(true);
    }
  };

  const pledgecommentristrict = () => {
    if (commentristrictions === false) {
      updateDoc(doc(db, "users", id), {
        Comments_Only_From_Pledge: "Yes",
        Comments_From_All: "No",
      });
      setCommentristrictpledge(true);
      setCommentristrictions(true);
    } else if (commentristrictpledge === true) {
      updateDoc(doc(db, "users", id), {
        Comments_Only_From_Pledge: "No",
      });
      setCommentristrictpledge(false);
    } else if (
      commentristrictpledge === false &&
      commentristrictions === true
    ) {
      updateDoc(doc(db, "users", id), {
        Comments_Only_From_Pledge: "Yes",
      });
      setCommentristrictpledge(true);
    }
  };

  const askcommentrankset = () => {
    if (rankrestrictsetting1 === false) {
      setRankrestrictsetting1(true);
    } else if (rankrestrictsetting1 === true) {
      setRankrestrictsetting1(false);
      setCommentristrictrank("");
      realcommentristrictrank !== false && setCommentristrictrankon(true);
    }
  };

  const chooserank1 = (e) => {
    setCommentristrictrankon(false);
    setCommentristrictrank(e.target.value);
  };

  const finalcommentristrictrank = () => {
    !(commentristrictrank >= 0) && alert("数字にしてください");
    commentristrictrank === "" && alert("数字にしてください");
    console.log(commentristrictrank);
    if (commentristrictrank >= 1 && commentristrictrank !== "") {
      setCommentristrictrankon(true);
      console.log(commentristrictrankon);
      setRankrestrictsetting1(false);
      setCommentristrictions(true);
      setRealcommentristrictrank(commentristrictrank);
      updateDoc(doc(db, "users", id), {
        Comments_Rank_Restrictions: commentristrictrank,
      }).then(() => {
        setCommentristrictrank("");
      });
    } else if (commentristrictrank == 0 && commentristrictrank !== "") {
      setCommentristrictrankon(false);
      console.log(commentristrictrankon);
      setRankrestrictsetting1(false);
      setCommentristrictrank("");
      updateDoc(doc(db, "users", id), {
        Comments_Rank_Restrictions: 0,
      });
    }
  };

  const cancelcommentristrictrank = () => {
    setRankrestrictsetting1(false);
    setCommentristrictrank("");
    realcommentristrictrank !== false && setCommentristrictrankon(true);
  };

  return (
    <div className="SettingDesign">
      <div className="UserOpTop">
        <img src="/UserOption.svg" className="UserOp1" />
      </div>
      <div className="cashline">
        <hr />
      </div>
      <div className="Privacy">
        <img src="/Profile3.svg" className="Profile4" />
        <img src="/Arrow.svg" className="arrow" />
        <span className="PrivacyOptions">
          <img
            src="/Lock.svg"
            className={privateon === true ? "Locked1" : "Locked"}
            onClick={MakePrivate}
          />
          <img
            src="./Earthy.svg"
            className={privateon === false ? "Planet1" : "Planet2"}
            onClick={MakePublic}
          />
        </span>
      </div>
      <div className="cashline">
        <hr />
      </div>
      <div className="MessageRestrictions">
        <img src="/MessageIcon2.svg" className="MessageIcon" />
        <img src="/Arrow.svg" className="arrow1" />
        <img
          src="./Earthy.svg"
          className={!messageristrictions ? "Planet3" : "Planet4"}
          onClick={messageunrestrict}
        />
        <img
          className={!messageristrictfollowers ? "Profile2" : "Profile5"}
          src="/Profile4.svg"
          onClick={messageristrict}
        />
        <img
          className={!messageristrictpledge ? "pledge1" : "pledge2"}
          src="/Pledge1.svg"
          onClick={pledgeristrict}
        />
        <div className="rankarrow">
          <img
            className={!messageristrictrankon ? "rankd1" : "rankd2"}
            src="/Rank.svg"
            onClick={askrankset}
          />
          <img className="uparrow" src="/ArrowUp.svg" />
        </div>
      </div>
      {messageristrictrankon === false ? (
        <span
          className={
            messageristrictrank <= 9
              ? "rankn3"
              : messageristrictrank >= 10 && messageristrictrank <= 99
              ? "rankn2"
              : messageristrictrank > 99 && messageristrictrank < 1000
              ? "rankn4"
              : messageristrictrank > 999
              ? "rankn5"
              : null
          }
          onClick={askrankset}
        >
          {!messageristrictrank >= 0 && null}
          {messageristrictrank >= 0 && messageristrictrank}
        </span>
      ) : (
        <span
          className={
            realmessageristrictrank <= 9
              ? "rankn3"
              : realmessageristrictrank >= 10 && realmessageristrictrank <= 99
              ? "rankn2"
              : realmessageristrictrank > 99 && realmessageristrictrank < 1000
              ? "rankn4"
              : realmessageristrictrank > 999
              ? "rankn5"
              : null
          }
          onClick={askrankset}
        >
          {realmessageristrictrank}
        </span>
      )}

      <div className="cashline">
        <hr />
      </div>
      <div className="CommentRestrictions">
        <img className="commenttap1" src="./Comment1.svg" />
        <img src="/Arrow.svg" className="arrow1" />
        <img
          src="./Earthy.svg"
          className={!commentristrictions ? "Planet3" : "Planet4"}
          onClick={commentunrestrict}
        />
        <img
          className={!commentristrictfollowers ? "Profile2" : "Profile5"}
          src="/Profile4.svg"
          onClick={commentristrict}
        />
        <img
          className={!commentristrictpledge ? "pledge1" : "pledge2"}
          src="/Pledge1.svg"
          onClick={pledgecommentristrict}
        />

        <div className="rankarrow">
          <img
            className={!commentristrictrankon ? "rankd1" : "rankd2"}
            src="/Rank.svg"
            onClick={askcommentrankset}
          />
          <img className="uparrow" src="/ArrowUp.svg" />
        </div>
      </div>
      {commentristrictrankon === false ? (
        <span
          className={
            commentristrictrank <= 9
              ? "rankn7"
              : commentristrictrank >= 10 && commentristrictrank <= 99
              ? "rankn6"
              : commentristrictrank > 99 && commentristrictrank < 1000
              ? "rankn8"
              : commentristrictrank > 999
              ? "rankn9"
              : null
          }
          onClick={askcommentrankset}
        >
          {!commentristrictrank >= 0 && null}
          {commentristrictrank >= 0 && commentristrictrank}
        </span>
      ) : (
        <span
          className={
            realcommentristrictrank <= 9
              ? "rankn7"
              : realcommentristrictrank >= 10 && realcommentristrictrank <= 99
              ? "rankn6"
              : realcommentristrictrank > 99 && realcommentristrictrank < 1000
              ? "rankn8"
              : realcommentristrictrank > 999
              ? "rankn9"
              : null
          }
          onClick={askcommentrankset}
        >
          {realcommentristrictrank}
        </span>
      )}
      <div className="cashline">
        <hr />
      </div>

      <div className="questionsin">ご質問/お問い合わせ to eplise</div>

      {rankrestrictsetting && (
        <div className="Blockrank">
          <div className="blockinsert">
            <input
              placeholder="あなたへメッセージを送れるランク..."
              onChange={chooserank}
            />
          </div>
          <span className="blockoption">
            <button onClick={finalmessageristrictrank}>セット</button>
            <button onClick={cancelmessageristrictrank}>キャンセル</button>
          </span>
        </div>
      )}
      {rankrestrictsetting1 && (
        <div className="Blockrank1">
          <div className="blockinsert">
            <input
              placeholder="あなたへコメントを送れるランク..."
              onChange={chooserank1}
            />
          </div>
          <span className="blockoption">
            <button onClick={finalcommentristrictrank}>セット</button>
            <button onClick={cancelcommentristrictrank}>キャンセル</button>
          </span>
        </div>
      )}
    </div>
  );
}
export default UserSettings;
