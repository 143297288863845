import React, { useState, useEffect } from "react";
import "./Progressbar.css";

function Progressbar({ percent }) {
  const [progresspercent, setProgresspercent] = useState(null);

  return (
    <div>
      {!percent ? (
        ""
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="ProgressbarGrey">
            <div
              style={{ width: `${percent}%` }}
              className="ProgressbarColored"
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Progressbar;
