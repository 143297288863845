import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css";

function LoadingAnimation({}) {
  //this is the amount of seconds the ad runs
  //maybe in the future use redux or use context api to get it since
  //you dont want to pass it down from connect page since you have to pass it down in two folds
  // and it will be considered prop drilling
  const [timer, setTimer] = useState(4);

  //this is the seconds that the ads last
  //however were not using this now
  const skiptimer = { "--d": "4s" };

  //premiumphotos.length is not good since the images dont come in in time
  //so go with the promos that are being picked, and see if it includes pledge
  //weird backgrund on mobile for skip button
  //back to fullscreenpromotion

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1300);
    }
  }, [timer]);

  const skipbuttonstyle = (currenttimer) => {
    let styling = currenttimer <= 0 ? [12, 3, 6, "center"] : [9.8, 5, 7, ""];
    //if (currenttimer <= 0 ) styling = [12,3,"center"]
    //else
    let styleobject = {
      color: "white",

      fontSize: styling[0],
      paddingLeft: styling[1],
      paddingTop: styling[2],
      textAlign: styling[3],
    };
    return styleobject;
  };

  return (
    <div className="LoadingAnimation" style={skiptimer}>
      <div
        /* style={{
          fontSize: timer <= 0 ? 12 : 11,
          color: "white",
          paddingTop: 6,
          paddingLeft: timer <= 0 ? 3 : 5,
          textAlign: timer <= 0 && "center",
        }}*/
        style={skipbuttonstyle(timer)}
      >
        {
          //make it a backtick to create space within javasript
        }
        スキップ {timer > 0 && `>> ${timer}`}
      </div>
    </div>
  );
}
export default LoadingAnimation;
