import React, { useState, useEffect, useRef } from "react";
import "./Ranks.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  where,
  query,
  collectionGroup,
  getDocs,
  getDoc,
  doc,
  orderBy,
  collection,
} from "firebase/firestore";
import { db, auth } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Paymentform1 from "./Paymentform1";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function Ranks() {
  const location = useLocation();
  const ref1 = useRef(null);
  const navigate = useNavigate();
  const Public_Key =
    "pk_test_51NMGCcBxT13QwRtaWZfi8ym0fzXzUxcQtWOYqcl0suDSVqm4LnvKiK6aw1eARISZ7zvUcokI2569kCB5BhDtG3Ew00UCu5WSMR";
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(Public_Key)
  );

  const finished = (answer) => {
    console.log(answer);
    setSuccess(answer);
  };

  const [allusers, setAllusers] = useState([]);
  const [openpayment, setOpenPayment] = useState(false);
  const [paymethod, setPaymethod] = useState("");
  const [price, setPrice] = useState("");
  const [newrank, setNewrank] = useState("");
  const [payintent, setPayintent] = useState(false);
  const [finalprice, setFinalprice] = useState("");
  const [success, setSuccess] = useState(false);
  const [newallusers, setNewallusers] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [waitingkonbini, setWaitingkonbini] = useState(false);
  const [konbiniinfo, setKonbiniinfo] = useState("");

  useEffect(() => {
    console.log(location.state[0]);
    console.log(location.state[1]);
    const userCollectionRef = collection(db, "users");
    getDocs(query(userCollectionRef, orderBy("Rank"))).then((querySnapshot) => {
      let rankings = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAllusers(rankings);
      let number = 0;
      rankings.map((post) => {
        if (
          location.state[0].id === post.id &&
          location.state[0].Total_Currency_Generated !==
            post.Total_Currency_Generated
        ) {
          console.log(rankings[number]);
          setUserinfo(rankings[number]);
        }
        number++;
      });

      const userCollectionRef2 = collection(
        db,
        "users",
        location.state[1],
        "OutstandingPay"
      );

      getDocs(query(userCollectionRef2), where("From", "==", "Rank")).then(
        (querySnapshot) => {
          let posts1 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(posts1);
          if (posts1.length > 0) {
            console.log(posts1);

            setWaitingkonbini(true);
            setKonbiniinfo(...posts1);
            console.log(posts1[0].Konbini_due);
            console.log(new Date(posts1[0].Konbini_due * 1000));
          }
        }
      );

      setTimeout(() => {
        console.log(ref1.current);
        ref1.current.scrollIntoView();
      });
    });
  }, []);

  const ranksimulate = (e) => {
    console.log(e.target.value);
    if (
      parseInt(e.target.value.charAt(0)) !== 0 &&
      isNaN(e.target.value) === false &&
      e.target.value !== "" &&
      !e.target.value.includes(".")
    ) {
      setPrice(e.target.value);

      let simulatetarget = JSON.parse(JSON.stringify(allusers));
      console.log(location.state[0]);
      if (e.target.value === "") {
        simulatetarget[
          (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
        ].Total_Currency_Generated =
          allusers[
            (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
          ].Total_Currency_Generated;
      } else {
        simulatetarget[
          (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
        ].Total_Currency_Generated =
          allusers[(userinfo ? userinfo.Rank : location.state[0].Rank) - 1]
            .Total_Currency_Generated +
          parseInt(e.target.value) * 2;
      }

      console.log(simulatetarget);
      simulatetarget = simulatetarget.sort(
        (a, b) => b.Total_Currency_Generated - a.Total_Currency_Generated
      );

      console.log(simulatetarget);

      setNewrank(
        simulatetarget.findIndex(
          (element) => location.state[0].id === element.id
        ) + 1
      );
      setNewallusers(simulatetarget);
      console.log(newallusers);
    } else if (e.target.value === "") {
      setNewrank("");
      setPrice("");
    }
  };

  const cancelpay = () => {
    setOpenPayment(false);
    setPayintent(false);
    setPrice("");
    setPaymethod("");
    setNewrank("");
  };

  const pay = () => {
    if (price < 120) {
      alert("金額は120円からです");
    } else {
      setOpenPayment(false);
      setPayintent(true);
    }
  };

  const taxes = (e) => {
    console.log(price);
    console.log(e.target.id);
    setPaymethod(e.target.id);
    if (e.target.id === "card" || e.target.id === "konbini") {
      setFinalprice(Math.round(parseInt(price) * 0.136 + parseInt(price)));
    } else if (e.target.id === "jp_bank-transfer") {
      setFinalprice(Math.round(parseInt(price) * 0.136 + parseInt(price)));
    }
  };

  return (
    <div>
      <img src="./Planet1.svg" className="Planet" />
      <img className="Rankimage" src={location.state[0].Profile_image} />
      {!openpayment && !payintent && (
        <>
          <div className="Rankup" onClick={(e) => setOpenPayment(true)}>
            <button>今すぐランクを上げる</button>
          </div>
          {waitingkonbini && (
            <>
              <div>コンビニ支払いのご案内 </div>
              <div
                style={{
                  borderBottom: "1px solid black",
                  paddingBottom: 10,
                  marginBottom: 10,
                }}
              >
                有効期限:
                {new Date(konbiniinfo.Konbini_due * 1000).getFullYear()}年/
                {new Date(konbiniinfo.Konbini_due * 1000).getMonth() + 1}
                月/
                {new Date(konbiniinfo.Konbini_due * 1000).getDate()}日 金額:
                {konbiniinfo.FullPrice}円 第1番号:{konbiniinfo.Payment_code}
                第2番号:{konbiniinfo.Phone}
              </div>
            </>
          )}
        </>
      )}
      {openpayment && (
        <>
          <div className="Border">
            <div style={{ textAlign: "center" }}>
              {userinfo ? userinfo.Rank : location.state[0].Rank} ⇒{newrank}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              金額指定:
              <input
                value={price}
                onChange={ranksimulate}
                placeholder="円"
              ></input>
            </div>
            <div style={{ textAlign: "center" }}>
              {" "}
              ボーナス:2倍ランクアップ↑
            </div>
          </div>

          <div className="Pselect">
            <button disabled={price === "" && true} onClick={pay}>
              〇
            </button>
            <button style={{ width: 40 }} onClick={cancelpay}>
              X
            </button>
          </div>
        </>
      )}
      {payintent && !success ? (
        <>
          <div className="Pselect">
            <button style={{ width: 40 }} onClick={cancelpay}>
              X
            </button>
          </div>
          お支払い方法：
          <div id={"card"} onClick={taxes}>
            クレジット決済
          </div>
          {!waitingkonbini && (
            <div id={"konbini"} onClick={taxes}>
              コンビニ決済
            </div>
          )}
          <div id={"jp_bank-transfer"} onClick={taxes}>
            口座振り込み、
          </div>
          {paymethod && (
            <>
              {price}
              <div>
                手数料:
                {paymethod === "card" || paymethod === "konbini"
                  ? Math.round(parseInt(price) * 0.036)
                  : paymethod === "jp_bank-transfer"
                  ? Math.round(parseInt(price) * 0.015)
                  : null}
              </div>
              <div>消費税:{Math.round(parseInt(price) * 0.1)}</div>
              {finalprice}
            </>
          )}
          <Elements stripe={stripePromise}>
            <Paymentform1
              Paymethod={paymethod}
              Price={price}
              FinalPrice={finalprice}
              Myid={location.state[0].id}
              Finished={finished}
              Allusers={newallusers}
              Rank={userinfo ? userinfo.Rank : location.state[0].Rank}
              Newrank={newrank}
            />
          </Elements>
        </>
      ) : (
        (paymethod === "card" && (
          <>
            <div>お支払い完了</div>
            <div>...少々お待ちください</div>
          </>
        )) ||
        (paymethod === "konbini" && (
          <>
            <div>コンビニ支払い受付完了</div>
            <div>...少々お待ちください</div>
          </>
        ))
      )}
      {allusers.map((alluser) => (
        <div
          key={alluser.id}
          ref={location.state[1] === alluser.id ? ref1 : null}
          onClick={(e) => navigate(`/UserView/${alluser.id}`)}
        >
          <div className="allrankcolumn">
            <img className="Rankimage" src={alluser.Profile_image} />
            <div className="rankcolumn">
              {alluser.Rank}

              <div className="nameletter">{alluser.User_Name}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Ranks;
