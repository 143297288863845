import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "./Firebase.js";
import "./Pledge.css";
import FileFullScreen from "./Customhooks/FileFullScreen.js";

function Pledge() {
  const { userid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref1 = useRef(null);

  const [viewinfo, setViewinfo] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [premiumplans, setPremiumplans] = useState([]);
  const [premiumphotos, setPremiumphotos] = useState([]);
  const [unblur, setUnblur] = useState("");
  const [fullscreentarget, setFullscreentarget] = useState([]);

  useEffect(() => {
    console.log(location.state);

    console.log(userid);
    const userDocumentRef = doc(db, "users", userid);
    getDoc(userDocumentRef).then((documentSnapshot) => {
      setViewinfo(documentSnapshot.data());
    });

    const userCollectionRef = collection(db, "users", userid, "PledgePlan");
    getDocs(query(userCollectionRef, orderBy("Timestamp", "desc"))).then(
      (querySnapshot) => {
        setPremiumplans(
          querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      }
    );

    const userCollectionRef2 = collection(db, "users", userid, "PledgeHold");
    getDocs(userCollectionRef2).then((querySnapshot) => {
      let blured = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const userCollectionRef1 = collection(
        db,
        "users",
        userid,
        "PledgePhotos"
      );
      getDocs(query(userCollectionRef1, orderBy("Timestamp", "desc"))).then(
        (querySnapshot) => {
          let photos = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            blur: true,
          }));

          photos.forEach((photo) =>
            blured.forEach((blur) => {
              if (
                photo.plan === blur.Pledgeid &&
                blur.PaidUser === location.state[0]?.id
              ) {
                photo.blur = false;
              }
            })
          );
          console.log(photos);
          setTimeout(() => {
            setPremiumphotos(photos);
          });
        }
      );
    });
  }, []);

  const askunblur = (e) => {
    let which = premiumphotos.findIndex(
      (element) => e.target.id === element.id
    );
    console.log(e.target.id);
    console.log(which);
    console.log(premiumphotos[which]);
    if (premiumphotos[which].blur) {
      console.log(premiumphotos[which].plan);
      setUnblur(premiumphotos[which].plan);
      setTimeout(() => {
        const element = ref1.current;
        element.scrollIntoView();

        setTimeout(() => {
          setUnblur("");
        }, 2000);
      });
    }
  };

  //const Fullscreen = (e) => {};
  const Closefullscreen = (e) => {
    setFullscreentarget([]);
  };

  return (
    <div
      style={{
        position: fullscreentarget.length > 0 && "fixed",
        width: fullscreentarget.length > 0 && "100%",
      }}
    >
      {fullscreentarget.length === 0 ? (
        <Link //to={`/UserView/${userid}`}
          to={
            location.state[1] !== "Direct" ? `/Connect` : `/UserView/${userid}`
          }
          state={location.state[1] === "Direct" ? "SeenPledge" : undefined}
        >
          <img
            style={{ zIndex: 2, position: "absolute", top: 5, left: 4 }}
            src="/Arrow1.svg"
          />
        </Link>
      ) : (
        <div
          style={{
            position: "fixed",

            width: "100%",
            height: "100%",
            //overflowX: "clip",
            zIndex: 2,
          }}
        >
          {
            //1 main mission, (1 copy and paste mission, or Testing), 1 reusable component mission, 1 iphone mission, 1 css
            ////////////////////////////////////
            //start prepping for pledge 広告 and be able to click it with right way to press back button as well
            //the design of the error for sign up is a lil ugly
            //make にゅん clickable too
            //full screen images in messsage, posts in user, connect,notification
            //(
            //(can do lil bit at a time)
            //::::::work on fourfiles for tempfiles resuable compoenent
            //::::::fourfiles for posts/comments videos and images files as separate re usuable component
            //::::::displaying alldisplay and simposts reusable component
            //::::::exportable functions for sendcomment
            //::::::exportable functions for deletecomment
            //::::::any other reusable component
            //)
            //full screen comments in userview, user, connect,notification
            //full screen userview headers
            //implement videos being fullscreen,
            //full screen videos in userview, user, connect,notification
            //full screen comments videos in userview, user, connect,notification
            //make sure that when turned sideways on mobile videos are able to be in perfect movie style
            //make sure you can do this in connect, user page, user view page as well
            //check in mobile
            //go back to confsend slash slash in messages
          }
          <FileFullScreen
            file={fullscreentarget[0]}
            type={fullscreentarget[1]}
            close={Closefullscreen}
          />
        </div>
      )}

      <div className="picline">
        <img
          src={
            viewinfo?.Pledgepic ? viewinfo.Pledgepic : viewinfo.Profile_image
          }
          className="Pledgepic"
        />
      </div>
      <div
        className="allplans1"
        style={{ justifyContent: premiumplans.length < 4 && "center" }}
      >
        {premiumplans.map((premiumplan) => (
          <div
            key={premiumplan.id}
            onClick={(e) =>
              navigate(`/PledgeSubscribe/${userid}`, {
                state: [premiumplan, location.state[0]],
              })
            }
          >
            <div
              ref={unblur === premiumplan.id ? ref1 : null}
              className="premiumplans"
              style={{ paddingLeft: premiumplans.length > 1 && 10 }}
            >
              <div
                className="premiumplans1"
                style={{
                  animation:
                    unblur === premiumplan.id && "flash 0.5s 4 forwards",
                }}
              >
                <div className="details">
                  <div
                    className="details1"
                    lang={
                      /^[a-zA-z]+$/.test(premiumplan.title) !== true && "ja"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flex: 1,
                      fontWeight: 200,
                      color: "gold",
                    }}
                  >
                    {premiumplan.title}
                  </div>
                </div>
                <div className="priceduration">
                  {premiumplan.price}円/{premiumplan.duration}
                </div>

                <div className="subscribeline">
                  <img style={{ width: 16 }} src="/Profile4.svg" />
                  <div style={{ fontSize: 9, marginLeft: 3 }}>
                    {premiumplan.subscribers}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 5 }} className="Premiumoption">
        <img style={{ width: 30, marginTop: 8 }} src="/Photo.svg" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: premiumphotos.length > 3 && "wrap",
        }}
      >
        {premiumphotos.map((premiumphoto) => (
          <div key={premiumphoto.id}>
            <img
              draggable="false"
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              id={premiumphoto.id}
              src={premiumphoto.image}
              onClick={
                premiumphoto.blur
                  ? askunblur
                  : () => setFullscreentarget([premiumphoto.image, "image"])
              }
              style={{
                WebkitTouchCallout: "none",

                width:
                  premiumphotos.length === 1
                    ? 240
                    : premiumphotos.length === 2
                    ? 170
                    : premiumphotos.length > 2
                    ? 110
                    : null,
                height:
                  premiumphotos.length === 1
                    ? 220
                    : premiumphotos.length === 2
                    ? 150
                    : premiumphotos.length > 2
                    ? 90
                    : null,

                borderRadius: premiumphotos.length === 2 ? 40 : 15,
                marginTop: 8,
                marginLeft: 10,
                filter:
                  premiumphoto.blur && `blur(${premiumphoto.blurlevel}px)`,

                // pointerEvents: "none",
                userSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                MozUserSelect: "none",
              }}
            />
            {premiumphoto.blur && (
              <div className="pledgeoptions">
                <img
                  src="/Lock.svg"
                  style={{
                    width: 13,
                    position: "relative",
                    bottom:
                      premiumphotos.length === 1
                        ? 222
                        : premiumphotos.length === 2
                        ? 153
                        : premiumphotos.length > 2
                        ? 95
                        : null,
                    left:
                      premiumphotos.length === 1
                        ? 220
                        : premiumphotos.length === 2
                        ? 132
                        : premiumphotos.length > 2
                        ? 95
                        : null,
                  }}
                  id={premiumphoto.id}
                  onClick={askunblur}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pledge;
