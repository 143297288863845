import React, { useState, useEffect, useRef } from "react";
import "./Paymentform.css";
import {
  updateDoc,
  doc,
  addDoc,
  collection,
  increment,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "./Firebase";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
const Card_Options = {
  iconStyle: "solid",

  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto,Open Sans,Segoe UI,sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },

    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function Paymentform({
  Paymethod,
  Price,
  Finished,
  Userid,
  Pledgeid,
  ExpireNumber,
  Symbol,
  FinalPrice,
  Userinfo,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const ref1 = useRef(false);

  const [carderror, setCarderror] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [client, setClient] = useState("");
  const [clientid, setClientid] = useState("");
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    if (Paymethod === "konbini") {
      console.log("YO");
      if (!ref1.current) {
        ref1.current = true;
        (async () => {
          try {
            const response = await axios.post(
              "http://localhost:4000/konbinipayment",
              {
                amount: FinalPrice,
                id: Paymethod,
              }
            );

            console.log(response.data);

            if (response.data.success) {
              console.log("konbini Ready");
              console.log(response.data.client_secret);
              console.log(response.data.id);
              setClient(response.data.client_secret);
              setClientid(response.data.id);
            }
          } catch (error) {
            console.log(error);
          }
        })();
      }
    }
  }, [Paymethod]);

  const paycard = async (e) => {
    e.preventDefault();
    setPressed(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: Paymethod,
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        console.log({ id });
        const response = await axios.post("http://localhost:4000/cardpayment", {
          amount: FinalPrice,
          id: id,
        });

        if (response.data.success) {
          Finished(true);
          console.log(Userid);

          console.log(Symbol);
          let dates = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 30];

          let b = 0;
          if (Symbol === "months") {
            for (let step = 0; step < ExpireNumber; step++) {
              b = b + dates[new Date().getMonth() + step];
            }
          }
          console.log(b);

          let a =
            Symbol === "weeks"
              ? ExpireNumber * 7
              : Symbol === "months"
              ? b
              : Symbol === "years"
              ? 365 * ExpireNumber
              : "";

          console.log(a);

          new Date(new Date().getTime() + 86400000 * a);

          addDoc(collection(db, "users", Userid, "PledgeHold"), {
            Pledgeid,
            PaidUser: Userinfo.id,
            Price,
            FinalPrice,
            ExpireNumber: new Date().getTime() + 86400000 * a,
            FullExpireDate: new Date(new Date().getTime() + 86400000 * a),
            Timestamp: serverTimestamp(),

            Profile_image: Userinfo.Profile_image,
            User_Name: Userinfo.User_Name,
            Rank: Userinfo.Rank,
          });

          updateDoc(doc(db, "users", Userid, "PledgePlan", Pledgeid), {
            subscribers: increment(1),
          });

          setTimeout(() => {
            navigate(`/Pledge/${Userid}`, {
              state: Userinfo.id,
            });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          setPressed(false);
        });
      }
    } else {
      console.log(error.message);
      if (error.message === "Your card number is incomplete.") {
        setCarderror("カード番号が間違っています");
      } else if (
        error.message === "Your card's expiration date is incomplete."
      ) {
        setCarderror("カードの有効期限情報が間違っています");
      } else if (
        error.message === "Your card's expiration year is in the past." ||
        error.message === "Your card's expiration date is in the past."
      ) {
        setCarderror("カードの有効期限が過ぎています");
      } else if (error.message === "Your card's security code is incomplete.") {
        setCarderror("セキュリティーコードが間違っています");
      }
      setTimeout(() => {
        setPressed(false);
      });
    }
  };

  const paybanktransfer = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: Paymethod,
      card: elements.getElement(CardElement),
    });
  };

  const paykonbini = async (e) => {
    e.preventDefault();

    setPressed(true);
    console.log(name);
    console.log(email);
    console.log(phone);
    console.log(client);

    try {
      const result = await stripe.confirmKonbiniPayment(client, {
        payment_method: {
          billing_details: {
            name,
            email,
          },
        },
        payment_method_options: {
          konbini: {
            confirmation_number: phone,
          },
        },
      });
      console.log(result);
      console.log(
        result.paymentIntent.next_action.konbini_display_details.expires_at
      );

      console.log(
        result.paymentIntent.next_action.konbini_display_details.stores
          .familymart.confirmation_number
      );

      let dates = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 30];

      let b = 0;
      if (Symbol === "months") {
        for (let step = 0; step < ExpireNumber; step++) {
          b = b + dates[new Date().getMonth() + step];
        }
      }
      console.log(b);

      let a =
        Symbol === "weeks"
          ? ExpireNumber * 7
          : Symbol === "months"
          ? b
          : Symbol === "years"
          ? 365 * ExpireNumber
          : "";

      console.log(a);

      addDoc(collection(db, "users", Userinfo.id, "OutstandingPay"), {
        Pledgeid,
        Price,
        FinalPrice,
        a,
        Directory: Userid,
        Konbini_due:
          result.paymentIntent.next_action.konbini_display_details.expires_at,
        Stripe_id: clientid,
        Phone:
          result.paymentIntent.next_action.konbini_display_details.stores
            .familymart.confirmation_number,
        Payment_code: parseInt(
          result.paymentIntent.next_action.konbini_display_details.stores
            .familymart.payment_code
        ),
        For: "Pledge",
      });

      updateDoc(doc(db, "users", Userid, "PledgePlan", Pledgeid), {
        subscribers: increment(1),
      });

      setTimeout(() => {
        Finished(true);
      });

      setTimeout(() => {
        navigate(`/Pledge/${Userid}`, {
          state: Userinfo.id,
        });
      }, 2000);
    } catch (error) {
      console.log(error);
      alert("情報が正しくありません");
      setTimeout(() => {
        setPressed(false);
      });
    }
  };

  return (
    <div>
      {Paymethod === "card" && (
        <>
          {carderror}
          <form className="payoptions" onSubmit={paycard}>
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={Card_Options} />
              </div>
            </fieldset>
            <button disabled={pressed}>支払う</button>
          </form>
        </>
      )}
      {Paymethod === "PayPay" && (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: 10 }}>
            このPayPayIDに提示の額を送り下さい :{" "}
          </span>
          <span style={{ color: "aqua" }}>eplise</span>
        </div>
      )}
      {Paymethod === "jp_bank-transfer" && <div>ただいま工事中..</div>}
      {Paymethod === "konbini" && (
        <div>
          <form id="payment-form" onSubmit={paykonbini}>
            <div
              class="form-row"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <label for="name">名前</label>
              <input
                className="inputonlybottomborder"
                id="name"
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>

            <div
              class="form-row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <label for="email">E-メール</label>
              <input
                className="inputonlybottomborder"
                id="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <div
              class="form-row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <label for="phone">電話番号</label>
              <input
                className="inputonlybottomborder"
                id="phone"
                name="phone"
                required
                onChange={(e) => setPhone(e.target.value)}
              ></input>
            </div>

            <div id="error-message" role="alert"></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button disabled={pressed} id="submit-button">
                コンビニ決済確定
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Paymentform;
