import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import { Link, useNavigate } from "react-router-dom";
import "./Currency.css";

function Currency() {
  const [id, setId] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [changepercent, setChangePercent] = useState(false);
  const [newdeduction, setNewDeduction] = useState("");
  const [bankregister, setBankregister] = useState(false);
  const [initialbankinfo, setInitialbankinfo] = useState({});

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        setId(userId);
        const userDocumentRef = doc(db, "users", user.uid);
        await getDoc(userDocumentRef).then((documentSnapshot) => {
          setUserinfo(documentSnapshot.data());
          setInitialbankinfo(documentSnapshot.data().Bankinformation);
        });
      }
    });
  }, []);

  const navigate = useNavigate();

  const DeductionChange = () => {
    console.log(isNaN(newdeduction));
    console.log(parseInt(newdeduction));

    if (
      parseInt(newdeduction) >= 20 &&
      parseInt(newdeduction) < 101 &&
      !newdeduction.includes(".")
    ) {
      updateDoc(doc(db, `users/${id}`), {
        Deduction: parseInt(newdeduction),
      });

      setChangePercent(false);
      setNewDeduction("");
      setUserinfo({ ...userinfo, Deduction: parseInt(newdeduction) });
    } else {
      alert("数字は20-100でないといけません");
    }
  };

  const CancelDeduction = () => {
    if (!changepercent) {
      setChangePercent(true);
    } else {
      setChangePercent(false);
      setNewDeduction("");
    }
  };

  const DeductionPrep = (e) => {
    console.log(!e.target.value.includes("."));
    console.log(e.target.value);
    if (
      parseInt(e.target.value.charAt(0)) !== 0 &&
      isNaN(e.target.value) === false &&
      e.target.value !== ""
    ) {
      setNewDeduction(e.target.value);
    } else if (e.target.value === "") {
      setNewDeduction("");
    }
  };

  const registerbankinfo = () => {
    console.log(Object.values(userinfo.Bankinformation).includes(!""));

    if (Object.values(userinfo.Bankinformation).includes("")) {
      alert("全ての項目に記入してください");
    } else {
      alert("お振込み先情報を更新しました");
      setInitialbankinfo({ ...userinfo.Bankinformation });
      setBankregister(false);
      updateDoc(doc(db, `users/${id}`), {
        Bankinformation: { ...userinfo.Bankinformation },
      });
    }
  };

  const cancelbankinfo = () => {
    setUserinfo({
      ...userinfo,
      Bankinformation: {
        ...initialbankinfo,
      },
    });
    setBankregister(false);
  };

  const bankinfoset = (e) => {
    console.log(e.target.id);
    console.log(e.target.value);
    let bankkey = e.target.id;
    setUserinfo((current) => ({
      ...current,

      Bankinformation: {
        ...current.Bankinformation,
        [bankkey]: e.target.value,
      },
    }));
    console.log(userinfo.Bankinformation);
  };

  return (
    <div className="cashpage">
      <img
        style={{ zIndex: 200, position: "absolute", top: 4, left: 4 }}
        src="/Arrow1.svg"
        onClick={(e) => navigate(`/User/${id}`)}
      />
      <div className="cashposition">
        <img className="cash1" src="/Cash.svg" />
      </div>
      <div className="cashline">
        <hr />
      </div>
      {!bankregister && (
        <>
          <div className="cashstatus">
            <span className="cashstatus_text">現在のWalletトータル</span>
            <span className="cashcolon">:</span>
            <span className="cashnumber">{userinfo.Current_Currency}</span>
          </div>
          <div className="cashline">
            <hr />
          </div>
          <div>次のランクに必要な金額:</div>
          <div>
            <div style={{ display: "block" }}>スピードランクアップ↑:</div>
            <div style={{ display: "flex" }}>
              <div onClick={CancelDeduction}>
                差し引かれパーセントの変更:
                {!changepercent && userinfo.Deduction}
              </div>
              {changepercent && (
                <input
                  style={{ width: 20, height: 15 }}
                  value={newdeduction}
                  onChange={DeductionPrep}
                  type="number"
                ></input>
              )}
              %
            </div>
            {changepercent && (
              <>
                <div style={{ fontSize: 8 }}>
                  1パーセントあげるごとに更に1.5倍ランクアップ↑
                </div>
                <div className="Pselect">
                  <button onClick={DeductionChange}>〇</button>
                  <button style={{ width: 40 }} onClick={CancelDeduction}>
                    X
                  </button>
                </div>
              </>
            )}

            <div style={{ display: "block" }}> チャージ: </div>
            <div style={{ display: "block" }}>支払い方法+ </div>
            <div
              onClick={(e) => setBankregister(true)}
              style={{ display: "block" }}
            >
              振込先:
            </div>

            <div style={{ display: "block" }}>振込依頼</div>
          </div>
        </>
      )}
      {bankregister && (
        <div>
          <div>
            金融機関名:
            <input
              type="text"
              onChange={bankinfoset}
              value={
                userinfo.Bankinformation.Bankname
                  ? userinfo.Bankinformation.Bankname
                  : ""
              }
              id={"Bankname"}
            ></input>
          </div>
          <div>
            支店名:
            <input
              type="text"
              onChange={bankinfoset}
              value={
                userinfo.Bankinformation.Branchname
                  ? userinfo.Bankinformation.Branchname
                  : ""
              }
              id={"Branchname"}
            ></input>
          </div>
          <div>
            口座番号:
            <input
              type="number"
              onChange={bankinfoset}
              value={
                userinfo.Bankinformation.Banknumber
                  ? userinfo.Bankinformation.Banknumber
                  : ""
              }
              id={"Banknumber"}
            ></input>
          </div>
          <div>
            口座名義:
            <input
              type="text"
              onChange={bankinfoset}
              value={
                userinfo.Bankinformation.Personname
                  ? userinfo.Bankinformation.Personname
                  : ""
              }
              id={"Personname"}
            ></input>
          </div>
          <div className="Pselect1">
            <button onClick={registerbankinfo}>〇</button>
            <button onClick={cancelbankinfo}>X</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Currency;
