import { io } from "socket.io-client";
const URL =
  process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";
/*const URL =
  process.env.NODE_ENV === "production" ? undefined : "http://192.168.1.5:4000";*/
//this needs to be an https connection as well
//const URL = "http://192.168.1.5:4000";
//const Socket = io(URL);
const Socket = io(URL, { autoConnect: false });
export default Socket;
