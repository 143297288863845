import React, { useState, useEffect } from "react";
import "./Main.css";
import { Link, useNavigate } from "react-router-dom";
import FakeConnect from "./FakeConnect.js";

function Main({ TurnFakeConnectOn }) {
  const [imageloaded, setImageloaded] = useState(false);
  const [componentloaded, setComponentloaded] = useState(false);
  const [urlname, setUrlName] = useState("");

  useEffect(() => {
    //const urlname1 = window.location.pathname;
    //console.log(urlname1);
    //console.log(urlname1 === "/Enter");
    //console.log(urlname1 === "/");
    setUrlName(window.location.pathname);
  }, []);

  const GoogleShinsa = () => {
    setComponentloaded(true);
    TurnFakeConnectOn();
  };

  return (
    /* <img className="Bottom" src="./test4.svg" />*/
    <div
      style={{
        overscrollBehaviorBlock: "none",
        overscrollBehaviorInline: "none",
        overscrollBehaviorY: "none",

        width: "100vw",
        ////////////////////this position fixed needs to be turned off when in google ad sense 審査
        // position: "fixed",
        position: !componentloaded ? "fixed" : "",
        //height: "100vh",
        //height: "100%",
        overflowX: "clip",
        // marginTop: -6.5,
        //borderWidth: "1px",
        //border: "solid black",
        //borderBottom: "none",
        /*border: "1px solid black",*/
        zIndex: -1,

        //this way you dont need to have borders, however the sizing is weird
        //background: "url(EpliseGirl.svg)",
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
      }}
    >
      <img
        className="Bottom"
        style={{
          display: "block",
          border: "1px solid black",

          /*borderTop: 0.5,*/
        }}
        onLoad={() => setImageloaded(true)}
        src="EpliseGirlEditCrosses.svg" //"EpliseGirlEditBubble.svg" //"EpliseGirlEdit.svg" //"EpliseGirl.svg"
      />

      <div onLoad={GoogleShinsa /*() => setComponentloaded(true)*/}>
        {urlname === "/" && <FakeConnect />}
      </div>

      {imageloaded && (
        <>
          {
            /*<img
            style={{
              position: "absolute",
              top: 0,
              zIndex: 1,
              animation: "neoneffect 4s infinite",
              //opacity: 0.5,
            }}
            className="Bottom"
            src=/*"EpliseGirlNeonAnimation1.svg"*/
            //"EpliseGirlNeonAnimation2.svg"
            /*/>*/
          }
          <Link to={`/CreditLaws`} style={{ textDecoration: "none" }}>
            <img
              style={{
                width: "100%",
                display: "block",
              }}
              src="Bottombar.svg"
            />
          </Link>
        </>
      )}
    </div>
  );
}

export default Main;
