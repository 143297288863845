import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import "./LowControl.css";

function LowControl({
  notification,
  messagenotification,
  currenturl,
  alldisplay,
  commentlength,
  clickedcomment,
  //dropsandcomments,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    /* onAuthStateChanged(auth, async (user) => {
      if (user) {
        setId(user.uid);
      }
    });
    console.log(notification);
    */
  }, []);

  return (
    <div
      style={{
        position:
          (alldisplay === 0 || commentlength > 1) && !clickedcomment
            ? "sticky"
            : "fixed",
      }}
      className="Panel"
    >
      <img
        style={{ width: 30 }}
        src="/Home2.svg"
        onClick={(e) => navigate(`/Home`, { state: currenturl })}
      />
      <span className="Notifyline">
        <img
          style={{ width: 30 }}
          src="/Notify.svg"
          onClick={(e) => navigate(`/Notification`, { state: currenturl })}
        />

        <span className="notificationnumber">
          {notification ? notification : null}
        </span>
      </span>
      <span className="Messageline">
        <img
          style={{ width: 20 }}
          src="/MessageIcon2.svg"
          onClick={(e) => navigate(`/MessageView`, { state: currenturl })}
        />

        <span className="notificationnumber" style={{ marginLeft: 3 }}>
          {messagenotification ? messagenotification : null}
        </span>
      </span>
    </div>
  );
}

export default LowControl;
