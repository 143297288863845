import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import "./EStation.css";

function EStation() {
  return <div>YOOO</div>;
}

export default EStation;
