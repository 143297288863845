import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import {
  getDocs,
  collection,
  getDoc,
  doc,
  deleteDoc,
  query,
  collectionGroup,
  where,
  writeBatch,
  updateDoc,
  increment,
  addDoc,
  serverTimestamp,
  deleteField,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
import "./Notification.css";
import Block from "./Customhooks/Block.js";
import useClickCounter from "./useClickCounter.js";
import Progressbar from "./Customhooks/Progressbar.js";

function Notification({ ImageUrl }) {
  const [id, setId] = useState("");
  const [back, setBack] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [commentid, setCommentId] = useState("");
  const [askingdeletecomment, setAskingdeletecomment] = useState(false);
  const [delete1, setDelete1] = useState(false);
  const [delete2, setDelete2] = useState(false);
  const [redroppoint, setRedroppoint] = useState("");
  const [unredroppoint, setUnredroppoint] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [unredropid1, setUnredropid1] = useState("");
  const [unredropdid, setUnredropdid] = useState([]);
  const [redropid, setRedropid] = useState([]);
  const [alldisplay, setAlldisplay] = useState([]);
  const [clickedcomment, setClickedcomment] = useState("");
  const [send, setSend] = useState("");
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [unredropid, setUnredropid] = useState([]);
  //const [notificationon, setNotificationon] = useState(true);
  const [simdisplays, setSimdisplays] = useState([]);
  const [commentload, setCommentload] = useState("");
  const [tempfiles, setTempfiles] = useState([]);
  const [disablepress, setDisablepress] = useState(false);
  const [blockid, setBlockid] = useState("");
  const [optionid, setOptionId] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);
  const [twominutevideo, setTwominutevideo] = useState(0);
  const [videorefload, setVideorefload] = useState(false);
  const [fullscreened, setFullscreened] = useState("");
  const [commentbackid, setCommentbackid] = useState("");
  const [notificationtype, setNotificationtype] = useState("");
  const [commentloadrefindex, setCommentloadrefindex] = useState(0);
  const [controlon, setControlon] = useState(false);
  const [paused, setPaused] = useState("");
  const [videolocation, setVideolocation] = useState(0);
  const [videotime, setVideotime] = useState(0);
  const [showtime, setShowtime] = useState(false);
  const [followers, setFollowers] = useState("");

  const navigate = useNavigate();
  const ref = useRef(null);
  const droppedvideoref = useRef([]);
  const scrollref = useRef(null);
  const ref3 = useRef([]);
  const Clicker = useClickCounter();

  useEffect(
    () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setId(user.uid);
          const userCollectionRef = collection(
            db,
            "users",
            user.uid,
            "Notification"
          );
          await getDocs(
            query(
              userCollectionRef,

              orderBy("Timestamp", "desc")
            )
          ).then((querySnapshot) => {
            let posts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            //if the above is pointing to the same redrop_id then combine the pic together,
            //foreach

            let num = 0;
            console.log(posts[-1]);
            let notificationfusion;
            let originalposts = [...posts];

            let deletetarget;
            console.log(posts);
            originalposts.forEach((post) => {
              //////////////////////////test
              //nested comment solo
              //nested redrop multiple
              //make all clickable display ( Follow, Follow nested, drop comment, Comment comment,nested comment,solo redrop drops,solo comment redrop,solo nested redrop,
              //multiple drop redrop, multiple comment redrop,multiple nested redrop )
              //does the link to the user page work for redropped comment and nested comments as well?
              //arrow positioned right
              //what if the comment notification is aimed toward someone in your comment in your drop
              //you as a dropper should get notification as well
              //how that notfication is displayed
              //and how to click display this
              //and how to nest it multiple

              //confirmunredrop slash slash
              //go back to the notes

              if (
                post.Type === "Redrop" &&
                posts[num - 1]?.Type === "Redrop" &&
                ((post.Loadtype === "Drops" &&
                  post.rootid === posts[num - 1]?.rootid) ||
                  (post.Loadtype === "Comments" &&
                    post.Comment_Directory ===
                      posts[num - 1]?.Comment_Directory) ||
                  (post.Loadtype === "NestedComments" &&
                    post.Nested_Directory === posts[num - 1]?.Nested_Directory))
              ) {
                /*let choo = "good"
           console.log(choo.includes("good"))*/
                if (
                  !posts[num - 1].Redropper_id.includes(post.Redropper_id)
                  //remember the first time it will not be an array so .includes is
                  //going to be tricky
                  /*posts[num - 1].Redropper_id !== post.Redropper_id &&
                posts[num - 1].Redropper_id.at(-1) !== post.Redropper_id*/
                ) {
                  notificationfusion = Array.isArray(posts[num - 1].Name)
                    ? [
                        [...posts[num - 1].Profile_image, post.Profile_image],
                        [...posts[num - 1].Rank, post.Rank],
                        [...posts[num - 1].Name, post.Name],
                        [...posts[num - 1].Redropper_id, post.Redropper_id],
                      ]
                    : [
                        [posts[num - 1].Profile_image, post.Profile_image],
                        [posts[num - 1].Rank, post.Rank],
                        [posts[num - 1].Name, post.Name],
                        [posts[num - 1].Redropper_id, post.Redropper_id],
                      ];

                  posts[num - 1] = {
                    ...posts[num - 1],
                    Profile_image: notificationfusion[0],
                    Rank: notificationfusion[1],
                    Name: notificationfusion[2],
                    Redropper_id: notificationfusion[3],
                  };
                  console.log(Array.isArray(posts[num - 1]));
                  //false,true
                  console.log(posts);
                } /*if (
                posts[num - 1].Redropper_id === post.Redropper_id ||
                posts[num - 1].Redropper_id.at(-1) === post.Redropper_id
              )*/ else {
                  deletetarget = posts[num].id;

                  console.log(user.uid);
                  const userDocumentRef = doc(
                    db,
                    "users",
                    user.uid,
                    "Notification",
                    deletetarget
                  );
                  deleteDoc(userDocumentRef);
                  console.log("RUN THIS ONCE AND NO MORE EVEEERRRR");
                }

                posts.splice(num, 1);

                console.log(posts);
              } else if (
                post.Type === "Follow" &&
                posts[num - 1]?.Type === "Follow"
              ) {
                if (!posts[num - 1].Directory.includes(post.Directory)) {
                  notificationfusion = Array.isArray(posts[num - 1].Name)
                    ? [
                        [...posts[num - 1].Profile_image, post.Profile_image],
                        [...posts[num - 1].Rank, post.Rank],
                        [...posts[num - 1].Name, post.Name],
                        [...posts[num - 1].Directory, post.Directory],
                      ]
                    : [
                        [posts[num - 1].Profile_image, post.Profile_image],
                        [posts[num - 1].Rank, post.Rank],
                        [posts[num - 1].Name, post.Name],
                        [posts[num - 1].Directory, post.Directory],
                      ];

                  posts[num - 1] = {
                    ...posts[num - 1],
                    Profile_image: notificationfusion[0],
                    Rank: notificationfusion[1],
                    Name: notificationfusion[2],
                    Directory: notificationfusion[3],
                  };
                  console.log(Array.isArray(posts[num - 1]));
                  //false,true
                  console.log(posts);
                } /*if (
                posts[num - 1].Redropper_id === post.Redropper_id ||
                posts[num - 1].Redropper_id.at(-1) === post.Redropper_id
              )*/ else {
                  deletetarget = posts[num].id;

                  console.log(user.uid);
                  const userDocumentRef = doc(
                    db,
                    "users",
                    user.uid,
                    "Notification",
                    deletetarget
                  );
                  deleteDoc(userDocumentRef);
                  console.log("RUN THIS ONCE AND NO MORE EVEEERRRR");
                }
                posts.splice(num, 1);
                console.log(posts);
              } else num++;
            });

            console.log(posts);

            setTimeout(() => {
              setNotifications(posts);
            });
          });
          //actually chagne these to arrays in the backend and aslo delete the notification in the backend so you dont have to keep

          const userDocumentRef = doc(db, "users", user.uid);
          await getDoc(userDocumentRef).then((documentSnapshot) => {
            setUserinfo(documentSnapshot.data());
          });

          updateDoc(doc(db, `users/${user.uid}`), {
            Notification: 0,
          });
        }
      });

      /*if (back === true) {
      setTimeout(() => {
        setBack1(true);
        setBack2(false);
      });
    }*/
    },
    [
      /*back*/
    ]
  );

  /*
  useEffect(() => {
    if (back1 === true) {
      const element = ref.current;
      element.scrollIntoView();
      setTimeout(() => {
        setAlldisplay([]);
        setSimdisplays([]);
      });
      setBack(false);
      setBack1(false);
      setRealtimecomment([]);
    }
    if (back2 === true) {
      setTimeout(() => {
        console.log(alldisplay.length);
        if (alldisplay.length !== 2) {
          const element = ref1.current;
          console.log(element);
          element.scrollIntoView();
        }
        setAlldisplay(alldisplay.slice(0, -1));
        console.log(alldisplay);
        setTimeout(() => {
          setBack2(false);
        }, 40);
      }, 160);
    }
    if (back3 === true) {
      if (alldisplay.length !== 1) {
        const element = ref1.current;
        console.log(element);
        element.scrollIntoView();
      }
      setTimeout(() => {
        setRealtimecomment([]);
        setBack3(false);
      });
    }
  }, [back1, back2, back3]);
*/

  useEffect(() => {
    if (back === true) {
      const element = scrollref.current;
      console.log(element);
      element?.scrollIntoView();
      if (clickedcomment) setClickedcomment("");
      else if (commentbackid) setCommentbackid("");
      else if (alldisplay.length === 1) setAlldisplay([]);
      else setAlldisplay(alldisplay.slice(0, -1));
      setBack(false);
    }
  }, [back]);

  const Optionreset = () => {
    setOptionId("");
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);
    setBlockid("");
  };

  const displaycomment = async (postdata) => {
    /*let tempid = e.target.id.split(",");
    console.log(tempid);
    */
    console.log(postdata);
    let posts;
    const postsRef = collection(db, "users", id, "Drops");
    await getDocs(postsRef).then((querySnapshot) => {
      posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    });
    let Redroppedlist = posts.filter((post) => post.Redropper_id === id);

    let datamatch;
    if (postdata.Loadtype === "Drops") {
      datamatch = postdata.Directory;
      //setCommentloadrefindex(0)
      droppedvideoref.current = new Array(2).fill(null);
    } else datamatch = postdata.rootid;

    /*let datamatch =
      postdata.Loadtype === "Drops" ? postdata.Directory : postdata.rootid;
    console.log(datamatch);*/
    //const inthere = (element) => element === redropmatch;
    const inthere = (element) => element?.Redrop_id === datamatch;
    // let e = cslists.some(inthere) && "done";
    let doneornot = Redroppedlist.some(inthere) && "done";
    //let doneornot = postdata.some(inthere) && "done";

    //this is getting the alldisplay post/drops
    let tempaddingdoc;
    if (!postdata.root_user || postdata?.root_user === id)
      tempaddingdoc = posts.find((element) => datamatch === element.id);
    else {
      const userCollectionRef = doc(
        db,
        "users",
        //tempid[7] === id || tempid[7] === "" ? id : tempid[7],
        //postdata.rootuser === "" ? id : postdata.rootuser,
        // !postdata.rootuser ? id : postdata.rootuser,
        postdata.root_user,
        "Drops",
        datamatch
      );
      /////

      await getDoc(userCollectionRef).then((querySnapshot) => {
        console.log(querySnapshot.data(), "DATAYO");
        console.log(querySnapshot.id);
        //let insertaddingdoc = querySnapshot.data();
        //if statement here
        if (querySnapshot.exists())
          tempaddingdoc = { ...querySnapshot.data(), id: datamatch };
      });
    }
    //if statement here
    console.log(tempaddingdoc);

    const converttimestamp = (date) => {
      return {
        year: date.getFullYear(),
        month: ("0" + (date.getMonth() + 1)).slice(-2),
        date: ("0" + date.getDate()).slice(-2),
        hour: date.getHours(),
        minutes: ("0" + date.getMinutes()).slice(-2),
      };
    };
    if (tempaddingdoc) {
      let redroptimestampedit = {};
      if (!tempaddingdoc?.Redropper_id) {
        let date = tempaddingdoc?.Timestamp.toDate();
        redroptimestampedit = converttimestamp(date);

        /*redroptimestampedit = {
              year: date.getFullYear(),
              month: (
                "0" +
                (date.getMonth() + 1)
              ).slice(-2),
              date: ("0" + date.getDate()).slice(
                -2
              ),
              hour: date.getHours(),
              minutes: (
                "0" + date.getMinutes()
              ).slice(-2),
              
            }*/
      }

      setAlldisplay([
        ...alldisplay,
        {
          ...tempaddingdoc,
          // id: alldisplay.length + 1,
          // id: tempaddingdoc.id,
          //is the id correct like this querysnapshot.data().id?
          //id: datamatch, this is technecially correct as well
          ...redroptimestampedit,
          Redropped: doneornot,
          notificationid: postdata.id,
        },
      ]);
    } else
      setAlldisplay([
        ...alldisplay,
        {
          Deleted: true,
        },
      ]);

    //let commentload1 = {}

    if (postdata.Loadtype === "Comments") {
      console.log(postdata);
      const userCollectionRef0 = doc(
        db,
        "users",
        postdata.Sent_id,
        "Comments",
        postdata.Directory
      );

      getDoc(userCollectionRef0).then((querySnapshot) => {
        if (querySnapshot.exists()) {
          console.log(querySnapshot.data());
          console.log(querySnapshot.id);
          //let insertaddingdoc1 = querySnapshot.data();
          let tempaddingdoc1 = {
            ...querySnapshot.data(),
            id: postdata.Directory,
          };
          // const inthere0 = (element) => element === postdata.Directory;
          const inthere0 = (element) =>
            element.Redrop_id === postdata.Directory;
          //  const inthere0 = (element) => element.Redrop_id === a0.id;
          let doneornot1 = Redroppedlist.some(inthere0) && "done";

          let redroptimestampedit1 = {};
          if (!tempaddingdoc1.Redropper_id) {
            let date = tempaddingdoc1.Timestamp.toDate();

            redroptimestampedit1 = converttimestamp(date);
            /* let b0  redroptimestampedit1 = {
            year: date.getFullYear(),
            month: ("0" + (date.getMonth() + 1)).slice(-2),
            date: ("0" + date.getDate()).slice(-2),
            hour: date.getHours(),
            minutes: ("0" + date.getMinutes()).slice(-2),
            //Redropped: d0,
            //id: postdata.Directory,
           // id:a0.id,
          };
          */
          }
          //let c0 = { ...a0, ...b0 };

          setTimeout(() => {
            setCommentload({
              ...redroptimestampedit1,
              ...tempaddingdoc1,
              Redropped: doneornot1,
            });
          });
          /*commentload1 = {
            ...redroptimestampedit1,
              ...tempaddingdoc1,
              Redropped: doneornot1,
            }*/
        } else {
          setTimeout(() => {
            setCommentload({
              Deleted: true,
            });
          });
          //commentload1={Deleted:true}
        }
      });
      setCommentloadrefindex(1);
      setTimeout(() => {
        droppedvideoref.current = new Array(3).fill(null);
        console.log(droppedvideoref.current);
      });
    }

    //////////////////////////
    const userCollectionRef1 = doc(
      db,
      "users",
      postdata.Sender_id,
      "Comments",
      postdata.Comment_Directory
    );
    ////////////////////
    await getDoc(userCollectionRef1).then((querySnapshot) => {
      console.log(querySnapshot.data());
      if (querySnapshot.exists()) {
        //console.log(postdata.Comment_Directory)
        //console.log(querySnapshot?.id)
        let tempaddingdoc2 = {
          ...querySnapshot.data(),
          id: postdata.Comment_Directory /*insertaddingdoc2.id*/,
        };
        const inthere1 = (element) => element === postdata.Comment_Directory;
        let doneornot2 = Redroppedlist.some(inthere1) && "done";

        let redroptimestampedit2 = {};
        if (!tempaddingdoc2.Redropper_id) {
          let date = tempaddingdoc2.Timestamp.toDate();

          redroptimestampedit2 = converttimestamp(date);
        }
        /*let b = {
          year: a.Timestamp.toDate().getFullYear(),
          month: ("0" + (a.Timestamp.toDate().getMonth() + 1)).slice(-2),
          date: ("0" + a.Timestamp.toDate().getDate()).slice(-2),
          hour: a.Timestamp.toDate().getHours(),
          minutes: ("0" + a.Timestamp.toDate().getMinutes()).slice(-2),
          Redropped: d,
          //id: tempid[4],
          
        };*/

        setTimeout(() => {
          setSimdisplays([
            [
              {
                ...redroptimestampedit2,
                ...tempaddingdoc2,
                Redropped: doneornot2,
              },
            ],
          ]);
        });
      } else {
        setTimeout(() => {
          setSimdisplays([
            [
              {
                Deleted: true,
              },
            ],
          ]);
        });
      }
    });

    setTimeout(() => {
      //setNotificationon(false);
      setNotificationtype("Comment");
    });

    //maybe change backgrond color of deleted style

    //find out a little bit more about 304 not modified
    //find out how and check if images are being rendered from cache (even in the connect)
    //use the firebase extension to resize image size and make sure it all loads in the connect without losing quality
    //resize images of connect droppy, and sendcomment , user profile image to the right size ( aspect ratio )

    //begin lazy loading all drops, user posts, userviewposts, Rank display all comments to save bandwidth

    //maybe change display backgrond css to not be coverd by images so when zooming in, it wont
    //trigger the display donut hole design

    //form in sign up page is overlapping with request otp and sendotp, pretty scary stuff

    //read up on react dom

    //is displayback fine with redrop regarding commentloadref? test with videos

    //本人確認ができましたModal を作るfor登録ページ　instead of alert()

    //clear all of sendcomment function from top to bottom one by one

    //implemennt video ref for commentload
    //how does videoref get affected when commentload is there alldisplay1

    //3 beast notification is empty could it be videos??

    //design sign up page better

    //qr code block and 察知
    //what action?
    //payment talk 察知
    //what action
    //social media 察知
    //update terms of service auto pay to site no direct payments allowed without through the site

    //dont allow upload of contents until pledge plan set
    //when coming back from pledge subscribe pledge photos are gone
    //atleast on mobile when clicking on photo the pledge plan doesnt shine
    //should you make back up database if in case all users and shiz gets deleted only periodically update, though since it all cost too much to update everyday once a month?

    //for the people following, 有料の写真を載せました通知、with モザイク写真 attached to see
    //click to easily go to the pledge page
    //有料のプランを追加しました！
    //how to know who paid for what

    //dont allow messages and message_details to be deleted by NO ONE
    //set up the rules in in firestore rules

    //how did that hacker know that i was using firebase

    //schedule and set up firestore backup

    //fix the block ui in userview page

    //accept alipay pyment

    //for the connect and maybe other query limiter instead of limiting by number of posts to query limit from the date and then limtit the amount so youll querry 10 at a time?

    //observe for videos to play whn scrolled to it
    //querry limit messages as well

    /////////////////////////////////////////////////////////////////////////////////////

    //arraystyle1 display
    //display all arraystyle1 with arrayobject
    //be able to onlcik anywhere to go to their respective userview page with displayfollow
    //displayfollow
    //all other display
    //follow display
    //Pledge plan notification
    //how to display videos and images in the initial notification
    //how to show images and videos in notification initial page
    //change color of notification top bar
    //fix all the functions from notification from top to bottom
    //message page background color changed in the message view and message
    //Low control should still be here in notification component to switch to messages and etc
    //condition on the new user follower design if you dont have a follower
    //にゅんdesign shouldhave design around the border like the chinese con cafe design
    //delete should be fixed?? delete for commentload
    // get rid of and replace commentid
    //in the connect after displaying if its a video its not sticking to the bottom position sticky not working
    //if anyone joined pledge then finish up pledge notification
    //when we come into notification do we need ovserver for notificatin videos?
    // when backed to notification do we need reactivate nulll ref?
    //back from redrop
    //back from follow,
    //we need an arrow on notification page to go back to user page
    //push the camera down if message starts extending
    //what if theres video or images but with texts, how to display it as notification
    //ban on QR code and links to sites dont allow to send messages or drops, comments and give warnings
    //after clicking back out from pledge subscribe to pledge page photos are gone (not albe to be loaded)
    //if you ranked down in the user page show notification but reset the previous rank in the database to the current rank
    //redirect to homepage if logged out because of access token
    //still when sending comment the notification is being sent to yourselves りさん case
    //messageview page is offsetted lil bit to the right should be oveflow x clip?
    //load only few outside messages, connect posts, Rank, user page posts, userview posts, inside messages comments ( forgot what it was called not lazy load but ) by timestamp
    //turn the にゅんad clickable to jump straight to the dm ( make all the ads clickable exccept for the animation of the eplise girl)
    //everyone girls ( that are on the list ) pledge plan 広告 run it in connect page and CLICKABLE
    //make sure we have a spinner to show how long the adds are,
    //make the ads run a lil longer
    //design pledge plan ads
    //go back to confirmunredrop in connect
  };

  const Videotimeupdate = (e) => {};

  const Droppedvideotimeupdate = (e, index) => {};

  const determinevideoindex = (e, index) => {};

  const determinefourthvideoindex = (e, index) => {};

  const determinevideoref = (e, index) => {};

  const imagehtml = (post, post1, post2) => {
    const images = {
      width: 0,
      height: 0,
      marginLeft: 0,
      marginTop: 0,
    };
    //if (post1.id === ){}

    images.width = post2?.path
      ? 130
      : post2?.path !== "" && post2?.path !== undefined
      ? 110
      : ((post1?.path !== "" && post1?.path !== undefined) ||
          post?.path !== "") &&
        150;
    images.height =
      post2?.path !== "" && post2?.path !== undefined
        ? 95
        : post1?.path !== "" && post2?.path === ""
        ? 195
        : post?.path !== "" && 150;

    images.marginLeft =
      post1?.path !== "" && post2?.path === "" ? 20 : post?.path !== "" && 5;
    images.marginTop = post?.path !== "" && 20;

    return images;
  };

  const videohtml = (post, post1, post2) => {
    /*
      controls: tempfiles.length < 2 && true,
      width: fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175,
      height: fullscreened ? "40%" : tempfiles.length === 1 ? "200" : 175,
      playsInline,
      autoPlay,
      muted,
      loop,
      */

    const videos = {
      objectFit: "cover",
      borderRadius: 20,
      marginLeft:
        !fullscreened && post1?.path !== "" && post2?.path === ""
          ? 20
          : post?.path && !fullscreened && 5,
      marginTop: post?.path !== "" && 20,
      position: fullscreened && "absolute",
      top: fullscreened && "30%",
    };
    return videos;
  };

  const display = async (post, e) => {
    console.log(e?.target.id);
    console.log(post);
    let Directory = e /*&& e.target.id === alldisplay[0].id*/
      ? post.Directory
      : post.Sender_id;
    //let Directory = alldisplay.length === 1 ? post.Directory : post.Sender_id;
    console.log(Directory);
    const userBlockRef = doc(db, "users", Directory, "Blocked", id);

    let blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
      console.log(documentSnapshot.exists());
      console.log(documentSnapshot.data());
      return documentSnapshot;
    });

    console.log(blocked);
    console.log(blocked.exists());
    console.log(blocked.data());
    if (blocked.exists()) return setBlockid(post.id);

    setAlldisplay([...alldisplay, post]);

    //setShowdrop(false);

    const dropsRef = query(
      collectionGroup(db, "Comments"),
      where("Directory", "==", post.id)
    );

    getDocs(
      query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
    ).then((querySnapshot) => {
      let simposts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(simposts);
      const postsRef = collection(db, "users", id, "Drops");
      getDocs(postsRef).then((querySnapshot) => {
        let posts = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let Redroppedcomments = posts.filter(
          (post) => post.Sender_id && post.Redropper_id === id
        );

        console.log(Redroppedcomments);

        simposts.map((simpost) => {
          if (!simpost.Redropper_id) {
            simpost.year = simpost.Timestamp.toDate().getFullYear();
            simpost.month = (
              "0" +
              (simpost.Timestamp.toDate().getMonth() + 1)
            ).slice(-2);
            simpost.date = ("0" + simpost.Timestamp.toDate().getDate()).slice(
              -2
            );
            simpost.hour = simpost.Timestamp.toDate().getHours();
            simpost.minutes = (
              "0" + simpost.Timestamp.toDate().getMinutes()
            ).slice(-2);
          }
          Redroppedcomments.forEach((redroppedcomment) => {
            if (redroppedcomment.Redrop_id === simpost.id) {
              simpost.Redropped = "done";
            }
          });

          return simpost;
        });
        console.log(simposts);

        setSimdisplays([...simdisplays, simposts]);
        setCommentloadrefindex(0);
        setTimeout(() => {
          droppedvideoref.current = new Array(simposts.length + 1).fill(null);
          console.log(droppedvideoref.current);
        });
      });
    });

    Optionreset();
    window.scroll({ top: 0 });
    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  };

  const displayfollow = (notification) => {
    if (!Array.isArray(notification.Directory))
      navigate(`/UserView/${notification.Directory}`);
    else {
      console.log(notification);
      // setFollowers([{...notification,Profile_image:[notification.Profile_image[1],notification.Profile_image[0]]}]);
      setFollowers(notification);
      setNotificationtype("Follow");
    }

    //if notification is multiple then we need to display them accordingly
    //style that display
    // make sure the back button works as well
  };

  const deletedrop = async (e) => {};

  const displayredrop = async (postdata) => {
    console.log(postdata);
    //setNotificationtype("Redrop");

    let posts;
    const postsRef = collection(db, "users", id, "Drops");
    await getDocs(postsRef).then((querySnapshot) => {
      posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    });
    let Redroppedlist = posts.filter((post) => post.Redropper_id === id);

    let datamatch;
    if (postdata.Loadtype === "Drops")
      //{
      //datamatch = postdata.rootid //postdata.originalpost.id //postdata.Directory;
      droppedvideoref.current = new Array(1).fill(null);
    // } //else datamatch = postdata.rootid; //postdata.Comment_Directory
    //else if (postdata.Loadtype === "Comments") datamatch = postdata.Comment_Directory
    datamatch = postdata.rootid;
    const inthere = (element) => element?.Redrop_id === datamatch;
    let doneornot = Redroppedlist.some(inthere) && "done";

    let tempaddingdoc;
    if (!postdata.root_user || postdata?.root_user === id)
      tempaddingdoc = posts.find((element) => datamatch === element.id);
    else {
      const userCollectionRef = doc(
        db,
        "users",
        postdata.root_user,
        "Drops",
        datamatch
      );

      //const inthere = (element) => element === tempid[5];
      //let e = cslists.some(inthere) && "done";

      await getDoc(userCollectionRef).then((querySnapshot) => {
        console.log(querySnapshot.data(), "DATAYO");
        console.log(querySnapshot.id);
        //let insertaddingdoc = querySnapshot.data();
        //if statement here
        if (querySnapshot.exists())
          tempaddingdoc = { ...querySnapshot.data(), id: datamatch };
      });
    }

    //if statement here
    console.log(tempaddingdoc);

    const converttimestamp = (date) => {
      return {
        year: date.getFullYear(),
        month: ("0" + (date.getMonth() + 1)).slice(-2),
        date: ("0" + date.getDate()).slice(-2),
        hour: date.getHours(),
        minutes: ("0" + date.getMinutes()).slice(-2),
      };
    };
    if (tempaddingdoc) {
      let redroptimestampedit = {};
      if (!tempaddingdoc?.Redropper_id) {
        let date = tempaddingdoc?.Timestamp.toDate();
        redroptimestampedit = converttimestamp(date);
      }

      console.log(tempaddingdoc, "YOOOOOOOO");

      setAlldisplay([
        ...alldisplay,
        {
          ...tempaddingdoc,
          ...redroptimestampedit,
          Redropped: doneornot,
          notificationid: postdata.id,
        },
      ]);
    } else
      setAlldisplay([
        ...alldisplay,
        {
          Deleted: true,
        },
      ]);

    /////////////////////////////////////////////////////////////
    let Directory;
    let Comment_Directory;

    if (postdata.Loadtype === "NestedComments") {
      Directory = postdata.Nested_user;
      Comment_Directory = postdata.Nested_Directory;

      const userCollectionRef0 = doc(
        db,
        "users",
        postdata.Directory,
        "Comments",
        postdata.Comment_Directory
      );

      getDoc(userCollectionRef0).then((querySnapshot) => {
        if (querySnapshot.exists()) {
          console.log(querySnapshot.data());
          console.log(querySnapshot.id);
          //let insertaddingdoc1 = querySnapshot.data();
          let tempaddingdoc1 = {
            ...querySnapshot.data(),
            //id: postdata.Directory,
            //id:uerySnapshot.id,
            id: postdata.Comment_Directory,
          };
          // const inthere0 = (element) => element === postdata.Directory;
          const inthere0 = (element) =>
            element.Redrop_id === postdata.Directory;
          //  const inthere0 = (element) => element.Redrop_id === a0.id;
          let doneornot1 = Redroppedlist.some(inthere0) && "done";

          let redroptimestampedit1 = {};
          if (!tempaddingdoc1.Redropper_id) {
            let date = tempaddingdoc1.Timestamp.toDate();

            redroptimestampedit1 = converttimestamp(date);
            /* let b0  redroptimestampedit1 = {
            year: date.getFullYear(),
            month: ("0" + (date.getMonth() + 1)).slice(-2),
            date: ("0" + date.getDate()).slice(-2),
            hour: date.getHours(),
            minutes: ("0" + date.getMinutes()).slice(-2),
            //Redropped: d0,
            //id: postdata.Directory,
           // id:a0.id,
          };
          */
          }
          //let c0 = { ...a0, ...b0 };

          setTimeout(() => {
            setCommentload({
              ...redroptimestampedit1,
              ...tempaddingdoc1,
              Redropped: doneornot1,
            });
          });
          /*commentload1 = {
            ...redroptimestampedit1,
              ...tempaddingdoc1,
              Redropped: doneornot1,
            }*/
        } else {
          setTimeout(() => {
            setCommentload({
              Deleted: true,
            });
          });
          //commentload1={Deleted:true}
        }
      });
      setCommentloadrefindex(1);

      setTimeout(() => {
        droppedvideoref.current = new Array(3).fill(null);
        console.log(droppedvideoref.current);
      });
    }

    if (
      postdata.Loadtype === "Comments" ||
      postdata.Loadtype === "NestedComments"
    ) {
      if (postdata.Loadtype === "Comments") {
        Directory = postdata.Directory;
        Comment_Directory = postdata.Comment_Directory;
        setTimeout(() => {
          droppedvideoref.current = new Array(2).fill(null);
        });
      }
      const userCollectionRef1 = doc(
        db,
        "users",
        Directory, // postdata.Directory,
        "Comments",
        Comment_Directory // postdata.Comment_Directory
      );

      ////////////////////
      await getDoc(userCollectionRef1).then((querySnapshot) => {
        console.log(querySnapshot.data());
        if (querySnapshot.exists()) {
          //console.log(postdata.Comment_Directory)
          //console.log(querySnapshot?.id)
          let tempaddingdoc2 = {
            ...querySnapshot.data(),
            //id: postdata.Comment_Directory /*insertaddingdoc2.id*/,
            id: Comment_Directory,
          };
          const inthere1 = (element) => element === postdata.Comment_Directory;
          let doneornot2 = Redroppedlist.some(inthere1) && "done";

          let redroptimestampedit2 = {};
          if (!tempaddingdoc2.Redropper_id) {
            let date = tempaddingdoc2.Timestamp.toDate();

            redroptimestampedit2 = converttimestamp(date);
          }
          /*let b = {
          year: a.Timestamp.toDate().getFullYear(),
          month: ("0" + (a.Timestamp.toDate().getMonth() + 1)).slice(-2),
          date: ("0" + a.Timestamp.toDate().getDate()).slice(-2),
          hour: a.Timestamp.toDate().getHours(),
          minutes: ("0" + a.Timestamp.toDate().getMinutes()).slice(-2),
          Redropped: d,
          //id: tempid[4],
          
        };*/

          setTimeout(() => {
            setSimdisplays([
              [
                {
                  ...redroptimestampedit2,
                  ...tempaddingdoc2,
                  Redropped: doneornot2,
                },
              ],
            ]);
          });
        } else {
          setTimeout(() => {
            setSimdisplays([
              [
                {
                  Deleted: true,
                },
              ],
            ]);
          });
        }
      });
    }

    /*if (postdata.Loadtype === "Comments") setTimeout(() => {
        droppedvideoref.current = new Array(2).fill(null);
      });*/

    /* const userCollectionRef0 = doc(
          db,
          "users",
          tempid[2],

          "Comments",
          tempid[4]
        );

        getDoc(userCollectionRef0).then((querySnapshot) => {
          let a0 = querySnapshot.data();
          console.log(querySnapshot.data());

          const inthere0 = (element) => element === tempid[4];
          let d0 = cslists.some(inthere0) && "done";

          let b0 = {
            year: a0.Timestamp.toDate().getFullYear(),
            month: ("0" + (a0.Timestamp.toDate().getMonth() + 1)).slice(-2),
            date: ("0" + a0.Timestamp.toDate().getDate()).slice(-2),
            hour: a0.Timestamp.toDate().getHours(),
            minutes: ("0" + a0.Timestamp.toDate().getMinutes()).slice(-2),
            Redropped: d0,
            id: tempid[4],
          };
          let c0 = { ...a0, ...b0 };

          setTimeout(() => {
            setCommentload(c0);
            //this should not be an array since we are not mapping it
          });
        });
      }
        




      
      if (tempid[3] !== "Drops") {
        console.log("YET");
        let g = tempid[3] === "Comments" && tempid[2];
        let i = tempid[3] === "NestedComments" && tempid[9];
        let f = tempid[3] === "Comments" && tempid[4];
        let h = tempid[3] === "NestedComments" && tempid[8];

        console.log(g);
        const userCollectionRef1 = doc(
          db,
          "users",
          g ? g : i,
          "Comments",
          f ? f : h
        );
        getDoc(userCollectionRef1).then((querySnapshot) => {
          let a = querySnapshot.data();
          console.log(querySnapshot.data());

          const inthere1 = (element) =>
            element === (tempid[3] === "Comments" && tempid[4]) ||
            element === (tempid[3] === "NestedComments" && tempid[8]);
          let d = cslists.some(inthere1) && "done";

          let b = {
            year: a.Timestamp.toDate().getFullYear(),
            month: ("0" + (a.Timestamp.toDate().getMonth() + 1)).slice(-2),
            date: ("0" + a.Timestamp.toDate().getDate()).slice(-2),
            hour: a.Timestamp.toDate().getHours(),
            minutes: ("0" + a.Timestamp.toDate().getMinutes()).slice(-2),
            Redropped: d,
            id: f ? f : h,
          };
          console.log(b.id);
          let c = { ...a, ...b };

          setTimeout(() => {
            setSimdisplays([[c]]);
          });
        });
      }
    */

    setTimeout(() => {
      //setNotificationon(false);
      setNotificationtype("Redrop");
    }, 300);
  };

  const OptionTerminal = (e) => {
    setCommentId(e.target.id.split(","));
    setAskingdeletecomment(false);
    console.log(commentid);
    delete1 === true && setDelete1(false);
    setDelete2(false);
    setRedroppoint(false);
    setUnredroppoint(false);
  };

  const askdeletecomment = (e) => {
    setAskingdeletecomment(true);
    setDeleteid(e.target.id);
  };

  const deletecomment = async () => {
    const userDocumentRef = doc(
      db,
      "users",
      commentid[1],
      "Comments",
      commentid[0]
    );
    await deleteDoc(userDocumentRef);

    updateDoc(
      doc(
        db,
        `users/${
          alldisplay[alldisplay.length - 1].Sender_id
            ? alldisplay[alldisplay.length - 1].Sender_id
            : alldisplay[alldisplay.length - 1].Directory
        }/${
          alldisplay[0].displayid1 ===
          alldisplay[alldisplay.length - 1].displayid
            ? "Drops"
            : "Comments"
        }/${
          alldisplay.length === 1
            ? alldisplay[alldisplay.length - 1].displayid1
            : alldisplay[alldisplay.length - 1].displayid
        }`
      ),
      {
        Comment_Counter: increment(-1),
      }
    );

    alldisplay.map((obj) => {
      if (obj.id === alldisplay.length) {
        setAlldisplay((prevvalue) => [
          ...prevvalue.slice(0, -1),
          {
            ...obj,
            Comment_Counter: obj.Comment_Counter - 1,
          },
        ]);
      }
    });

    if (commentid[0] === commentload.id) {
      setCommentload("");
    } else {
      let commentdelete = simdisplays[simdisplays.length - 1].findIndex(
        (element) => commentid[0] === element.id
      );
      console.log(commentdelete);
      console.log(simdisplays);
      console.log(simdisplays.slice(-1)[0].slice(1));

      if (commentdelete === 0) {
        setSimdisplays([
          ...simdisplays.slice(0, -1),

          [...simdisplays.slice(-1)[0].slice(1)],
        ]);
      } else if (commentdelete > 0) {
        setSimdisplays([
          ...simdisplays.slice(0, -1),

          [
            ...simdisplays.slice(-1)[0].slice(0, commentdelete),
            ...simdisplays.slice(-1)[0].slice(commentdelete + 1),
          ],
        ]);
      }
    }
    if (clickedcomment) {
      setClickedcomment("");
    }
    if (
      alldisplay.length > 1 &&
      alldisplay[alldisplay.length - 1].displayid === commentload.id
    ) {
      commentload.Comment_Counter = commentload.Comment_Counter - 1;
    } else if (
      alldisplay.length === 2 &&
      alldisplay[0].displayid1 === alldisplay[alldisplay.length - 1].displayid
    ) {
      alldisplay[0].Comment_Counter = alldisplay[0].Comment_Counter - 1;
    } else if (
      alldisplay.length === 2 &&
      alldisplay[0].displayid1 !== alldisplay[alldisplay.length - 1].displayid
    ) {
      simdisplays[simdisplays.length - 2][0].Comment_Counter =
        simdisplays[simdisplays.length - 2][0].Comment_Counter - 1;
    } else if (alldisplay.length > 2) {
      let commentsubtract = simdisplays[simdisplays.length - 2].filter(
        (simdisplay) =>
          simdisplay.id === alldisplay[alldisplay.length - 1].displayid
      );
      console.log(commentsubtract);
      commentsubtract[0].Comment_Counter =
        commentsubtract[0].Comment_Counter - 1;
    }

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", commentid[0])
    );
    const querySnapshot1 = getDocs(dropsRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts.forEach((item) => {
        let beems = item.id;
        let beemsuser = item.Redropper_id;
        const userDocumentRef = doc(db, "users", beemsuser, "Drops", beems);
        batchArray[batchIndex].delete(userDocumentRef);
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    let b =
      alldisplay.length === 1
        ? alldisplay[alldisplay.length - 1].displayid1
        : alldisplay[alldisplay.length - 1].displayid;

    const dropsRef2 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", b)
    );

    const querySnapshot2 = getDocs(dropsRef2).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let new_posts1 = posts2.filter((post) => post.id !== b);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts1.forEach((item) => {
        let beems = item.id;
        let beemsuser = item.Redropper_id;
        const userDocumentRef = doc(db, "users", beemsuser, "Drops", beems);
        batchArray[batchIndex].update(userDocumentRef, {
          Comment_Counter: increment(-1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
  };

  const deletecomment3 = async () => {
    const userDocumentRef = doc(
      db,
      "users",
      commentid[1],
      commentid[0] === alldisplay[0].dipsplayid1 ? "Drops" : "Comments",
      commentid[0]
    );
    await deleteDoc(userDocumentRef);

    if (commentid[0] !== alldisplay[0].dipsplayid1) {
      updateDoc(
        doc(
          db,
          `users/${
            alldisplay[alldisplay.length - 2]?.Sender_id
              ? alldisplay[alldisplay.length - 2]?.Sender_id
              : alldisplay[alldisplay.length - 2]?.Directory
          }/${
            alldisplay[alldisplay.length - 2]?.Sender_id ? "Comments" : "Drops"
          }/${
            alldisplay[alldisplay.length - 2]?.Sender_id
              ? alldisplay[alldisplay.length - 2].displayid
              : alldisplay[0].displayid1
          }`
        ),
        {
          Comment_Counter: increment(-1),
        }
      );
    }

    let commentdelete = simdisplays[simdisplays.length - 2].findIndex(
      (element) => commentid[0] === element.id
    );
    console.log(commentdelete);
    console.log(simdisplays.slice(-2, -1)[0].slice(1));

    if (commentdelete === 0) {
      setSimdisplays([
        ...simdisplays.slice(0, -2),

        [...simdisplays.slice(-2, -1)[0].slice(1)],
      ]);
    } else if (commentdelete > 0) {
      setSimdisplays([
        ...simdisplays.slice(0, -2),

        [
          ...simdisplays.slice(-2, -1)[0].slice(0, commentdelete),
          ...simdisplays.slice(-2, -1)[0].slice(commentdelete + 1),
        ],
      ]);
    }

    setDelete1(false);
    setDelete2(false);
    if (clickedcomment) {
      setClickedcomment("");
    }

    setTimeout(() => {
      console.log(alldisplay.length);
      console.log(alldisplay[alldisplay.length - 2].displayid);
      if (
        alldisplay.length === 3 &&
        alldisplay[alldisplay.length - 2].displayid === alldisplay[0].displayid1
      ) {
        alldisplay[0].Comment_Counter = alldisplay[0].Comment_Counter - 1;
      } else if (
        alldisplay.length === 3 &&
        alldisplay[alldisplay.length - 2].displayid !== alldisplay[0].displayid1
      ) {
        simdisplays[0][0].Comment_Counter =
          simdisplays[0][0].Comment_Counter - 1;
      }

      setAlldisplay([
        ...alldisplay.slice(0, -2),
        {
          ...alldisplay.slice(-2, -1)[0],
          Comment_Counter: alldisplay.slice(-2, -1)[0].Comment_Counter - 1,
        },
      ]);
    });

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", commentid[0])
    );
    const querySnapshot1 = getDocs(dropsRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts.forEach((item) => {
        let beems = item.id;
        let beemsuser = item.Redropper_id;
        const userDocumentRef = doc(db, "users", beemsuser, "Drops", beems);
        batchArray[batchIndex].delete(userDocumentRef);
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    const dropsRef2 = query(
      collectionGroup(db, "Drops"),
      where(
        "Redrop_id",
        "==",
        commentid[0] === alldisplay[0].displayid1
          ? alldisplay[0].displayid1
          : alldisplay[alldisplay.length - 2].displayid
      )
    );

    const querySnapshot2 = getDocs(dropsRef2).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let new_posts1 = posts2.filter((post) =>
        (post.id !== commentid[0]) === alldisplay[0].displayid1
          ? alldisplay[0].displayid1
          : alldisplay[alldisplay.length - 2].displayid
      );

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts1.forEach((item) => {
        let beems = item.id;
        let beemsuser = item.Redropper_id;
        const userDocumentRef = doc(db, "users", beemsuser, "Drops", beems);
        batchArray[batchIndex].update(userDocumentRef, {
          Comment_Counter: increment(-1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
  };

  const commentdeletecancel = () => {
    setAskingdeletecomment(false);
    setDelete2(false);
  };

  const comment = async (event) => {
    setDisablepress(false);
    let tempid = event.target.id.split(",");
    console.log(tempid);
    const userBlockRef = doc(db, "users", tempid[1], "Blocked", id);

    let blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
      console.log(documentSnapshot.exists());
      console.log(documentSnapshot.data());
      return documentSnapshot;
    });
    if (blocked.exists()) return setBlockid(tempid[0]);
    //what if youre trying to comment to the commentload?

    if (alldisplay.at(-1).id === tempid[0])
      setClickedcomment(alldisplay.at(-1));
    else if (commentload.id === tempid[0]) setClickedcomment(commentload);
    else
      setClickedcomment(
        ...simdisplays
          .at(-1)
          .filter((simdisplay) => simdisplay.id === tempid[0])
      );

    //setRealtimecomment(tempid);
    Optionreset();
    /*setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);*/
  };

  /*const comment1 = (event) => {
    let tempid = event.target.id.split(",");
    console.log(tempid);

    const userBlockRef = doc(db, "users", tempid[1], "Blocked", id);
    getDoc(userBlockRef).then((documentSnapshot) => {
      documentSnapshot.data().Blocked_id && navigate(`/UserView/${tempid[1]}`);
    });

    if (tempid[0] === commentload.id) {
      setClickedcomment(commentload);
    } else if (alldisplay.length === 1) {
      setClickedcomment(simdisplays[[simdisplays.length - 1]]);
    } else if (alldisplay.length > 1) {
      setClickedcomment(
        simdisplays[[simdisplays.length - 1]].filter(
          (post) => post.id === tempid[0]
        )
      );
    }

    setRealtimecomment(tempid);

    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);
  };
  */

  /*const SendComment = async () => {
    await addDoc(collection(db, `users/${id}/Comments/`), {
      Directory: realtimecomment[0],
      Sender_id: id,
      Comment: send,
      Timestamp: serverTimestamp(),
      image1: image,
      image2: image,
      image3: image,
      image4: image,
      video: video,
      Rank: userinfo.Rank,
      Profile_image: userinfo.Profile_image,
      Comment_Counter: 0,
      Sent_id: realtimecomment[1],
      Redropped_Counter: 0,
      This_Directory: "",
    }).then((docRef) => {
      let lit = docRef.id;
      console.log(lit);

      updateDoc(doc(db, `users/${id}/Comments/${lit}`), {
        This_Directory: lit,
      });

      try {
        updateDoc(
          doc(
            db,
            `users/${realtimecomment[1]}/${
              realtimecomment[0] === alldisplay[0].displayid1
                ? "Drops"
                : "Comments"
            }/${realtimecomment[0]}`
          ),
          {
            Comment_Counter: increment(1),
          }
        );
      } catch (error) {
        console.log(error);
      }

      if (realtimecomment[0] === commentload.id) {
        commentload.Comment_Counter = commentload.Comment_Counter + 1;
      } else if (
        alldisplay.length === 1 &&
        realtimecomment[0] === simdisplays[0][0].id
      ) {
        console.log(simdisplays[0][0].Comment_Counter);
        simdisplays[0][0].Comment_Counter =
          simdisplays[0][0].Comment_Counter + 1;
      } else if (
        alldisplay.length === 2 &&
        realtimecomment[0] === commentload.id
      ) {
        commentload.Comment_Counter = commentload.Comment_Counter + 1;
      } else if (
        alldisplay.length > 1 &&
        realtimecomment[0] !== alldisplay[alldisplay.length - 1].displayid
      ) {
        let decidecomment = simdisplays[simdisplays.length - 1].filter(
          (simdisplay) => simdisplay.id === realtimecomment[0]
        );
        decidecomment[0].Comment_Counter = decidecomment[0].Comment_Counter + 1;
      }

      if (
        alldisplay.length === 1 ||
        realtimecomment[0] !== alldisplay[alldisplay.length - 1].displayid
      ) {
        const dropsRef = query(
          collectionGroup(db, "Comments"),
          where("Directory", "==", realtimecomment[0])
        );
        const querySnapshot = getDocs(
          query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
        ).then((querySnapshot) => {
          let simposts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          const postsRef = collection(db, "users", id, "Drops");
          const postsSnapshot = getDocs(postsRef).then((querySnapshot) => {
            let posts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            let cs = posts.filter(
              (post) => post.Sender_id && post.Redropper_id === id
            );
            console.log(cs);

            simposts.map((simpost) => {
              simpost.year = simpost.Timestamp.toDate().getFullYear();
              simpost.month = (
                "0" +
                (simpost.Timestamp.toDate().getMonth() + 1)
              ).slice(-2);
              simpost.date = ("0" + simpost.Timestamp.toDate().getDate()).slice(
                -2
              );
              simpost.hour = simpost.Timestamp.toDate().getHours();
              simpost.minutes = (
                "0" + simpost.Timestamp.toDate().getMinutes()
              ).slice(-2);

              cs.forEach((c) => {
                if (c.Redrop_id === simpost.id) {
                  simpost.Redropped = "done";
                }
              });

              return simpost;
            });

            setSimdisplays([...simdisplays, simposts]);
          });
        });
      } else if (
        alldisplay.length > 1 &&
        realtimecomment[0] === alldisplay[alldisplay.length - 1].displayid
      ) {
        let commentpush = simdisplays[simdisplays.length - 1].findIndex(
          (element) =>
            userinfo.Rank < element.Rank || element.Rank === userinfo.Rank
        );
        console.log(commentpush);

        if (
          realtimecomment[0] !== alldisplay[0].displayid1 &&
          realtimecomment[0] !== commentload.id
        ) {
          console.log(realtimecomment[0]);
          console.log(commentload);
          let decidecomment1 = simdisplays[simdisplays.length - 2].filter(
            (simdisplay) => simdisplay.id === realtimecomment[0]
          );
          console.log(decidecomment1);
          decidecomment1[0].Comment_Counter =
            decidecomment1[0].Comment_Counter + 1;
        } else if (realtimecomment[0] === commentload.id) {
          commentload.Comment_Counter = commentload.Comment_Counter + 1;
        }

        if (commentpush === 0) {
          setSimdisplays([
            ...simdisplays.slice(0, -1),

            [
              {
                Directory: realtimecomment[0],
                Sender_id: id,
                Comment: send,
                id: lit,
                year: new Date().getFullYear(),
                month: ("0" + (new Date().getMonth() + 1)).slice(-2),
                date: ("0" + new Date().getDate()).slice(-2),
                hour: new Date().getHours(),
                minutes: ("0" + new Date().getMinutes()).slice(-2),
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Rank: userinfo.Rank,
                Profile_image: userinfo.Profile_image,
                Comment_Counter: 0,
                Sent_id: realtimecomment[1],
                Redropped_Counter: 0,
                This_Directory: lit,
              },
              ...simdisplays[simdisplays.length - 1],
            ],
          ]);
        } else if (commentpush > 0) {
          setSimdisplays([
            ...simdisplays.slice(0, -1),

            [
              ...simdisplays[simdisplays.length - 1].slice(0, commentpush),
              {
                Directory: realtimecomment[0],
                Sender_id: id,
                Comment: send,
                id: lit,
                year: new Date().getFullYear(),
                month: ("0" + (new Date().getMonth() + 1)).slice(-2),
                date: ("0" + new Date().getDate()).slice(-2),
                hour: new Date().getHours(),
                minutes: ("0" + new Date().getMinutes()).slice(-2),
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Rank: userinfo.Rank,
                Profile_image: userinfo.Profile_image,
                Comment_Counter: 0,
                Sent_id: realtimecomment[1],
                Redropped_Counter: 0,
                This_Directory: lit,
              },
              ...simdisplays[simdisplays.length - 1].slice(commentpush),
            ],
          ]);
        } else if (
          commentpush === -1 &&
          simdisplays[simdisplays.length - 1].length > 0
        ) {
          setSimdisplays([
            ...simdisplays.slice(0, -1),

            [
              ...simdisplays[simdisplays.length - 1],
              {
                Directory: realtimecomment[0],
                Sender_id: id,
                Comment: send,
                id: lit,
                year: new Date().getFullYear(),
                month: ("0" + (new Date().getMonth() + 1)).slice(-2),
                date: ("0" + new Date().getDate()).slice(-2),
                hour: new Date().getHours(),
                minutes: ("0" + new Date().getMinutes()).slice(-2),
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Rank: userinfo.Rank,
                Profile_image: userinfo.Profile_image,
                Comment_Counter: 0,
                Sent_id: realtimecomment[1],
                Redropped_Counter: 0,
                This_Directory: lit,
              },
            ],
          ]);
        } else if (
          commentpush === -1 &&
          simdisplays[simdisplays.length - 1].length === 0
        ) {
          setSimdisplays([
            ...simdisplays.slice(0, -1),

            [
              {
                Directory: realtimecomment[0],
                Sender_id: id,
                Comment: send,
                id: lit,
                year: new Date().getFullYear(),
                month: ("0" + (new Date().getMonth() + 1)).slice(-2),
                date: ("0" + new Date().getDate()).slice(-2),
                hour: new Date().getHours(),
                minutes: ("0" + new Date().getMinutes()).slice(-2),
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Rank: userinfo.Rank,
                Profile_image: userinfo.Profile_image,
                Comment_Counter: 0,
                Sent_id: realtimecomment[1],
                Redropped_Counter: 0,
                This_Directory: lit,
              },
            ],
          ]);
        }
      }

      setTimeout(() => {
        if (
          alldisplay.length === 1 &&
          realtimecomment[0] === alldisplay[alldisplay.length - 1].displayid1
        ) {
          alldisplay[alldisplay.length - 1].Comment_Counter =
            alldisplay[alldisplay.length - 1].Comment_Counter + 1;
          alldisplay.push({
            ...alldisplay[alldisplay.length - 1],
            id: alldisplay[alldisplay.length - 1].id + 1,
            displayid: alldisplay[alldisplay.length - 1].displayid1,
          });
          setTimeout(() => {
            delete alldisplay[alldisplay.length - 1].displayid1;
            console.log(alldisplay);
          });
        } else if (
          (alldisplay.length === 1 &&
            realtimecomment[0] !==
              alldisplay[alldisplay.length - 1].displayid1) ||
          (alldisplay.length > 1 &&
            realtimecomment[0] !== alldisplay[alldisplay.length - 1].displayid)
        ) {
          alldisplay.push({
            id: alldisplay.length + 1,
            displayid: realtimecomment[0],
            Sender_id: realtimecomment[1],
            Comment_Counter: parseInt(realtimecomment[7]) + 1,
            Comment: realtimecomment[8],
            hour: realtimecomment[2],
            minutes: realtimecomment[3],
            year: realtimecomment[4],
            month: realtimecomment[5],
            date: realtimecomment[6],
            Redropped_Counter: realtimecomment[9],
            Rank: realtimecomment[10],
            Profile_image: realtimecomment[11],
            image1: realtimecomment[12],
            image2: realtimecomment[13],
            image3: realtimecomment[14],
            image4: realtimecomment[15],
            video: realtimecomment[16],
            Redropper_id: realtimecomment[17],
            Sent_id: realtimecomment[18],
            This_Directory: realtimecomment[19],
          });
        } else if (
          (alldisplay.length > 1 &&
            realtimecomment[0] ===
              alldisplay[alldisplay.length - 1].displayid) ||
          realtimecomment[0] ===
            alldisplay[alldisplay.length - 1].This_Directory
        ) {
          alldisplay.map((obj) => {
            if (obj.id === alldisplay.length) {
              setAlldisplay((prevvalue) => [
                ...prevvalue.slice(0, -1),

                {
                  ...obj,
                  Comment_Counter: obj.Comment_Counter + 1,
                },
              ]);
            }
          });
          if (realtimecomment[0] === alldisplay[0].displayid1) {
            alldisplay[alldisplay.length - 2].Comment_Counter =
              alldisplay[alldisplay.length - 2].Comment_Counter + 1;
          }
        }

        setClickedcomment("");
      }, 180);

      setSend("");

      const dropsRef1 = query(
        collectionGroup(db, "Drops"),
        where("Redrop_id", "==", realtimecomment[0])
      );

      const querySnapshot1 = getDocs(dropsRef1).then((querySnapshot) => {
        let posts1 = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let new_posts1 = posts1.filter(
          (post) => post.id !== realtimecomment[0]
        );

        let batchArray = [];
        batchArray.push(writeBatch(db));
        let batchCount = 0;
        let batchIndex = 0;
        new_posts1.forEach((post) => {
          const userDocumentRef = doc(
            db,
            "users",
            post.Redropper_id,
            "Drops",
            post.id
          );
          batchArray[batchIndex].update(userDocumentRef, {
            Comment_Counter: increment(1),
          });
          batchCount++;
          if (batchCount === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            batchCount = 0;
          }
        });

        batchArray.forEach(async (batch) => await batch.commit());
      });

      setTimeout(() => {
        console.log(alldisplay);
        if (realtimecomment[1] !== id) {
          console.log(alldisplay.length);
          let loadtype0 = alldisplay[alldisplay.length - 1].Sender_id
            ? "Comments"
            : "Drops";

          if (realtimecomment[0] === alldisplay[0].displayid1) {
            addDoc(
              collection(db, `users/${realtimecomment[1]}/Notification/`),
              {
                Type: "Comment",
                Loadtype: loadtype0,
                Timestamp: serverTimestamp(),
                Profile_image: userinfo.Profile_image,
                Sender_id: id,
                Sent_id: realtimecomment[1],
                Sender_name: userinfo.User_Name,
                Rank: userinfo.Rank,
                Comment: send,
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Directory: realtimecomment[0],
                Comment_Directory: lit,
              }
            );
          } else {
            addDoc(
              collection(db, `users/${realtimecomment[1]}/Notification/`),
              {
                Type: "Comment",
                Loadtype: loadtype0,
                Timestamp: serverTimestamp(),
                Profile_image: userinfo.Profile_image,
                Sender_id: id,
                Sent_id: realtimecomment[1],
                Sender_name: userinfo.User_Name,
                Rank: userinfo.Rank,
                Comment: send,
                image1: image,
                image2: image,
                image3: image,
                image4: image,
                video: video,
                Directory: realtimecomment[0],
                Comment_Directory: lit,
                rootid: alldisplay[0].displayid1,
                root_user: alldisplay[0].Directory,
              }
            );

            if (id !== alldisplay[0].Directory) {
              addDoc(
                collection(
                  db,
                  `users/${alldisplay[0].Directory}/Notification/`
                ),
                {
                  Type: "Comment",
                  Loadtype: loadtype0,
                  Timestamp: serverTimestamp(),
                  Profile_image: userinfo.Profile_image,
                  Sender_id: id,
                  Sent_id: realtimecomment[1],
                  Sender_name: userinfo.User_Name,
                  Rank: userinfo.Rank,
                  Comment: send,
                  image1: image,
                  image2: image,
                  image3: image,
                  image4: image,
                  video: video,
                  Directory: realtimecomment[0],
                  Comment_Directory: lit,
                  rootid: alldisplay[0].displayid1,
                  root_user: alldisplay[0].Directory,
                }
              );
            }
          }
          updateDoc(doc(db, `users/${realtimecomment[1]}`), {
            Notification: increment(1),
          });
        }
      }, 1000);
    });
    setTimeout(() => {
      const userBlockRef = doc(
        db,
        "users",
        alldisplay[alldisplay.length - 1].Sender_id
          ? alldisplay[alldisplay.length - 1].Sender_id
          : alldisplay[alldisplay.length - 1].Directory,
        "Blocked",
        id
      );

      getDoc(userBlockRef).then((documentSnapshot) => {
        documentSnapshot.data().Blocked_id &&
          navigate(
            `/UserView/${
              alldisplay[alldisplay.length - 1].Sender_id
                ? alldisplay[alldisplay.length - 1].Sender_id
                : alldisplay[alldisplay.length - 1].Directory
            }`
          );
      });
    }, 1001);
  };
*/

  const fileload = async () => {
    let file = [
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
    ];
    let promises = [];
    if (tempfiles[0]) {
      const storage = getStorage();
      let num0 = 0;
      let num = 0;

      for (const tempfile of tempfiles) {
        file[num0].filetype = tempfile.realfile.type.includes("video")
          ? "videos"
          : "images";
        console.log(tempfile);
        file[num0].id = tempfile.id;

        const storageRef = ref(
          storage,
          `${file[num0].filetype}/${tempfile.id}`
        );

        num0++;
        promises.push(
          await new Promise((resolve, reject) => {
            uploadBytes(storageRef, tempfile.realfile).then((uploadtask) => {
              console.log(uploadtask);
              resolve(
                getDownloadURL(storageRef).then((url) => {
                  console.log(url);
                  let target = 1;
                  console.log(target);
                  //findindex of tempfiles and match it with file[target?]
                  file[num].path = url;
                  if (num + 1 === tempfiles.length) {
                    setProgresspercent(100);
                  } else {
                    setProgresspercent(
                      Math.round(100 / tempfiles.length) * (num + 1)
                    );
                  }
                  num++;
                })
              );
            });
          })
        );

        URL.revokeObjectURL(tempfile.file);
      }
      setTwominutevideo(0);
    }

    await promises[0];
    await promises[1];
    await promises[2];
    await promises[3];

    console.log(file);
    return file;
  };

  const SendComment = async () => {
    //we might need the This_directory put in the state?

    //dropref.current = false;
    setDisablepress(true);
    let DirectoryorSenderid =
      clickedcomment.id === alldisplay[0].id
        ? [clickedcomment.Directory, "Drops"]
        : [clickedcomment.Sender_id, "Comments"];

    let file = await fileload();
    let commentobject = {
      Directory: clickedcomment.id,
      Sender_id: id,
      Comment: send,
      Timestamp: serverTimestamp(),
      file1: file[0],
      file2: file[1],
      file3: file[2],
      file4: file[3],
      Rank: userinfo.Rank,
      Profile_image: userinfo.Profile_image,
      Comment_Counter: 0,
      Sent_id: DirectoryorSenderid[0],
      Redropped_Counter: 0,
      //This_Directory: "",
    };
    await addDoc(collection(db, `users/${id}/Comments/`), commentobject).then(
      (docRef) => {
        /* updateDoc(doc(db, `users/${id}/Comments/${docRef.id}`), {
          This_Directory: docRef.id,
        });*/
        //commentobject.This_Directory = docRef.id;

        //do we even need This_Directory ever?

        if (
          clickedcomment.Sender_id !== id &&
          clickedcomment.Directory !== id
        ) {
          //cleareddddddddddddddddddddddddddddddddddddddd
          let loadtype0 = clickedcomment.Sender_id ? "Comments" : "Drops";

          let notificationinfo = {
            Type: "Comment",
            Loadtype: loadtype0,
            Sender_name: userinfo.User_Name,
            Comment_Directory: docRef.id,
          };

          if (clickedcomment.id === alldisplay[0].id) {
            //Directory for the first notification block needs to be changed to
            //clickedcomment.id ?

            addDoc(
              collection(db, `users/${clickedcomment.Directory}/Notification/`),
              {
                ...commentobject,
                //prolly need Directory here its goin to be clickedcomment.id?
                ...notificationinfo,
              }
            );
          } else {
            addDoc(
              collection(db, `users/${clickedcomment.Sender_id}/Notification/`),
              {
                ...commentobject,
                ...notificationinfo,
                Directory: clickedcomment.id,
                rootid: alldisplay[0].id,
                root_user: alldisplay[0].Directory,
                notification_reciever: clickedcomment.Sender_id,
              }
            );

            ///this needs to be revised since first of all
            //do we even need this since this could confuse people on who commented to who
            //and this doesnt trigger if commenting to your own comments so thats wrong ( it needs to trigger even if you are commenting to yourself as long as alldisplay[0] isnt you )
            //this might need to be outside of the if statement in around line 2426 since it wont trigger if you are commenting to your owl comment
            if (
              // clickedcomment.Sender_id !== id &&
              alldisplay[0].Directory !== clickedcomment.Sender_id &&
              alldisplay[0].Directory !== id
            ) {
              //need to make sure that the person recieves this knows that its to someone else
              //the comment is not to you
              //prolly notification reciver is === rootuser than its not to you
              //but you are the root user
              addDoc(
                collection(
                  db,
                  `users/${alldisplay[0].Directory}/Notification/`
                ),
                {
                  ...commentobject,
                  ...notificationinfo,
                  Directory: clickedcomment.id,
                  rootid: alldisplay[0].id,
                  root_user: alldisplay[0].Directory,
                  notification_reciever: alldisplay[0].Directory,
                }
              );
            }
          }
          updateDoc(doc(db, `users/${DirectoryorSenderid[0]}`), {
            Notification: increment(1),
          });
        }

        try {
          updateDoc(
            doc(
              db,
              `users/${DirectoryorSenderid[0]}/${DirectoryorSenderid[1]}/${clickedcomment.id}`
            ),
            {
              Comment_Counter: increment(1),
            }
          );
        } catch (error) {
          console.log(error);
        }
        //get rid of topsubtract state

        setSend("");
        setTempfiles([]);
        //put this in the if statement down below if(alldisplay.length === 0)setShowdrop(true)
        let CommentCounterUpdated = {
          ...clickedcomment,
          Comment_Counter: clickedcomment.Comment_Counter + 1,
        };
        console.log(CommentCounterUpdated);
        let Commentincrementtarget;
        const postincrement = () => {
          /* Commentincrementtarget = posts.findIndex(
            (post) => clickedcomment.id === post.id
          );
          posts[Commentincrementtarget] = {
            ...posts[Commentincrementtarget],
            Comment_Counter: posts[Commentincrementtarget].Comment_Counter + 1,
          };*/
        };
        const commentincrement = (index) => {
          Commentincrementtarget = simdisplays
            .at(index)
            .findIndex((simdisplay) => clickedcomment.id === simdisplay.id);
          simdisplays.at(index)[Commentincrementtarget] = {
            ...simdisplays.at(index)[Commentincrementtarget],
            Comment_Counter:
              simdisplays.at(index)[Commentincrementtarget].Comment_Counter + 1,
          };
        };
        let reffilllength;
        ////////////////////////////////////////////////////////////
        if (
          clickedcomment.id !== alldisplay.at(-1)?.id ||
          alldisplay.length === 1
          //(clickedcomment.id === alldisplay[0].id && alldisplay.length === 1)
        ) {
          //maybe set commentload and simdisplays of alldisplay.length 1 here
          if (clickedcomment.id === alldisplay[0].id)
            setAlldisplay([
              {
                ...alldisplay[0],
                Comment_Counter: alldisplay[0].Comment_Counter + 1,
              },
              CommentCounterUpdated,
            ]);
          else {
            if (clickedcomment.id === commentload.id)
              setCommentload({
                ...commentload,
                Comment_Counter: commentload.Comment_Counter + 1,
              });
            setAlldisplay([...alldisplay, CommentCounterUpdated]);
          }

          const dropsRef = query(
            collectionGroup(db, "Comments"),
            where("Directory", "==", clickedcomment.id)
          );

          getDocs(
            query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
          ).then((querySnapshot) => {
            let simposts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            console.log(simposts);
            const postsRef = collection(db, "users", id, "Drops");
            getDocs(postsRef).then((querySnapshot) => {
              let posts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));

              let Redroppedcomments = posts.filter(
                (post) => post.Sender_id && post.Redropper_id === id
              );

              console.log(Redroppedcomments);

              simposts.map((simpost) => {
                if (!simpost.Redropper_id) {
                  simpost.year = simpost.Timestamp.toDate().getFullYear();
                  simpost.month = (
                    "0" +
                    (simpost.Timestamp.toDate().getMonth() + 1)
                  ).slice(-2);
                  simpost.date = (
                    "0" + simpost.Timestamp.toDate().getDate()
                  ).slice(-2);
                  simpost.hour = simpost.Timestamp.toDate().getHours();
                  simpost.minutes = (
                    "0" + simpost.Timestamp.toDate().getMinutes()
                  ).slice(-2);
                }
                Redroppedcomments.forEach((redroppedcomment) => {
                  if (redroppedcomment.Redrop_id === simpost.id) {
                    simpost.Redropped = "done";
                  }
                });
              });
              console.log(simposts);
            });

            //maybe promise is better instead of timeout?
            setTimeout(() => {
              reffilllength = simposts.length + 1;
              if (clickedcomment.id === simdisplays[0][0].id)
                setSimdisplays([
                  [
                    {
                      ...simdisplays[0][0],
                      Comment_Counter: simdisplays[0][0].Comment_Counter + 1,
                    },
                  ],
                  simposts,
                ]);
              else setSimdisplays([...simdisplays, simposts]);
            }, 500);
          });

          /*if (/*alldisplay.length === 1 clickedcomment.id === alldisplay[0].id) {
            //postincrement();
            console.log("HELLO")
          } else {
            commentincrement(-1);
          }*/

          // if(clickedcomment.id === alldisplay[0].id)
          if (
            /*clickedcomment.id !== alldisplay[0].id &&*/ alldisplay.length > 1
          )
            commentincrement(-1);
          //need to increment commentload as well?
        } else {
          //if(alldisplay[0].id ===  clickedcomment.id)

          let plugalldisplay =
            alldisplay[0].id === clickedcomment.id
              ? [
                  {
                    ...alldisplay[0],
                    Comment_Counter: alldisplay[0].Comment_Counter + 1,
                  },
                ]
              : alldisplay.slice(0, -1);

          setAlldisplay([
            //...alldisplay.slice(0, -1),
            ...plugalldisplay,
            {
              ...alldisplay.at(-1),
              Comment_Counter: alldisplay.at(-1).Comment_Counter + 1,
            },
          ]);
          let ranktarget = simdisplays
            .at(-1)
            .findIndex((simdisplay) => userinfo.Rank <= simdisplay.Rank);
          console.log(userinfo.Rank);
          console.log(ranktarget);

          let Time = new Date();
          commentobject = {
            ...commentobject,
            id: docRef.id,
            year: Time.getFullYear(),
            month: ("0" + (Time.getMonth() + 1)).slice(-2),
            date: ("0" + Time.getDate()).slice(-2),
            hour: Time.getHours(),
            minutes: ("0" + Time.getMinutes()).slice(-2),
          };
          let plugsimdisplays =
            clickedcomment.id === simdisplays[0][0].id
              ? [
                  [
                    {
                      ...simdisplays[0][0],
                      Comment_Counter: simdisplays[0][0].Comment_Counter + 1,
                    },
                  ],
                ]
              : simdisplays.slice(0, -1);
          console.log(plugsimdisplays);

          //  if(clickedcomment.id === simdisplays[0][0].id)setSimdisplays([[{...simdisplays[0][0],Comment_Counter: simdisplays[0][0].Comment_Counter + 1}], simposts])
          if (ranktarget === 0)
            setSimdisplays([
              ...plugsimdisplays,
              //...simdisplays.slice(0, -1),
              [commentobject, ...simdisplays.at(-1)],
            ]);
          else if (ranktarget > 0)
            setSimdisplays([
              ...plugsimdisplays,
              //...simdisplays.slice(0, -1),
              [
                ...simdisplays.at(-1).slice(0, ranktarget),
                commentobject,
                ...simdisplays.at(-1).slice(ranktarget),
              ],
            ]);
          else
            setSimdisplays([
              ...plugsimdisplays,
              //...simdisplays.slice(0, -1),
              [...simdisplays.at(-1), commentobject],
            ]);

          /*if (alldisplay.length === 2) {
            postincrement();
          } else {
            commentincrement(-2);
          }*/
          if (commentload.id === clickedcomment.id)
            setCommentload({
              ...commentload,
              Comment_Counter: commentload.Comment_Counter + 1,
            });
          else if (alldisplay.length > 2) commentincrement(-2);

          reffilllength = simdisplays.at(-1).length + 2;
        }

        setTimeout(() => {
          //check if the length is correct since realid is going to be +2 and simposts is going to be +1
          console.log(reffilllength);
          droppedvideoref.current = new Array(reffilllength).fill(null);
          console.log(droppedvideoref.current);
          setVideorefload(!videorefload);
          //700 may not be enough who knows, if theres errors, change it
        }, 800);
      }
    );

    setTimeout(() => {
      //dropref.current = true;
      setProgresspercent(0);
    });

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", clickedcomment.id)
    );

    getDocs(dropsRef1).then((querySnapshot) => {
      let posts1 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let new_posts1 = posts1.filter((post) => post.id !== clickedcomment.id);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts1.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Comment_Counter: increment(1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    setClickedcomment("");
  };

  const AskUnredrop = (e) => {
    let unredropidpost = e.target.id.split(",");
    console.log(unredropidpost);
    setUnredropdid(e.target.id.split(","));
    const UnredropRef = collection(db, "users", id, "Drops");
    const querySnapshot = getDocs(UnredropRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(posts);
      let num = -1;
      let redropanswer = [];
      for (let step = 0; step < posts.length; step++) {
        num++;
        redropanswer.push(posts[num].Redrop_id === unredropidpost[0]);
      }
      console.log(redropanswer);
      let unredropc = (element) => element == true;
      let targetunredrop = redropanswer.findIndex(unredropc);

      console.log(targetunredrop);
      setUnredropid1(posts[targetunredrop].id);
    });

    setUnredroppoint(true);
    setRedroppoint(false);
    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
  };

  const redrop = (event) => {
    let redrop = event.target.id.split(",");
    console.log(redrop);
    setRedropid(redrop);
    const userBlockRef = doc(db, "users", redrop[1], "Blocked", id);
    getDoc(userBlockRef).then((documentSnapshot) => {
      documentSnapshot.data().Blocked_id && navigate(`/UserView/${redrop[4]}`);
    });
    setRedroppoint(true);
    setUnredroppoint(false);
    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
  };

  const confirmredrop = async () => {
    console.log(redropid);
    try {
      if (redropid[1] !== id) {
        await updateDoc(
          doc(
            db,
            `users/${redropid[1]}/${
              alldisplay[0]?.displayid1 === redropid[0] ? "Drops" : "Comments"
            }/${redropid[0]}`
          ),
          {
            Redropped_Counter: increment(1),
          }
        );
      } else {
        await updateDoc(
          doc(
            db,
            `users/${redropid[1]}/${
              alldisplay[0]?.displayid1 === redropid[0] ? "Drops" : "Comments"
            }/${redropid[0]}`
          ),
          {
            Redropped_Counter: increment(1),
            Redrop_id: redropid[0],
            Redropped: true,
            Redropper_id: id,
          }
        );
      }

      if (redropid[1] !== id && alldisplay[0]?.displayid1 === redropid[0]) {
        addDoc(collection(db, `users/${id}/Drops/`), {
          Redropped: true,
          Directory: redropid[1],
          Real_Timestamp: [
            redropid[6],
            redropid[7],
            redropid[8],
            redropid[9],
            redropid[10],
          ],
          image1: redropid[11],
          image2: redropid[12],
          image3: redropid[13],
          image4: redropid[14],
          video: redropid[15],
          Rank: redropid[2],
          Profile_image: redropid[3],
          Comment_Counter: redropid[5],
          Redropped_Counter: parseInt(redropid[17]),
          Redrop_id: redropid[0],
          text: redropid[4],
          Redropper_id: id,
          Timestamp: serverTimestamp(),
        });
      } else if (alldisplay[0]?.displayid1 !== redropid[0]) {
        addDoc(collection(db, `users/${id}/Drops/`), {
          Redropped: true,
          Directory: redropid[19],
          Real_Timestamp: [
            redropid[6],
            redropid[7],
            redropid[8],
            redropid[9],
            redropid[10],
          ],
          image1: redropid[11],
          image2: redropid[12],
          image3: redropid[13],
          image4: redropid[14],
          video: redropid[15],
          Rank: redropid[2],
          Profile_image: redropid[3],
          Comment_Counter: redropid[5],
          Redropped_Counter: parseInt(redropid[17]),
          Redrop_id: redropid[0],
          text: redropid[4],
          Redropper_id: id,
          Timestamp: serverTimestamp(),
          Sender_id: redropid[1],
          Sent_id: redropid[18],
          This_Directory: redropid[20],
        });
      }
    } catch (error) {
      console.log(error);
    }

    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", redropid[0])
    );
    const querySnapshot = getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(posts.length);

      let new_posts =
        alldisplay[0]?.displayid1 === redropid[0]
          ? posts.filter((post) => post.Directory !== post.Redropper_id)
          : posts;

      console.log(new_posts.length);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Redropped_Counter: increment(1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    if (
      redropid[0] === alldisplay[0].displayid1 ||
      redropid[0] === alldisplay[alldisplay.length - 1].displayid
    ) {
      alldisplay[alldisplay.length - 1].Redropped = "done";
      alldisplay[alldisplay.length - 1].Redropped_Counter =
        alldisplay[alldisplay.length - 1].Redropped_Counter + 1;
      if (redropid[0] === commentload.id) {
        commentload.Redropped_Counter = commentload.Redropped_Counter + 1;
        commentload.Redropped = "done";
      } else if (
        alldisplay.length > 1 &&
        alldisplay[alldisplay.length - 1]?.displayid !==
          alldisplay[0].displayid1
      ) {
        simdisplays[simdisplays.length - 2].forEach((simpost) => {
          if (simpost.id === redropid[0]) {
            (simpost.Redropped = "done") &&
              (simpost.Redropped_Counter = simpost.Redropped_Counter + 1);
          }
        });
      }
    } else if (redropid[0] === commentload.id) {
      commentload.Redropped_Counter = commentload.Redropped_Counter + 1;
      commentload.Redropped = "done";
    } else if (
      redropid[0] !== alldisplay[0].displayid1 &&
      redropid[0] !== alldisplay[alldisplay.length - 1].displayid
    ) {
      simdisplays[simdisplays.length - 1].forEach((simpost) => {
        if (simpost.id === redropid[0]) {
          (simpost.Redropped = "done") &&
            (simpost.Redropped_Counter = simpost.Redropped_Counter + 1);
        }
      });
    }

    setRedroppoint(false);

    if (redropid[1] !== id) {
      let a =
        redropid[0] === alldisplay[0]?.displayid1
          ? "Drops"
          : redropid[0] !== alldisplay[0]?.displayid1 &&
            redropid[19] === alldisplay[0]?.displayid1
          ? "Comments"
          : "NestedComments";
      if (a === "Drops") {
        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: redropid[1],
          rootid: redropid[0],
          Redropper_id: id,
        });
      } else if (a === "Comments") {
        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: redropid[1],
          Comment_Directory: redropid[0],
          rootid: alldisplay[0]?.displayid1,
          root_user: alldisplay[0]?.Directory,
          Redropper_id: id,
        });
      } else if (a === "NestedComments") {
        let b =
          alldisplay.length === 1 ||
          (alldisplay.length === 2 &&
            commentload &&
            redropid[0] === simdisplays[0][0].id)
            ? commentload.id
            : alldisplay.length === 3 &&
              redropid[0] === alldisplay[alldisplay.length - 1].displayid
            ? alldisplay[alldisplay.length - 2].displayid
            : alldisplay[alldisplay.length - 1].displayid;
        let c =
          alldisplay.length === 1 ||
          (alldisplay.length === 2 &&
            commentload &&
            redropid[0] === simdisplays[0][0].id)
            ? commentload.Sender_id
            : alldisplay.length === 3 &&
              redropid[0] === alldisplay[alldisplay.length - 1].displayid
            ? alldisplay[alldisplay.length - 2].Sender_id
            : alldisplay[alldisplay.length - 1].Sender_id;

        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: c,
          Comment_Directory: b,
          Nested_Directory: redropid[0],
          Nested_user: redropid[1],
          rootid: alldisplay[0]?.displayid1,
          root_user: alldisplay[0]?.Directory,
          Redropper_id: id,
        });
      }
      updateDoc(doc(db, `users/${redropid[1]}`), {
        Notification: increment(1),
      });
    }
  };

  const ConfirmUnredrop = async () => {
    if (
      (unredropdid[1] !== id && unredropdid[0] === alldisplay[0].displayid1) ||
      unredropdid[0] !== alldisplay[0].displayid1
    ) {
      const userDocumentRef = doc(db, "users", id, "Drops", unredropid1);
      await deleteDoc(userDocumentRef);
    }
    if (unredropdid[2] > 1) {
      updateDoc(
        doc(
          db,
          `users/${unredropdid[1]}/${
            alldisplay[0]?.displayid1 !== unredropdid[0] ? "Comments" : "Drops"
          }/${unredropdid[0]}`
        ),
        {
          Redropped_Counter: increment(-1),
        }
      );
    } else {
      updateDoc(
        doc(
          db,
          `users/${unredropdid[1]}/${
            alldisplay[0]?.displayid1 !== unredropdid[0] ? "Comments" : "Drops"
          }/${unredropdid[0]}`
        ),
        {
          Redropped_Counter: increment(-1),
          Redrop_id: deleteField(),
          Redropped: deleteField(),
          Redropper_id: deleteField(),
        }
      );
    }

    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", unredropdid[0])
    );
    const querySnapshot = getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let new_posts =
        alldisplay[0]?.displayid1 === unredropdid[0]
          ? posts.filter((post) => post.Directory !== post.Redropper_id)
          : alldisplay[0]?.displayid1 !== unredropdid[0]
          ? posts
          : null;

      console.log(new_posts);
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Redropped_Counter: increment(-1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    if (
      alldisplay[0]?.displayid1 === unredropdid[0] ||
      unredropdid[0] === alldisplay[alldisplay.length - 1].displayid
    ) {
      alldisplay[alldisplay.length - 1].Redropped_Counter =
        alldisplay[alldisplay.length - 1].Redropped_Counter - 1;
      delete alldisplay[alldisplay.length - 1].Redropped;
      if (unredropdid[0] === commentload.id) {
        commentload.Redropped_Counter = commentload.Redropped_Counter - 1;
        delete commentload.Redropped;
      } else if (
        alldisplay.length > 1 &&
        alldisplay[alldisplay.length - 1]?.displayid !==
          alldisplay[0].displayid1
      ) {
        simdisplays[simdisplays.length - 2].forEach((simdisplay) => {
          if (unredropdid[0] === simdisplay.id) {
            simdisplay.Redropped =
              simdisplay.Redropped_Counter > 1 &&
              simdisplay.Sender_id === simdisplay.Redropper_id
                ? true
                : "";
            simdisplay.Redropped_Counter = simdisplay.Redropped_Counter - 1;
          }
        });
      }
    } else if (unredropdid[0] === commentload.id) {
      commentload.Redropped_Counter = commentload.Redropped_Counter - 1;
      delete commentload.Redropped;
    } else {
      simdisplays[simdisplays.length - 1].forEach((simdisplay) => {
        if (unredropdid[0] === simdisplay.id) {
          simdisplay.Redropped =
            simdisplay.Redropped_Counter > 1 &&
            simdisplay.Sender_id === simdisplay.Redropper_id
              ? true
              : "";
          simdisplay.Redropped_Counter = simdisplay.Redropped_Counter - 1;
        }
      });
    }
    setUnredroppoint(false);
  };

  const CancelUnredrop = () => {
    setUnredroppoint(false);
  };

  const backed = () => {
    //this is currently being used for Follow back

    //setShow(false);
    setNotificationtype("");
    //setNotificationon(true);

    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);
    setCommentload("");
    //setCommentloadrefindex(0)
    //setOneoffdisplay(false);
    setBack(!back);
  };

  const displayback = async () => {
    if (alldisplay.length === 1) {
      setSimdisplays([]);
      setCommentload("");
      setNotificationtype("");
      setCommentloadrefindex(0);
      //setBack(true);

      //actually do we need observation on the notification videos?
      /*setTimeout(() => {

        droppedvideoref.current = new Array(posts.length).fill(null);
       
        setVideorefload(!videorefload);
      });*/
    } else {
      /*
      let Directory
      let tempindex = 0
      if(alldisplay.length === 2){
        Directory = alldisplay.at(-2).Directory
        if(commentload) {
      setCommentloadrefindex(1);
        tempindex = 1;
        }
      }
    else Directory = alldisplay.at(-2).Sender_id
setTimeout(() => {
        droppedvideoref.current = new Array(
          simdisplays.at(-2).length + 1 + tempindex
        ).fill(null)})
*/
      let Directory = alldisplay.at(-2).Sender_id
        ? alldisplay.at(-2).Sender_id
        : alldisplay.at(-2).Directory;

      /*let Directory =
        alldisplay.length === 2
          ? alldisplay.at(-2).Directory
          : alldisplay.at(-2).Sender_id;*/
      let blocked;
      if (Directory !== id) {
        console.log(Directory);
        const userBlockRef = doc(db, "users", Directory, "Blocked", id);
        blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
          return documentSnapshot;
        });
      }

      console.log(blocked);
      console.log(blocked?.exists());
      console.log(blocked?.data());

      const blockedaction = () => {
        alldisplay.pop();
        simdisplays.pop();
        displayback();
      };
      if (blocked?.exists()) return blockedaction();

      console.log("THIS BETTTER NOT RUN");
      ///commentloadrefindex??
      let tempindex = 0;
      if (alldisplay.length === 2 && commentload) {
        setCommentloadrefindex(1);
        tempindex = 1;
      }

      setSimdisplays(simdisplays.slice(0, -1));
      setTimeout(() => {
        droppedvideoref.current = new Array(
          simdisplays.at(-2).length + 1 + tempindex
        ).fill(null);
        console.log(droppedvideoref.current);
        //setBack(true);
        setVideorefload(!videorefload);
      });
    }
    console.log("Does this run");
    Optionreset();
    setTimeout(() => {
      setBack(true);
    });
  };

  /*const displayback = () => {
    const userBlockRef = doc(
      db,
      "users",
      alldisplay[alldisplay.length - 2].Sender_id
        ? alldisplay[alldisplay.length - 2].Sender_id
        : alldisplay[alldisplay.length - 2].Directory,
      "Blocked",
      id
    );
    getDoc(userBlockRef).then((documentSnapshot) => {
      documentSnapshot.data().Blocked_id &&
        navigate(
          `/UserView/${
            alldisplay[alldisplay.length - 2].Sender_id
              ? alldisplay[alldisplay.length - 2].Sender_id
              : alldisplay[alldisplay.length - 2].Directory
          }`
        );
    });

    //setShow(false);

    setSimdisplays(simdisplays.slice(0, -1));

    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);
    if (
      alldisplay[alldisplay.length - 1]?.displayid !== alldisplay[0].displayid1
    ) {
      setTimeout(() => {
        setBack2(true);
      }, 200);
    } else {
      setTimeout(() => {
        setAlldisplay(alldisplay.slice(0, -1));
      }, 200);
    }
  };
  */

  /*const commentcancel = () => {
    setClickedcomment("");
    setCommentId("");
    setDelete1(false);
    setDelete2(false);
    setDeleteid("");
    setSend("");
    if (
      alldisplay[0]?.displayid1 !== realtimecomment[0] &&
      alldisplay[alldisplay.length - 1]?.displayid !== realtimecomment[0]
    ) {
      setBack3(true);
    }
  };*/

  const commentcancel = () => {
    console.log(droppedvideoref.current);
    setDeleteid("");
    setOptionId("");
    setSend("");
    setTempfiles([]);
    setTwominutevideo(0);
    console.log(twominutevideo);
    let reffilllength;

    if (
      clickedcomment.id !== alldisplay[alldisplay.length - 1]?.id &&
      alldisplay.length > 1
    ) {
      // if (alldisplay.length > 1) {
      setCommentbackid(clickedcomment.id);
      //setClickedcomment("");
      reffilllength = simdisplays.at(-1).length + 1;
      setBack(true);
      //}
      /*else {
        reffilllength = 3;
      }*/
      //setClickedcomment("");
    } else if (alldisplay.length === 1 && commentload) reffilllength = 3;
    else {
      //setClickedcomment("");
      reffilllength = simdisplays.at(-1).length + 1;
    }
    //if(alldisplay.length=== 1 && commentload) reffilllength= 3

    setClickedcomment("");
    setTimeout(() => {
      droppedvideoref.current = new Array(reffilllength).fill(null);
      console.log(droppedvideoref.current);
      setVideorefload(!videorefload);
    });
    /* setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);*/
  };

  const understandblocked = () => {
    setBlockid("");
  };

  const arraystyle = (post) => {
    let arrayornot = {
      display: "flex",

      height: 25,
      marginTop: 3,
    };
    arrayornot = !Array.isArray(post)
      ? {
          ...arrayornot,
          marginLeft: "auto",
          //flex: 0.59,
          flex: 1,
          marginRight: 20,
          //width:"100%",
          flexDirection: "column",
          alignItems: "center",
        }
      : {
          ...arrayornot,
          justifyContent: "center",
          flex: 1,
        };
    return arrayornot;
  };

  const arraystyle1 = (array) => {
    console.log(array);
    console.log(array[0]);
    let firstarray = [...array[0]];
    let finalarray;
    let num = 1;

    //display all arraystyle1 with this arrayobject
    let arrayobject = [];
    array[0].forEach((element) => {
      arrayobject.push({ Profile_image: element });
    });
    array[1].forEach((element, index) => {
      arrayobject[index] = { ...arrayobject[index], Rank: element };
    });
    array[2]?.forEach((element, index) => {
      arrayobject[index] = { ...arrayobject[index], Name: element };
    });

    console.log(arrayobject);

    array[1].forEach((element) => {
      firstarray.splice(num, 0, element);

      num = num + 2;
    });

    if (array[2]) {
      num = 2;
      array[2].forEach((element) => {
        firstarray.splice(num, 0, element);

        num = num + 3;
      });
      let arrayobject = [{}];
    }

    //if dont need the ranks then you might not even need this function just map it in the dom
    console.log(firstarray);
    return firstarray;
  };

  const Fullscreencancel = () => {
    /* setFullscreened(false);
    setControlon(false);
    document.exitFullscreen();

    setShowtime(true);*/
  };

  const fullscreenside = (e) => {
    let clickcount = Clicker();
    if (clickcount === "Once") {
      console.log("YOONE");

      if (!controlon) {
        setControlon(true);
        setVideolocation(3);

        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[1]
              .currentTime
          )
        );

        setTimeout(() => {
          setVideolocation(4);
        }, 100);
      } else {
        setControlon(false);
      }
    }

    if (clickcount === "Twice") {
      console.log(e.target.id.split(","));
      let tempid = e.target.id.split(",");
      let matchtarget = tempfiles.findIndex(
        (element) => tempid[0] === element.id
      );
      console.log(matchtarget);
      console.log(tempid[1]);
      console.log("YOTWO");
      console.log("durationaction");

      let timeadjustnumber;
      if (tempid[1] === "left") {
        timeadjustnumber = 5;
      } else {
        timeadjustnumber = -5;
      }

      if (!controlon) {
        ref3.current[
          matchtarget
        ].children[0].children[0].children[1].currentTime =
          Math.floor(
            ref3.current[matchtarget].children[0].children[0].children[1]
              .currentTime
          ) - timeadjustnumber;
        console.log("first");
      } else {
        ref3.current[
          matchtarget
        ].children[0].children[0].children[4].currentTime =
          Math.floor(
            ref3.current[matchtarget].children[0].children[0].children[4]
              .currentTime
          ) - timeadjustnumber;
        console.log("two");
      }
    }
  };

  const fileedit = async (e) => {
    for (let step = 0; step < e.target.files.length; step++) {
      if (
        !e.target.files[step]?.type.includes("video") &&
        !e.target.files[step]?.type.includes("image")
      ) {
        console.log(e.target.files[step]);
        alert("ファイルは動画か写真にしましょう");
        return (e.target.value = "");
      }
    }
    console.log("This better not run");
    //.type.includes("video")
    if (tempfiles.length + e.target.files.length < 5) {
      let filenumber = 0;
      let finaltempfiles = [];
      let promises = [];

      for (let step = 0; step < e.target.files.length; step++) {
        promises.push(
          await new Promise((resolve, reject) => {
            let fileurl = window.URL.createObjectURL(
              e.target.files[filenumber]
            );
            console.log(fileurl);
            finaltempfiles.push({
              file: fileurl,
              id: e.target.files[filenumber].name + new Date().getTime(),
              realfile: e.target.files[filenumber],
            });

            if (e.target.files[filenumber].type.includes("video")) {
              let videotime = document.createElement("video");
              videotime.muted = true;

              console.log(videotime);

              let videotimesource = document.createElement("source");
              videotimesource.src = fileurl;
              videotime.preload = "metadata";
              videotime.appendChild(videotimesource);

              videotime.onloadedmetadata = () => {
                console.log(Math.floor(videotime.duration));
                console.log(twominutevideo);
                if (
                  videotime.duration > 120 ||
                  twominutevideo + Math.floor(videotime.duration) > 120
                ) {
                  URL.revokeObjectURL(fileurl);
                  alert(
                    "動画は２分以内でなければいけません、２分以上の動画はE-Stationに投稿しましょう"
                  );
                  e.target.value = "";
                  reject("over");
                } else {
                  console.log(finaltempfiles);
                  filenumber++;
                  setTwominutevideo(
                    (prevvalue) => prevvalue + Math.floor(videotime.duration)
                  );

                  resolve();
                }
              };
            } else {
              console.log(finaltempfiles);
              filenumber++;
              resolve();
            }
          })
        );
      }
      try {
        await promises[0];
        await promises[1];
        await promises[2];
        await promises[3];

        setTempfiles([...tempfiles, ...finaltempfiles]);

        setTimeout(() => {
          e.target.value = "";
          if (tempfiles.length >= 1) {
            setShowtime(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("ファイルは４つまでです");
      e.target.value = "";
    }
  };

  const filedelete = (e) => {
    console.log(twominutevideo);
    if (e.target.id === tempfiles[0].id) {
      if (tempfiles.length === 1) {
        setTempfiles([]);
      } else {
        setTempfiles([...tempfiles.slice(1)]);
      }
      URL.revokeObjectURL(tempfiles[0].file);
      if (tempfiles[0].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) =>
            prevvalue -
            Math.floor(
              ref3.current[0].children[0].children[0].children[0].duration
            )
        );
        setShowtime(true);
      }
    } else {
      let deletetarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      setTimeout(() => {
        setTempfiles([
          ...tempfiles.slice(0, deletetarget),
          ...tempfiles.slice(deletetarget + 1),
        ]);
      });
      URL.revokeObjectURL(tempfiles[deletetarget].file);
      if (tempfiles[deletetarget].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) =>
            prevvalue -
            Math.floor(
              ref3.current[deletetarget].children[0].children[0].children[0]
                .duration
            )
        );
        setShowtime(true);
      }
    }
  };

  const fullscreen = (e) => {
    if (!fullscreened) {
      console.log(e.target.id);
      console.log(e.detail);

      let matchtarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      console.log(matchtarget);
      setFullscreened(true);
      ref3.current[matchtarget].requestFullscreen();

      if (
        ref3.current[matchtarget].children[0].children[0].children[0].paused
      ) {
        setPaused(true);
      } else {
        setPaused(false);
      }
    } else if (!controlon) {
      setControlon(true);

      setVideotime(
        Math.floor(
          document.fullscreenElement.children[0].children[0].children[1]
            .currentTime
        )
      );

      setVideolocation(3);

      setShowtime(true);
    } else {
      setControlon(false);
    }
  };

  const Videotimechange = (e) => {
    console.log(e.target.value);
    let matchtarget = tempfiles.findIndex(
      (element) => e.target.id === element.id
    );

    let timeadjust = parseInt(e.target.value) - videotime;

    setVideotime(parseInt(e.target.value));
    setTimeout(() => {
      ref3.current[
        matchtarget
      ].children[0].children[0].children[4].currentTime =
        Math.floor(
          ref3.current[matchtarget].children[0].children[0].children[4]
            .currentTime
        ) + timeadjust;
    }, 100);
    setVideolocation(4);
    setShowtime(true);
  };

  return (
    <div>
      {/*<div className="bordercolorchange"style={{border:"1.5px solid grey", width:60,borderRadius:8}}>spinner</div>*/}
      <div
        style={{
          width: "100%",
          height: 56,
          display: "block",
          background: "#005280",
          zIndex: 1,
        }}
      ></div>
      {/*<img
        style={{ zIndex: 2, position: "fixed", top: 15, left: 4 }}
        src="/Arrow1.svg"
        onClick={backurl1}
      />*/}

      {
        /*notificationon*/ !notificationtype /*(alldisplay.length === 0 && !oneoffdisplay)*/ && (
          <>
            {notifications.map((notification, index) => (
              <div
                style={{
                  height: "max-content",
                  display: "flex",
                  alignItems: notification.Type !== "Redrop" && "center",
                  background: "linear-gradient(#63f0f5, #cecaf0)",
                }}
                key={notification.id}
                className="drops"
                ref={
                  alldisplay[0]?.notificationid === notification.id
                    ? scrollref
                    : null
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingRight: 3,
                    borderRight: "4px solid skyblue",
                  }}
                >
                  {!Array.isArray(notification.Profile_image) ? (
                    <>
                      <Link to={`/UserView/${notification.Sender_id}`}>
                        <img
                          style={{ marginLeft: 5, marginTop: 3 }}
                          src={notification.Profile_image}
                          className={
                            notification.Profile_image === "/Profile3.svg"
                              ? "current3"
                              : "current1"
                          }
                        />
                      </Link>

                      <span
                        className="stylerank"
                        style={{
                          marginLeft: 1,
                          fontSize: 11,
                          marginTop: -3,

                          flex: notification.Comment?.length >= 25 ? 0.18 : 0.5,
                        }}
                      >
                        {notification.Rank}
                      </span>
                      <span
                        style={{
                          fontSize:
                            (notification?.Sender_name
                              ? notification?.Sender_name
                              : notification?.Name
                            )?.length < 12
                              ? 9
                              : 7,
                          flex: 1,
                        }}
                      >
                        {
                          //maybe followed name here if its a follow notification
                          notification.Sender_name
                            ? notification.Sender_name
                            : notification.Name && notification.Name
                        }
                      </span>
                    </>
                  ) : //prolly use ? ternary to display redropped mark or follow mark

                  notification.Redropper_id?.length > 0 ? (
                    <img
                      style={{
                        width: 48,
                        paddingTop: 14,
                        paddingBottom: 10,
                        marginLeft: 1,
                      }}
                      className="Redrop"
                      src="./Redrop.svg"
                    />
                  ) : notification.Directory?.length > 0 ? (
                    <img
                      style={{
                        width: 48,
                        paddingTop: 10,
                        paddingBottom: 10 /*paddingLeft:1.5*/,
                      }}
                      className="Profile2"
                      src="/Profile4.svg"
                    />
                  ) : //you plugin the Pledge subscribe stipulation && pledge icon image here
                  null}
                </div>

                {notification.Comment ? (
                  <div
                    style={{
                      width: "80%",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    {notification.Comment}
                  </div>
                ) : notification.Type === "Redrop" ? (
                  <>
                    <div style={arraystyle(notification.Profile_image)}>
                      {!Array.isArray(notification.Profile_image) ? (
                        <>
                          <img
                            style={{
                              marginRight: 1,
                              paddingBottom: 8,
                              borderBottom: "1px solid white",
                              borderBottomStyle: "groove",
                            }}
                            className="Redrop"
                            src="./Redrop.svg"
                          />
                          <div
                            style={{
                              fontSize: 12,
                              marginRight: 1,
                              marginTop: 7 /*marginLeft: 7*/,
                            }}
                          >
                            {notification.text}
                            {
                              //images and videos
                            }
                          </div>
                        </>
                      ) : (
                        <>
                          {
                            //deal with when notification text overflows then use ...?
                            //go back to useeffect
                          }
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 18,
                              //maybe change the flex : 1 in the arraystyle function instead of marginRight
                            }}
                          >
                            {
                              <div style={{ zIndex: 1, display: "flex" }}>
                                {arraystyle1([
                                  notification.Profile_image,
                                  notification.Rank,
                                ]).map((element, secondindex) => (
                                  <div key={secondindex}>
                                    {typeof element === "string" ? (
                                      <Link
                                        to={`/UserView/${
                                          notification.Redropper_id[
                                            0 + secondindex / 2
                                          ]
                                        }`}
                                      >
                                        <img
                                          style={{
                                            border:
                                              "1.5px solid rgb(84, 188, 226)",
                                            borderRadius: "50%",
                                            width: 30,
                                            height: 30,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          src={element}
                                        />
                                      </Link>
                                    ) : (
                                      <div
                                        style={{
                                          fontSize: 8,
                                          color: "MediumSlateBlue",
                                        }}
                                      >
                                        {element}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            }

                            {/*notification.Profile_image[1]*/}
                            {/*notification.Rank[1]*/}
                            {/*notification.Name[1]*/}
                            <div style={{ fontSize: 12, marginTop: 7 }}>
                              {notification.text}
                              {
                                //images and videos
                              }
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : notification.Type === "Follow" ? (
                  <>
                    {
                      //put follow stipulation
                    }

                    {
                      //<div style={arraystyle(notification.Profile_image)}>
                    }

                    {!Array.isArray(notification.Profile_image) ? (
                      <div
                        style={{
                          width: "80%",
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={() => displayfollow(notification)}
                      >
                        <img
                          style={{ width: 40 }}
                          className="Profile2"
                          src="/Profile4.svg"
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 18,
                            width: "100%",
                            //maybe change the flex : 1 in the arraystyle function instead of marginRight
                          }}
                        >
                          {
                            <div style={{ zIndex: 1, display: "flex" }}>
                              {arraystyle1([
                                notification.Profile_image,
                                notification.Rank,
                              ]).map((element, secondindex) => (
                                <div key={secondindex}>
                                  {typeof element === "string" ? (
                                    <Link
                                      to={`/UserView/${
                                        notification.Directory[
                                          0 + secondindex / 2
                                        ]
                                      }`}
                                    >
                                      <img
                                        style={{
                                          border:
                                            "1.5px solid rgb(84, 188, 226)",
                                          borderRadius: "50%",
                                          width: 30,
                                          height: 30,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        src={element}
                                      />
                                    </Link>
                                  ) : (
                                    <div
                                      style={{
                                        fontSize: 8,
                                        color: "MediumSlateBlue",
                                      }}
                                    >
                                      {element}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          }

                          {
                            //images and videos
                          }
                        </div>
                      </>
                    )}
                    {
                      //</div>
                    }
                  </>
                ) : //pledge stipulation here
                null}
                <div
                  style={{ borderLeft: "none" }}
                  className="alldrops"
                  onClick={
                    notification.Type === "Comment"
                      ? () => displaycomment(notification)
                      : notification.Type === "Follow"
                      ? () => displayfollow(notification)
                      : () => displayredrop(notification)
                    //pledge and other notification here
                  }
                  /* id={notification
                  [
                  notification.id,
                  notification.Sender_id,
                  notification.Directory,
                  notification.Loadtype,
                  notification.Comment_Directory,
                  notification.rootid,
                  notification.Sent_id,
                  notification.root_user,
                  notification.Nested_Directory,
                  notification.Nested_user,
                ]}*/
                ></div>
              </div>
            ))}
          </>
        )
      }

      {notificationtype && (
        <img
          src="/Arrow1.svg"
          className="arrowback"
          style={{ position: "absolute", top: 16, left: 7, zIndex: 1 }}
          onClick={
            //(notificationtype === "Comment" && alldisplay.length <= 1) ||
            notificationtype === "Follow" //||
              ? //(notificationtype === "Redrop" && alldisplay.length <= 1)
                backed
              : clickedcomment
              ? commentcancel
              : alldisplay.length >= 1 && !clickedcomment && displayback
          }
        />
      )}

      {/*!notificationon*/
      /*we need to add in case of follow display,pledge display
which probably needs to be its on state 
 /*(alldisplay.length === 1 || oneoffdisplay)
       ((notificationtype=== "Comment" && alldisplay.length <= 1) || notificationtype === "Follow" || (notificationtype === "Redrop" && alldisplay.length <= 1)) && (
          <img src="/Arrow1.svg" className="arrowback" onClick={backed} />
        )
      */}

      {/*clickedcomment && (
        <img className="arrowback" src="/Arrow1.svg" onClick={commentcancel} />
      )*/}

      {/*alldisplay.length > 1 && !clickedcomment && (
        <img src="/Arrow1.svg" className="arrowback" onClick={displayback} />
      )*/}

      {
        /*alldisplay.length > 0*/ (notificationtype === "Comment" ||
          notificationtype === "Redrop") &&
          !clickedcomment &&
          (alldisplay.at(-1)?.Deleted ? (
            <div className="drops">
              {" "}
              {/*<span className="textline" style={{justifyContent:"center"}}>*/}
              <div
                ref={(droppedvideoref.current[0] = null)}
                style={{ marginTop: 92, textAlign: "center", fontSize: 12 }}
              >
                このコメントは消されているか表示できないです
              </div>
              {/*</span>*/}
            </div>
          ) : (
            <>
              <div
                className="drops"
                id={alldisplay.at(-1).id}
                style={{
                  height:
                    (alldisplay.at(-1).image2 && 310) ||
                    ((alldisplay.at(-1).image1 ||
                      (alldisplay.at(-1).file1?.path !== "" &&
                        alldisplay.at(-1).file1?.path !== undefined)) &&
                      330),
                }}
              >
                <div className="postlinetop">
                  <Link
                    to={`/UserView/${
                      alldisplay.at(-1).Sender_id
                        ? alldisplay.at(-1).Sender_id
                        : alldisplay.at(-1).Directory
                    }`}
                  >
                    <img
                      className={
                        alldisplay.at(-1).Profile_image === "/Profile3.svg"
                          ? "current3"
                          : "current1"
                      }
                      src={alldisplay.at(-1).Profile_image}
                    />
                  </Link>

                  {(alldisplay.at(-1).image1 ||
                    alldisplay.at(-1).file1?.path) && (
                    <div
                      style={{
                        fontSize:
                          (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                            ?.length >= 142 && 10.5,
                        position:
                          optionid[0] === alldisplay.at(-1).id && "absolute",
                        left:
                          optionid[0] === alldisplay.at(-1).id &&
                          (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                            ?.length >= 23
                            ? "20%"
                            : `${
                                49.5 -
                                (
                                  alldisplay.at(-1).text ||
                                  alldisplay.at(-1).Comment
                                )?.length
                              }%`,
                      }}
                      className={
                        (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                          ?.length >= 23
                          ? "text"
                          : "text1"
                      }
                    >
                      {alldisplay.at(-1).text || alldisplay.at(-1).Comment}
                    </div>
                  )}

                  {optionid[0] !== alldisplay.at(-1).id && (
                    <img
                      src="/DropOption.svg"
                      className="postoption"
                      id={[
                        alldisplay.at(-1).id,
                        alldisplay.at(-1).Sender_id
                          ? alldisplay.at(-1).Sender_id
                          : alldisplay.at(-1).Directory,
                      ]}
                      onClick={OptionTerminal}
                    />
                  )}

                  {!deleteid && optionid[0] === alldisplay.at(-1).id && (
                    <span className="Delete">
                      {(id === optionid[1] ||
                        id === alldisplay[0].Directory) && (
                        <button
                          id={alldisplay.at(-1).id}
                          onClick={(e) => setDeleteid(alldisplay.at(-1).id)}
                        >
                          Delete
                        </button>
                      )}

                      <button
                        onClick={(e) => {
                          setOptionId("");
                          setDeleteid("");
                        }}
                      >
                        Cancel
                      </button>
                    </span>
                  )}

                  {deleteid === alldisplay.at(-1).id && (
                    <span className="Delete">
                      <button
                        id={alldisplay.at(-1).id}
                        onClick={
                          alldisplay.length === 1
                            ? deletedrop
                            : (e) => deletecomment(2)
                        }
                      >
                        〇
                      </button>
                      <button onClick={(e) => setDeleteid("")}>X</button>
                    </span>
                  )}
                </div>
                <span className="textline">
                  <span
                    className="stylerank"
                    style={{
                      marginLeft:
                        alldisplay.at(-1).Rank <= 9
                          ? 19.4
                          : alldisplay.at(-1).Rank === 11
                          ? 16
                          : alldisplay.at(-1).Rank <= 99
                          ? 14.5
                          : alldisplay.at(-1).Rank > 99 && 10,
                      flex:
                        (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                          ?.length >= 25
                          ? 0.18
                          : (
                              alldisplay.at(-1).text ||
                              alldisplay.at(-1).Comment
                            )?.length === 20
                          ? 0.14
                          : 0.5,
                      marginTop:
                        (alldisplay.at(-1).text || alldisplay.at(-1).Comment)
                          ?.length > 65 &&
                        (alldisplay.at(-1).image1 ||
                          alldisplay.at(-1).file1?.path)
                          ? -25
                          : null,
                    }}
                  >
                    {alldisplay.at(-1).Rank}
                  </span>
                  {!alldisplay.at(-1).image1 &&
                    !alldisplay.at(-1).file1?.path && (
                      <div
                        className={
                          alldisplay.at(-1).text?.length >= 23 ||
                          alldisplay.at(-1).Comment?.length >= 23
                            ? "text"
                            : "text1"
                        }
                      >
                        {alldisplay.at(-1)?.text || alldisplay.at(-1)?.Comment}
                      </div>
                    )}
                </span>
                <div
                  className="alldrops"
                  /*id={[
                        alldisplay.at(-1).id,
                        alldisplay.at(-1).Directory,
                        alldisplay.at(-1).Comment_Counter,
                      ]}*/
                  id={alldisplay[0].id}
                  onClick={
                    alldisplay.length === 1
                      ? (e) => display(alldisplay[0], e)
                      : undefined
                  }
                  style={{
                    height:
                      (alldisplay.at(-1).image2 && 275) ||
                      ((alldisplay.at(-1).image1 ||
                        alldisplay.at(-1).file1?.path) &&
                        295),
                  }}
                ></div>

                <div
                  style={{
                    marginLeft:
                      !alldisplay.at(-1).file3?.path &&
                      alldisplay.at(-1).file2?.path
                        ? 40
                        : alldisplay.at(-1).file3?.path && 60,
                    marginTop: alldisplay.at(-1).file3?.path && -10,
                    justifyContent:
                      alldisplay.at(-1).file3?.path &&
                      !alldisplay.at(-1).file4?.path &&
                      "flex-start",
                  }}
                  className="postphotoline"
                >
                  {alldisplay.at(-1).file1?.filetype === "videos" ? (
                    <>
                      <video
                        controls={!alldisplay.at(-1).file2.path && true}
                        width={
                          fullscreened
                            ? "100%"
                            : alldisplay.at(-1).file2.path === ""
                            ? 200
                            : alldisplay.at(-1).file4.path !== ""
                            ? 130
                            : 150
                        }
                        height={
                          fullscreened
                            ? "40%"
                            : alldisplay.at(-1).file2.path === ""
                            ? 200
                            : alldisplay.at(-1).file4?.path !== ""
                            ? 95
                            : alldisplay.at(-1).file3.path !== ""
                            ? 195
                            : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[0] = element)
                        }
                        onTimeUpdate={Videotimeupdate}
                        onEnded={
                          alldisplay.at(-1).file2.path
                            ? (e) => Droppedvideotimeupdate(e, 0)
                            : undefined
                        }
                        playsInline
                        muted
                        loop={!alldisplay.at(-1).file2.path && true}
                        // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                        id={alldisplay.at(-1).file1.id}
                        style={videohtml(
                          alldisplay.at(-1)?.file2,
                          alldisplay.at(-1).file3,
                          alldisplay.at(-1).file4
                        )}
                      >
                        <source
                          type="video/mp4"
                          src={alldisplay.at(-1).file1?.path}
                        />
                      </video>
                      {!isNaN(
                        Array.isArray(droppedvideoref.current[0])
                          ? droppedvideoref.current[0][0]?.duration
                          : droppedvideoref.current[0]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: !alldisplay.at(-1).file2.filetype
                              ? "18%"
                              : !alldisplay.at(-1).file3.filetype
                              ? "27%"
                              : alldisplay.at(-1).file4.filetype
                              ? "46%"
                              : "16%",
                            left:
                              !alldisplay.at(-1).file2.filetype ||
                              alldisplay.at(-1).file4.filetype
                                ? "25%"
                                : !alldisplay.at(-1).file3.filetype
                                ? "17%"
                                : "23%",
                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              (Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]
                                : droppedvideoref.current[0]
                              )?.currentTime / 60
                            )}
                          :
                          {(Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.currentTime < 10 && 0}
                          {(Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.currentTime >= 60
                            ? Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              (Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]
                                : droppedvideoref.current[0]
                              )?.duration / 60
                            )}
                          :
                          {(Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.duration < 10 && 0}
                          {(Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.duration >= 60
                            ? Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.duration % 60
                              )
                            : Math.floor(
                                (Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]
                                  : droppedvideoref.current[0]
                                )?.duration
                              )}
                        </span>
                      )}
                    </>
                  ) : (
                    alldisplay.at(-1).file1?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={alldisplay.at(-1).file1?.path}
                        id={alldisplay.at(-1).file1?.id}
                        style={imagehtml(
                          alldisplay.at(-1).file2,
                          alldisplay.at(-1).file3,
                          alldisplay.at(-1).file4
                        )}
                      />
                    )
                  )}

                  {!alldisplay.at(-1).file4?.path ? (
                    <div
                      style={{
                        display:
                          alldisplay.at(-1).file3?.path &&
                          !alldisplay.at(-1).file4?.path &&
                          "flex",
                        flexDirection:
                          alldisplay.at(-1).file3?.path &&
                          !alldisplay.at(-1).file4?.path &&
                          "column",
                      }}
                    >
                      {alldisplay.at(-1).file2?.filetype === "videos" ? (
                        <>
                          {console.log(droppedvideoref.current)}
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : alldisplay.at(-1).file3?.path
                                ? 110
                                : 150
                            }
                            height={
                              fullscreened
                                ? "40%"
                                : alldisplay.at(-1).file3?.path
                                ? 95
                                : 150
                            }
                            ref={(element) =>
                              (droppedvideoref.current[0] =
                                alldisplay.at(-1).file1.filetype === "videos"
                                  ? [droppedvideoref.current[0], element]
                                  : element)
                            }
                            playsInline
                            muted
                            id={alldisplay.at(-1).file2.id}
                            style={videohtml(alldisplay.at(-1).file1)}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                          >
                            <source
                              type="video/mp4"
                              src={alldisplay.at(-1).file2?.path}
                            />
                          </video>

                          {droppedvideoref?.current[0] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]?.duration
                                : droppedvideoref.current[0]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: !alldisplay.at(-1).file3.filetype
                                    ? "27%"
                                    : "46%",
                                  left: !alldisplay.at(-1).file3.filetype
                                    ? "59%"
                                    : "65%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (alldisplay.at(-1).file1.filetype ===
                                    "videos"
                                      ? droppedvideoref?.current[0][1]
                                      : Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                      ? droppedvideoref?.current[0][0]
                                      : droppedvideoref?.current[0]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime < 10 && 0}
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (alldisplay.at(-1).file1.filetype ===
                                    "videos"
                                      ? droppedvideoref?.current[0][1]
                                      : Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                      ? droppedvideoref?.current[0][0]
                                      : droppedvideoref?.current[0]
                                    )?.duration / 60
                                  )}
                                :
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration < 10 && 0}
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        alldisplay.at(-1).file2?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={alldisplay.at(-1).file2?.path}
                            id={alldisplay.at(-1).file2?.id}
                            style={
                              alldisplay.at(-1).file3?.path
                                ? {
                                    ...imagehtml(alldisplay.at(-1).file1),
                                    width: 110,
                                    height: alldisplay.at(-1).file3.path && 95,
                                  }
                                : imagehtml(alldisplay.at(-1).file1)
                            }
                          />
                        )
                      )}

                      {alldisplay.at(-1).file3?.filetype === "videos" ? (
                        <>
                          <video
                            width={fullscreened ? "100%" : 110}
                            height={fullscreened ? "40%" : 95}
                            ref={(element) =>
                              (droppedvideoref.current[0] =
                                alldisplay.at(-1).file1.filetype === "videos" &&
                                alldisplay.at(-1).file2.filetype === "videos"
                                  ? [
                                      droppedvideoref.current[0][0],
                                      droppedvideoref.current[0][1],
                                      element,
                                    ]
                                  : alldisplay.at(-1).file1.filetype !==
                                      "videos" &&
                                    alldisplay.at(-1).file2.filetype !==
                                      "videos"
                                  ? element
                                  : alldisplay.at(-1).file1.filetype !==
                                      "videos" ||
                                    alldisplay.at(-1).file2.filetype !==
                                      "videos"
                                  ? [droppedvideoref?.current[0], element]
                                  : [])
                            }
                            playsInline
                            muted
                            id={alldisplay.at(-1).file3.id}
                            style={{
                              ...videohtml(alldisplay.at(-1).file1),
                              marginTop: 4,
                            }}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                          >
                            <source
                              type="video/mp4"
                              src={alldisplay.at(-1).file3?.path}
                            />
                          </video>

                          {droppedvideoref?.current[0] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]?.duration
                                : droppedvideoref.current[0]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "16%",
                                  left: "65%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file4.filetype,
                                      0
                                    )?.currentTime / 60
                                  )}
                                :
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.currentTime < 10 && 0}
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file4.filetype,
                                      0
                                    )?.duration / 60
                                  )}
                                :
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.duration < 10 && 0}
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.duration >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        alldisplay.at(-1).file3?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={alldisplay.at(-1).file3?.path}
                            id={alldisplay.at(-1).file3?.id}
                            style={{
                              ...imagehtml(alldisplay.at(-1).file1),
                              width: !alldisplay.at(-1).file4?.path ? 110 : 130,
                              height: 95,
                              marginTop: 4,
                            }}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    <>
                      {alldisplay.at(-1).file2?.filetype === "videos" ? (
                        <>
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : alldisplay.at(-1).file4.path !== ""
                                ? 130
                                : alldisplay.at(-1).file3.path
                                ? 110
                                : 150
                            }
                            height={
                              fullscreened
                                ? "40%"
                                : alldisplay.at(-1).file3.path
                                ? 95
                                : 150
                            }
                            ref={(element) =>
                              (droppedvideoref.current[0] =
                                alldisplay.at(-1).file1.filetype === "videos"
                                  ? [droppedvideoref.current[0], element]
                                  : element)
                            }
                            playsInline
                            muted
                            id={alldisplay.at(-1).file2.id}
                            style={videohtml(alldisplay.at(-1).file1)}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                          >
                            <source
                              type="video/mp4"
                              src={alldisplay.at(-1).file2?.path}
                            />
                          </video>

                          {droppedvideoref?.current[0] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]?.duration
                                : droppedvideoref.current[0]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "46%",
                                  left: "62%",

                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (alldisplay.at(-1).file1.filetype ===
                                    "videos"
                                      ? droppedvideoref?.current[0][1]
                                      : Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                      ? droppedvideoref?.current[0][0]
                                      : droppedvideoref?.current[0]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime < 10 && 0}
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (alldisplay.at(-1).file1.filetype ===
                                    "videos"
                                      ? droppedvideoref?.current[0][1]
                                      : Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                      ? droppedvideoref?.current[0][0]
                                      : droppedvideoref?.current[0]
                                    )?.duration / 60
                                  )}
                                :
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration < 10 && 0}
                                {(alldisplay.at(-1).file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (alldisplay.at(-1).file1.filetype ===
                                      "videos"
                                        ? droppedvideoref?.current[0][1]
                                        : Array.isArray(
                                            droppedvideoref.current[0]
                                          )
                                        ? droppedvideoref?.current[0][0]
                                        : droppedvideoref?.current[0]
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        alldisplay.at(-1).file2?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={alldisplay.at(-1).file2?.path}
                            id={alldisplay.at(-1).file2?.id}
                            style={
                              alldisplay.at(-1).file3?.path
                                ? {
                                    ...imagehtml(alldisplay.at(-1).file1),
                                    width:
                                      (alldisplay.at(-1).file4?.path && 130) ||
                                      (alldisplay.at(-1).file3.path && 110),
                                    height: alldisplay.at(-1).file3.path && 95,
                                  }
                                : imagehtml(alldisplay.at(-1).file1)
                            }
                          />
                        )
                      )}
                      {alldisplay.at(-1).file3?.filetype === "videos" ? (
                        <>
                          <video
                            width={
                              fullscreened
                                ? "100%"
                                : alldisplay.at(-1).file4.path !== ""
                                ? 130
                                : 110
                            }
                            height={fullscreened ? "40%" : 95}
                            ref={(element) =>
                              (droppedvideoref.current[0] =
                                alldisplay.at(-1).file1.filetype === "videos" &&
                                alldisplay.at(-1).file2.filetype === "videos"
                                  ? [
                                      droppedvideoref.current[0][0],
                                      droppedvideoref.current[0][1],
                                      element,
                                    ]
                                  : alldisplay.at(-1).file1.filetype !==
                                      "videos" &&
                                    alldisplay.at(-1).file2.filetype !==
                                      "videos"
                                  ? element
                                  : alldisplay.at(-1).file1.filetype !==
                                      "videos" ||
                                    alldisplay.at(-1).file2.filetype !==
                                      "videos"
                                  ? [droppedvideoref?.current[0], element]
                                  : [])
                            }
                            playsInline
                            muted
                            id={alldisplay.at(-1).file3.id}
                            style={{
                              ...videohtml(alldisplay.at(-1).file1),

                              marginTop: 4,
                            }}
                            onTimeUpdate={Videotimeupdate}
                            onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                          >
                            <source
                              type="video/mp4"
                              src={alldisplay.at(-1).file3?.path}
                            />
                          </video>

                          {droppedvideoref?.current[0] !== null &&
                            !isNaN(
                              Array.isArray(droppedvideoref.current[0])
                                ? droppedvideoref.current[0][0]?.duration
                                : droppedvideoref.current[0]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: "16%",
                                  left: "26%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file4.filetype,
                                      0
                                    )?.currentTime / 60
                                  )}
                                :
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.currentTime < 10 && 0}
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    determinevideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file4.filetype,
                                      0
                                    )?.duration / 60
                                  )}
                                :
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.duration < 10 && 0}
                                {determinevideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file4.filetype,
                                  0
                                )?.duration >= 60
                                  ? Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      determinevideoindex(
                                        alldisplay.at(-1).file1.filetype,
                                        alldisplay.at(-1).file2.filetype,
                                        alldisplay.at(-1).file4.filetype,
                                        0
                                      )?.duration
                                    )}
                              </span>
                            )}
                        </>
                      ) : (
                        alldisplay.at(-1).file3?.filetype === "images" && (
                          <img
                            className="postphoto"
                            src={alldisplay.at(-1).file3?.path}
                            id={alldisplay.at(-1).file3?.id}
                            style={{
                              ...imagehtml(alldisplay.at(-1).file1),
                              width: !alldisplay.at(-1).file4?.path ? 110 : 130,
                              height: 95,
                              marginTop: 4,
                            }}
                          />
                        )
                      )}
                    </>
                  )}

                  {alldisplay.at(-1).file4?.filetype === "videos" ? (
                    <>
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : alldisplay.at(-1).file4.path !== ""
                            ? 130
                            : 110
                        }
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[0] =
                            alldisplay.at(-1).file1.filetype === "videos" &&
                            alldisplay.at(-1).file2.filetype === "videos" &&
                            alldisplay.at(-1).file3.filetype === "videos"
                              ? [
                                  droppedvideoref.current[0][0],
                                  droppedvideoref.current[0][1],
                                  droppedvideoref.current[0][2],
                                  element,
                                ]
                              : alldisplay.at(-1).file1.filetype !== "videos" &&
                                alldisplay.at(-1).file2.filetype !== "videos" &&
                                alldisplay.at(-1).file3.filetype !== "videos"
                              ? element
                              : alldisplay.at(-1).file1.filetype !== "videos" ||
                                alldisplay.at(-1).file2.filetype !== "videos" ||
                                alldisplay.at(-1).file3.filetype !== "videos"
                              ? [
                                  ...determinevideoref(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  ),
                                  element,
                                ]
                              : [])
                        }
                        playsInline
                        muted
                        id={alldisplay.at(-1).file4.id}
                        style={{
                          ...videohtml(alldisplay.at(-1).file1),

                          marginTop: 4,
                        }}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                      >
                        <source
                          type="video/mp4"
                          src={alldisplay.at(-1).file4?.path}
                        />
                      </video>

                      {droppedvideoref?.current[0] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: "16%",
                              left: "61.5%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                determinefourthvideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file3.filetype,
                                  0
                                )?.currentTime / 60
                              )}
                            :
                            {determinefourthvideoindex(
                              alldisplay.at(-1).file1.filetype,
                              alldisplay.at(-1).file2.filetype,
                              alldisplay.at(-1).file3.filetype,
                              0
                            )?.currentTime < 10 && 0}
                            {determinefourthvideoindex(
                              alldisplay.at(-1).file1.filetype,
                              alldisplay.at(-1).file2.filetype,
                              alldisplay.at(-1).file3.filetype,
                              0
                            )?.currentTime >= 60
                              ? Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.currentTime
                                )}
                            <span style={{ marginLeft: 5, marginRight: 5 }}>
                              /
                            </span>
                            {0 +
                              Math.floor(
                                determinefourthvideoindex(
                                  alldisplay.at(-1).file1.filetype,
                                  alldisplay.at(-1).file2.filetype,
                                  alldisplay.at(-1).file3.filetype,
                                  0
                                )?.duration / 60
                              )}
                            :
                            {determinefourthvideoindex(
                              alldisplay.at(-1).file1.filetype,
                              alldisplay.at(-1).file2.filetype,
                              alldisplay.at(-1).file3.filetype,
                              0
                            )?.duration < 10 && 0}
                            {determinefourthvideoindex(
                              alldisplay.at(-1).file1.filetype,
                              alldisplay.at(-1).file2.filetype,
                              alldisplay.at(-1).file3.filetype,
                              0
                            )?.duration >= 60
                              ? Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  determinefourthvideoindex(
                                    alldisplay.at(-1).file1.filetype,
                                    alldisplay.at(-1).file2.filetype,
                                    alldisplay.at(-1).file3.filetype,
                                    0
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    alldisplay.at(-1).file4?.filetype === "images" && (
                      <img
                        src={alldisplay.at(-1).file4?.path}
                        className="postphoto"
                        id={alldisplay.at(-1).file4?.id}
                        style={{
                          ...imagehtml(alldisplay.at(-1).file1),
                          width: 130,
                          height: 95,
                          marginTop: 4,
                        }}
                      />
                    )
                  )}

                  {alldisplay.at(-1).image1 && (
                    <img
                      className="postphoto"
                      src={alldisplay.at(-1).image1}
                      style={{
                        width: alldisplay.at(-1).image2 && 150,
                        height: alldisplay.at(-1).image2 && 150,
                        marginLeft: alldisplay.at(-1).image2 && 50,
                        marginTop: alldisplay.at(-1).image2 && 20,
                      }}
                    />
                  )}
                </div>

                <div className="date">
                  {alldisplay.at(-1).year}/{alldisplay.at(-1).month}/
                  {alldisplay.at(-1).date}
                  <span className="Timesplit">|</span>
                  <span className="Time">
                    {alldisplay.at(-1).hour}:{alldisplay.at(-1).minutes}
                  </span>
                </div>
                <img
                  className="Rankmarked"
                  src="/RankMark.svg"
                  style={{
                    top:
                      (alldisplay.at(-1).image1 ||
                        alldisplay.at(-1).file1?.path) &&
                      51,
                  }}
                />
                <span
                  className="dropoption"
                  style={{ height: clickedcomment && 29 }}
                >
                  {
                    //!clickedcomment && !showdrop && (
                    <span className="commentgroup">
                      <img
                        className="commenttap"
                        src="./Comment1.svg"
                        id={[
                          alldisplay.at(-1).id,
                          alldisplay.at(-1).Sender_id
                            ? alldisplay.at(-1).Sender_id
                            : alldisplay.at(-1).Directory,
                          /* alldisplay.length === 1
                            ? alldisplay.at(-1).Directory
                            : alldisplay.at(-1).Sender_id,*/
                        ]}
                        onClick={comment}
                      />

                      <span className="commentcount">
                        {alldisplay.at(-1).Comment_Counter > 0 &&
                          alldisplay.at(-1).Comment_Counter}
                      </span>
                    </span>
                    //)
                  }

                  <span className="redropline">
                    {alldisplay.at(-1).Redropped === "done" &&
                      !clickedcomment && (
                        <img
                          src="./Redrop.svg"
                          className="Redrop"
                          //couldnt we combine the id for redrop and askunredrop
                          onClick={AskUnredrop}
                          id={[
                            alldisplay.at(-1).id,
                            alldisplay.length === 1
                              ? alldisplay.at(-1).Directory
                              : alldisplay.at(-1).Sender_id,
                          ]}
                        />
                      )}
                    {alldisplay.at(-1).Redropped !== "done" &&
                      !clickedcomment && (
                        <img
                          className="Redrop"
                          src="./PlaneRedrop4.svg"
                          id={[
                            alldisplay.at(-1).id,
                            alldisplay.length === 1
                              ? alldisplay.at(-1).Directory
                              : alldisplay.at(-1).Sender_id,
                          ]}
                          onClick={redrop}
                        />
                      )}
                    <span className="commentcount">
                      {alldisplay.at(-1).Redropped_Counter == 0 ||
                      clickedcomment
                        ? null
                        : alldisplay.at(-1).Redropped_Counter}
                    </span>
                  </span>
                </span>
                {redroppoint && alldisplay.at(-1).id === redropid[0] && (
                  <span
                    style={{ fontSize: 8, bottom: "5%" }}
                    className="Pselect"
                  >
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        color: "white",
                        bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                      }}
                      onClick={(e) => confirmredrop(alldisplay.at(-1))}
                    >
                      リドロップ
                    </button>
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        marginLeft: 85,
                        bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                      }}
                      onClick={(e) => {
                        setRedroppoint(false);
                      }}
                    >
                      キャンセル
                    </button>
                  </span>
                )}
                {unredroppoint && alldisplay.at(-1).id === unredropid[0] && (
                  <span
                    style={{ fontSize: 8, bottom: "5%" }}
                    className="Pselect"
                  >
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        color: "white",
                        bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                      }}
                      onClick={(e) =>
                        ConfirmUnredrop(alldisplay.at(-1).OriginalTimestamp)
                      }
                    >
                      リドロップ解除
                    </button>
                    <button
                      style={{
                        left: "30%",
                        fontSize: 9,
                        marginLeft: 85,
                        bottom: alldisplay.at(-1).file1?.path ? "3%" : "5%",
                      }}
                      onClick={CancelUnredrop}
                    >
                      キャンセル
                    </button>
                  </span>
                )}
                {blockid === alldisplay.at(-1).id && (
                  <div className="Blockposition">
                    <Block
                      style={{ width: "80%" }}
                      // style={{ zIndex: 8 }}
                      understandblocked={understandblocked}
                    />
                  </div>
                )}
              </div>

              {alldisplay.length === 1 && commentload && (
                <>
                  {
                    //commentload &&
                    commentload?.Deleted ? (
                      <div className="drops">
                        {" "}
                        {/*<span className="textline" style={{justifyContent:"center"}}>*/}
                        <div
                          style={{
                            marginTop: 92,
                            textAlign: "center",
                            fontSize: 12,
                          }}
                          // ref={(droppedvideoref.current[1] = null)}
                          ref={(element) =>
                            (droppedvideoref.current[1] = element)
                          }
                        >
                          このコメントは消されているか表示できないです
                        </div>
                        {/*</span>*/}
                      </div>
                    ) : (
                      <>
                        {/*
                    <div className="drops">
                      <div className="postlinetop">
                        <Link to={`/UserView/${commentload.Sender_id}`}>
                          <img
                            src={commentload.Profile_image}
                            className={
                              commentload.Profile_image === "/Profile3.svg"
                                ? "current3"
                                : "current1"
                            }
                          />
                        </Link>
                        {commentid[0] !== commentload.id && (
                          <img
                            src="/DropOption.svg"
                            className="postoption"
                            id={[commentload.id, commentload.Sender_id]}
                            onClick={OptionTerminal}
                          />
                        )}
                      </div>
                      {!askingdeletecomment && (
                        <>
                          {commentid[0] === commentload.id && (
                            <span className="Delete">
                              {(id === commentload.Sender_id ||
                                id === alldisplay[0].Directory) && (
                                <button
                                  id={commentload.id}
                                  onClick={askdeletecomment}
                                >
                                  Delete Comment
                                </button>
                              )}

                              <button onClick={(e) => setCommentId(false)}>
                                Cancel
                              </button>
                            </span>
                          )}
                        </>
                      )}
                      {deleteid === commentload.id && askingdeletecomment && (
                        <span className="Delete">
                          <button onClick={deletecomment}>いいですか？</button>
                          <button onClick={commentdeletecancel}>
                            キャンセル
                          </button>
                        </span>
                      )}

                      <span className="textline">
                        <span
                          className="stylerank"
                          style={{
                            marginLeft:
                              commentload.Rank <= 10
                                ? 19.4
                                : commentload.Rank === 11
                                ? 16
                                : null,
                            flex:
                              commentload.Comment?.length >= 25
                                ? 0.18
                                : commentload.Comment?.length === 20
                                ? 0.14
                                : 0.5,
                          }}
                        >
                          {commentload.Rank}
                        </span>
                        <div
                          className={
                            commentload.Comment?.length >= 20 ? "text" : "text1"
                          }
                        >
                          {commentload.Comment}
                        </div>
                      </span>

                      <div
                        className="alldrops"
                       
                        //id={
                        //  commentload.id
                          //[
                           //commentload.Sender_id,
                          //commentload.Directory,
                          //commentload.Loadtype,
                        //]
                     // }
                       onClick={() => display(commentload)}
                      ></div>
                      <div className="date">
                        {commentload.year}/{commentload.month}/
                        {commentload.date}
                        <span className="Timesplit">|</span>
                        <span className="Time">
                          {commentload.hour}:{commentload.minutes}
                        </span>
                      </div>
                      <img className="Rankmarked" src="/RankMark.svg" />
                      <span className="dropoption">
                        <span className="commentgroup">
                          <img
                            className="commenttap"
                            src="./Comment1.svg"
                            id={[commentload.id, commentload.Sender_id]}
                            onClick={comment}
                          />
                          <span className="commentcount">
                            {commentload.Comment_Counter == 0
                              ? null
                              : commentload.Comment_Counter}
                          </span>
                        </span>
                        <span className="redropline">
                          {commentload.Redropped == "done" ? (
                            <>
                              <img
                                src="./Redrop.svg"
                                className="Redrop"
                                onClick={AskUnredrop}
                                id={[
                                  commentload.id,
                                  commentload.Sender_id,
                                  commentload.Redropped_Counter,
                                ]}
                              />
                            </>
                          ) : (
                            <img
                              className="Redrop"
                              src="./PlaneRedrop4.svg"
                              id={[
                                commentload.id,
                                commentload.Sender_id,
                                commentload.Rank,
                                commentload.Profile_image,
                                commentload.Comment,
                                commentload.Comment_Counter,
                                commentload.hour,
                                commentload.minutes,
                                commentload.year,
                                commentload.month,
                                commentload.date,
                                commentload.image1,
                                commentload.image2,
                                commentload.image3,
                                commentload.image4,
                                commentload.video,
                                commentload.Redropper_id,
                                commentload.Redropped_Counter,
                                commentload.Sent_id,
                                commentload.Directory,
                                commentload.This_Directory,
                              ]}
                              onClick={redrop}
                            />
                          )}
                          <span className="commentcount">
                            {commentload.Redropped_Counter == 0
                              ? null
                              : commentload.Redropped_Counter}
                          </span>
                        </span>
                      </span>
                      {redroppoint && commentload.id === redropid[0] && (
                        <span className="redropstyle">
                          <button onClick={confirmredrop}>
                            confirm redrop
                          </button>
                          <button
                            onClick={(e) => {
                              setRedroppoint(false);
                            }}
                          >
                            Cancel redrop
                          </button>
                        </span>
                      )}
                      {unredroppoint && commentload.id === unredropdid[0] && (
                        <span className="redropstyle">
                          <button onClick={ConfirmUnredrop}>Unredrop</button>
                          <button onClick={CancelUnredrop}>Cancel</button>
                        </span>
                      )}
                    </div>
                    */}

                        <div
                          className="drops"
                          id={commentload.id}
                          style={{
                            height:
                              //(alldisplay.at(-1).image2 && 310) ||
                              //alldisplay.at(-1).image1 ||
                              commentload.file1?.path !== "" &&
                              commentload.file1?.path !== undefined &&
                              330,
                          }}
                        >
                          <div className="postlinetop">
                            <Link to={`/UserView/${commentload.Sender_id}`}>
                              <img
                                className={
                                  commentload.Profile_image === "/Profile3.svg"
                                    ? "current3"
                                    : "current1"
                                }
                                src={commentload.Profile_image}
                              />
                            </Link>

                            {
                              //(alldisplay.at(-1).image1 ||
                              commentload.file1?.path /*)*/ && (
                                <div
                                  style={{
                                    fontSize:
                                      commentload.Comment?.length >= 142 &&
                                      10.5,
                                    position:
                                      optionid[0] === commentload.id &&
                                      "absolute",
                                    left:
                                      optionid[0] === commentload.id &&
                                      commentload.Comment?.length >= 23
                                        ? "20%"
                                        : `${
                                            49.5 - commentload.Comment?.length
                                          }%`,
                                  }}
                                  className={
                                    commentload.Comment?.length >= 23
                                      ? "text"
                                      : "text1"
                                  }
                                >
                                  {commentload.Comment}
                                </div>
                              )
                            }

                            {optionid[0] !== commentload.id && (
                              <img
                                src="/DropOption.svg"
                                className="postoption"
                                id={[commentload.id, commentload.Sender_id]}
                                onClick={OptionTerminal}
                              />
                            )}

                            {!deleteid && optionid[0] === commentload.id && (
                              <span className="Delete">
                                {(id === optionid[1] ||
                                  id === commentload.Sender_id) && (
                                  <button
                                    id={commentload.id}
                                    onClick={(e) => setDeleteid(commentload.id)}
                                  >
                                    Delete
                                  </button>
                                )}

                                <button
                                  onClick={(e) => {
                                    setOptionId("");
                                    setDeleteid("");
                                  }}
                                >
                                  Cancel
                                </button>
                              </span>
                            )}

                            {deleteid === commentload.id && (
                              <span className="Delete">
                                <button
                                  id={commentload.id}
                                  onClick={(e) => deletecomment(2)}
                                >
                                  〇
                                </button>
                                <button onClick={(e) => setDeleteid("")}>
                                  X
                                </button>
                              </span>
                            )}
                          </div>
                          <span className="textline">
                            <span
                              className="stylerank"
                              style={{
                                marginLeft:
                                  commentload.Rank <= 9
                                    ? 19.4
                                    : commentload.Rank === 11
                                    ? 16
                                    : commentload.Rank <= 99
                                    ? 14.5
                                    : commentload.Rank > 99 && 10,
                                flex:
                                  commentload.Comment?.length >= 25
                                    ? 0.18
                                    : commentload.Comment?.length === 20
                                    ? 0.14
                                    : 0.5,
                                marginTop:
                                  commentload.Comment?.length > 65 &&
                                  commentload.file1?.path
                                    ? -25
                                    : null,
                              }}
                            >
                              {commentload.Rank}
                            </span>
                            {!commentload.file1?.path && (
                              <div
                                className={
                                  commentload.Comment?.length >= 23
                                    ? "text"
                                    : "text1"
                                }
                              >
                                {commentload?.Comment}
                              </div>
                            )}
                          </span>
                          <div
                            className="alldrops"
                            /*id={[
                        alldisplay.at(-1).id,
                        alldisplay.at(-1).Directory,
                        alldisplay.at(-1).Comment_Counter,
                      ]}*/
                            id={commentload.id}
                            onClick={(e) => display(commentload)}
                            style={{
                              height: commentload.file1?.path && 295,
                            }}
                          ></div>
                          <div
                            style={{
                              marginLeft:
                                !commentload.file3?.path &&
                                commentload.file2?.path
                                  ? 40
                                  : commentload.file3?.path && 60,
                              marginTop: commentload.file3?.path && -10,
                              justifyContent:
                                commentload.file3?.path &&
                                !commentload.file4?.path &&
                                "flex-start",
                            }}
                            className="postphotoline"
                          >
                            {commentload.file1?.filetype === "videos" ? (
                              <>
                                <video
                                  controls={!commentload.file2.path && true}
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : commentload.file2.path === ""
                                      ? 200
                                      : commentload.file4.path !== ""
                                      ? 130
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : commentload.file2.path === ""
                                      ? 200
                                      : commentload.file4?.path !== ""
                                      ? 95
                                      : commentload.file3.path !== ""
                                      ? 195
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[1] = element)
                                  }
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={
                                    commentload.file2.path
                                      ? (e) => Droppedvideotimeupdate(e, 1)
                                      : undefined
                                  }
                                  playsInline
                                  muted
                                  loop={!commentload.file2.path && true}
                                  // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                                  id={commentload.file1.id}
                                  style={videohtml(
                                    commentload.file2,
                                    commentload.file3,
                                    commentload.file4
                                  )}
                                >
                                  <source
                                    type="video/mp4"
                                    src={commentload.file1?.path}
                                  />
                                </video>
                                {!isNaN(
                                  Array.isArray(droppedvideoref.current[1])
                                    ? droppedvideoref.current[1][0]?.duration
                                    : droppedvideoref.current[1]?.duration
                                ) && (
                                  <span
                                    //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                    style={{
                                      bottom: !commentload.file2.filetype
                                        ? "18%"
                                        : !commentload.file3.filetype
                                        ? "27%"
                                        : commentload.file4.filetype
                                        ? "46%"
                                        : "16%",
                                      left:
                                        !commentload.file2.filetype ||
                                        commentload.file4.filetype
                                          ? "25%"
                                          : !commentload.file3.filetype
                                          ? "17%"
                                          : "23%",
                                      zIndex: 1,
                                      position: "absolute",
                                    }}
                                    className="videoduration"
                                  >
                                    {0 +
                                      Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                          : droppedvideoref.current[1]
                                        )?.currentTime / 60
                                      )}
                                    :
                                    {(Array.isArray(droppedvideoref.current[1])
                                      ? droppedvideoref.current[1][0]
                                      : droppedvideoref.current[1]
                                    )?.currentTime < 10 && 0}
                                    {(Array.isArray(droppedvideoref.current[1])
                                      ? droppedvideoref.current[1][0]
                                      : droppedvideoref.current[1]
                                    )?.currentTime >= 60
                                      ? Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[1]
                                          )
                                            ? droppedvideoref.current[1][0]
                                            : droppedvideoref.current[1]
                                          )?.currentTime % 60
                                        )
                                      : Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[1]
                                          )
                                            ? droppedvideoref.current[1][0]
                                            : droppedvideoref.current[1]
                                          )?.currentTime
                                        )}
                                    <span
                                      style={{ marginLeft: 5, marginRight: 5 }}
                                    >
                                      /
                                    </span>
                                    {0 +
                                      Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                          : droppedvideoref.current[1]
                                        )?.duration / 60
                                      )}
                                    :
                                    {(Array.isArray(droppedvideoref.current[1])
                                      ? droppedvideoref.current[1][0]
                                      : droppedvideoref.current[1]
                                    )?.duration < 10 && 0}
                                    {(Array.isArray(droppedvideoref.current[1])
                                      ? droppedvideoref.current[1][0]
                                      : droppedvideoref.current[1]
                                    )?.duration >= 60
                                      ? Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[1]
                                          )
                                            ? droppedvideoref.current[1][0]
                                            : droppedvideoref.current[1]
                                          )?.duration % 60
                                        )
                                      : Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[1]
                                          )
                                            ? droppedvideoref.current[1][0]
                                            : droppedvideoref.current[1]
                                          )?.duration
                                        )}
                                  </span>
                                )}
                              </>
                            ) : (
                              commentload.file1?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={commentload.file1?.path}
                                  id={commentload.file1?.id}
                                  style={imagehtml(
                                    commentload.file2,
                                    commentload.file3,
                                    commentload.file4
                                  )}
                                />
                              )
                            )}

                            {!commentload.file4?.path ? (
                              <div
                                style={{
                                  display:
                                    commentload.file3?.path &&
                                    !commentload.file4?.path &&
                                    "flex",
                                  flexDirection:
                                    commentload.file3?.path &&
                                    !commentload.file4?.path &&
                                    "column",
                                }}
                              >
                                {commentload.file2?.filetype === "videos" ? (
                                  <>
                                    <video
                                      width={
                                        fullscreened
                                          ? "100%"
                                          : commentload.file3?.path
                                          ? 110
                                          : 150
                                      }
                                      height={
                                        fullscreened
                                          ? "40%"
                                          : commentload.file3?.path
                                          ? 95
                                          : 150
                                      }
                                      ref={(element) =>
                                        (droppedvideoref.current[1] =
                                          commentload.file1.filetype ===
                                          "videos"
                                            ? [
                                                droppedvideoref.current[1],
                                                element,
                                              ]
                                            : element)
                                      }
                                      playsInline
                                      muted
                                      id={commentload.file2.id}
                                      style={videohtml(commentload.file1)}
                                      onTimeUpdate={Videotimeupdate}
                                      onEnded={(e) =>
                                        Droppedvideotimeupdate(e, 1)
                                      }
                                    >
                                      <source
                                        type="video/mp4"
                                        src={commentload.file2?.path}
                                      />
                                    </video>

                                    {droppedvideoref?.current[0] !== null &&
                                      !isNaN(
                                        Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                              ?.duration
                                          : droppedvideoref.current[1]?.duration
                                      ) && (
                                        <span
                                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                          style={{
                                            bottom: !commentload.file3.filetype
                                              ? "27%"
                                              : "46%",
                                            left: !commentload.file3.filetype
                                              ? "59%"
                                              : "65%",
                                            zIndex: 1,
                                            position: "absolute",
                                          }}
                                          className="videoduration"
                                        >
                                          {0 +
                                            Math.floor(
                                              (commentload.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[1][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[1]
                                                  )
                                                ? droppedvideoref?.current[1][0]
                                                : droppedvideoref?.current[1]
                                              )?.currentTime / 60
                                            )}
                                          :
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.currentTime < 10 && 0}
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.currentTime >= 60
                                            ? Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.currentTime % 60
                                              )
                                            : Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.currentTime
                                              )}
                                          <span
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            /
                                          </span>
                                          {0 +
                                            Math.floor(
                                              (commentload.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[1][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[1]
                                                  )
                                                ? droppedvideoref?.current[1][0]
                                                : droppedvideoref?.current[1]
                                              )?.duration / 60
                                            )}
                                          :
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.duration < 10 && 0}
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.duration >= 60
                                            ? Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.duration % 60
                                              )
                                            : Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.duration
                                              )}
                                        </span>
                                      )}
                                  </>
                                ) : (
                                  commentload.file2?.filetype === "images" && (
                                    <img
                                      className="postphoto"
                                      src={commentload.file2?.path}
                                      id={commentload.file2?.id}
                                      style={
                                        commentload.file3?.path
                                          ? {
                                              ...imagehtml(commentload.file1),
                                              width: 110,
                                              height:
                                                commentload.file3.path && 95,
                                            }
                                          : imagehtml(commentload.file1)
                                      }
                                    />
                                  )
                                )}

                                {commentload.file3?.filetype === "videos" ? (
                                  <>
                                    <video
                                      width={fullscreened ? "100%" : 110}
                                      height={fullscreened ? "40%" : 95}
                                      ref={(element) =>
                                        (droppedvideoref.current[1] =
                                          commentload.file1.filetype ===
                                            "videos" &&
                                          commentload.file2.filetype ===
                                            "videos"
                                            ? [
                                                droppedvideoref.current[1][0],
                                                droppedvideoref.current[1][1],
                                                element,
                                              ]
                                            : commentload.file1.filetype !==
                                                "videos" &&
                                              commentload.file2.filetype !==
                                                "videos"
                                            ? element
                                            : commentload.file1.filetype !==
                                                "videos" ||
                                              commentload.file2.filetype !==
                                                "videos"
                                            ? [
                                                droppedvideoref?.current[1],
                                                element,
                                              ]
                                            : [])
                                      }
                                      playsInline
                                      muted
                                      id={commentload.file3.id}
                                      style={{
                                        ...videohtml(commentload.file1),
                                        marginTop: 4,
                                      }}
                                      onTimeUpdate={Videotimeupdate}
                                      onEnded={(e) =>
                                        Droppedvideotimeupdate(e, 1)
                                      }
                                    >
                                      <source
                                        type="video/mp4"
                                        src={commentload.file3?.path}
                                      />
                                    </video>

                                    {droppedvideoref?.current[1] !== null &&
                                      !isNaN(
                                        Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                              ?.duration
                                          : droppedvideoref.current[1]?.duration
                                      ) && (
                                        <span
                                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                          style={{
                                            bottom: "16%",
                                            left: "65%",
                                            zIndex: 1,
                                            position: "absolute",
                                          }}
                                          className="videoduration"
                                        >
                                          {0 +
                                            Math.floor(
                                              determinevideoindex(
                                                commentload.file1.filetype,
                                                commentload.file2.filetype,
                                                commentload.file4.filetype,
                                                0
                                              )?.currentTime / 60
                                            )}
                                          :
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.currentTime < 10 && 0}
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.currentTime >= 60
                                            ? Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.currentTime % 60
                                              )
                                            : Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.currentTime
                                              )}
                                          <span
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            /
                                          </span>
                                          {0 +
                                            Math.floor(
                                              determinevideoindex(
                                                commentload.file1.filetype,
                                                commentload.file2.filetype,
                                                commentload.file4.filetype,
                                                0
                                              )?.duration / 60
                                            )}
                                          :
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.duration < 10 && 0}
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.duration >= 60
                                            ? Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.duration % 60
                                              )
                                            : Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.duration
                                              )}
                                        </span>
                                      )}
                                  </>
                                ) : (
                                  commentload.file3?.filetype === "images" && (
                                    <img
                                      className="postphoto"
                                      src={commentload.file3?.path}
                                      id={commentload.file3?.id}
                                      style={{
                                        ...imagehtml(commentload.file1),
                                        width: !commentload.file4?.path
                                          ? 110
                                          : 130,
                                        height: 95,
                                        marginTop: 4,
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            ) : (
                              <>
                                {commentload.file2?.filetype === "videos" ? (
                                  <>
                                    <video
                                      width={
                                        fullscreened
                                          ? "100%"
                                          : commentload.file4.path !== ""
                                          ? 130
                                          : commentload.file3.path
                                          ? 110
                                          : 150
                                      }
                                      height={
                                        fullscreened
                                          ? "40%"
                                          : commentload.file3.path
                                          ? 95
                                          : 150
                                      }
                                      ref={(element) =>
                                        (droppedvideoref.current[1] =
                                          commentload.file1.filetype ===
                                          "videos"
                                            ? [
                                                droppedvideoref.current[1],
                                                element,
                                              ]
                                            : element)
                                      }
                                      playsInline
                                      muted
                                      id={commentload.file2.id}
                                      style={videohtml(commentload.file1)}
                                      onTimeUpdate={Videotimeupdate}
                                      onEnded={(e) =>
                                        Droppedvideotimeupdate(e, 1)
                                      }
                                    >
                                      <source
                                        type="video/mp4"
                                        src={commentload.file2?.path}
                                      />
                                    </video>

                                    {droppedvideoref?.current[1] !== null &&
                                      !isNaN(
                                        Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                              ?.duration
                                          : droppedvideoref.current[1]?.duration
                                      ) && (
                                        <span
                                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                          style={{
                                            bottom: "46%",
                                            left: "62%",

                                            zIndex: 1,
                                            position: "absolute",
                                          }}
                                          className="videoduration"
                                        >
                                          {0 +
                                            Math.floor(
                                              (commentload.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[1][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[1]
                                                  )
                                                ? droppedvideoref?.current[1][0]
                                                : droppedvideoref?.current[1]
                                              )?.currentTime / 60
                                            )}
                                          :
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.currentTime < 10 && 0}
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.currentTime >= 60
                                            ? Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.currentTime % 60
                                              )
                                            : Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.currentTime
                                              )}
                                          <span
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            /
                                          </span>
                                          {0 +
                                            Math.floor(
                                              (commentload.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[1][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[1]
                                                  )
                                                ? droppedvideoref?.current[1][0]
                                                : droppedvideoref?.current[1]
                                              )?.duration / 60
                                            )}
                                          :
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.duration < 10 && 0}
                                          {(commentload.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[1][1]
                                            : Array.isArray(
                                                droppedvideoref.current[1]
                                              )
                                            ? droppedvideoref?.current[1][0]
                                            : droppedvideoref?.current[1]
                                          )?.duration >= 60
                                            ? Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.duration % 60
                                              )
                                            : Math.floor(
                                                (commentload.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref
                                                      ?.current[1][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[1]
                                                    )
                                                  ? droppedvideoref
                                                      ?.current[1][0]
                                                  : droppedvideoref?.current[1]
                                                )?.duration
                                              )}
                                        </span>
                                      )}
                                  </>
                                ) : (
                                  commentload.file2?.filetype === "images" && (
                                    <img
                                      className="postphoto"
                                      src={commentload.file2?.path}
                                      id={commentload.file2?.id}
                                      style={
                                        commentload.file3?.path
                                          ? {
                                              ...imagehtml(commentload.file1),
                                              width:
                                                (commentload.file4?.path &&
                                                  130) ||
                                                (commentload.file3.path && 110),
                                              height:
                                                commentload.file3.path && 95,
                                            }
                                          : imagehtml(commentload.file1)
                                      }
                                    />
                                  )
                                )}
                                {commentload.file3?.filetype === "videos" ? (
                                  <>
                                    <video
                                      width={
                                        fullscreened
                                          ? "100%"
                                          : commentload.file4.path !== ""
                                          ? 130
                                          : 110
                                      }
                                      height={fullscreened ? "40%" : 95}
                                      ref={(element) =>
                                        (droppedvideoref.current[1] =
                                          commentload.file1.filetype ===
                                            "videos" &&
                                          commentload.file2.filetype ===
                                            "videos"
                                            ? [
                                                droppedvideoref.current[1][0],
                                                droppedvideoref.current[1][1],
                                                element,
                                              ]
                                            : commentload.file1.filetype !==
                                                "videos" &&
                                              commentload.file2.filetype !==
                                                "videos"
                                            ? element
                                            : commentload.file1.filetype !==
                                                "videos" ||
                                              commentload.file2.filetype !==
                                                "videos"
                                            ? [
                                                droppedvideoref?.current[1],
                                                element,
                                              ]
                                            : [])
                                      }
                                      playsInline
                                      muted
                                      id={commentload.file3.id}
                                      style={{
                                        ...videohtml(commentload.file1),

                                        marginTop: 4,
                                      }}
                                      onTimeUpdate={Videotimeupdate}
                                      onEnded={(e) =>
                                        Droppedvideotimeupdate(e, 1)
                                      }
                                    >
                                      <source
                                        type="video/mp4"
                                        src={commentload.file3?.path}
                                      />
                                    </video>

                                    {droppedvideoref?.current[1] !== null &&
                                      !isNaN(
                                        Array.isArray(
                                          droppedvideoref.current[1]
                                        )
                                          ? droppedvideoref.current[1][0]
                                              ?.duration
                                          : droppedvideoref.current[1]?.duration
                                      ) && (
                                        <span
                                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                          style={{
                                            bottom: "16%",
                                            left: "26%",
                                            zIndex: 1,
                                            position: "absolute",
                                          }}
                                          className="videoduration"
                                        >
                                          {0 +
                                            Math.floor(
                                              determinevideoindex(
                                                commentload.file1.filetype,
                                                commentload.file2.filetype,
                                                commentload.file4.filetype,
                                                0
                                              )?.currentTime / 60
                                            )}
                                          :
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.currentTime < 10 && 0}
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.currentTime >= 60
                                            ? Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.currentTime % 60
                                              )
                                            : Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.currentTime
                                              )}
                                          <span
                                            style={{
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >
                                            /
                                          </span>
                                          {0 +
                                            Math.floor(
                                              determinevideoindex(
                                                commentload.file1.filetype,
                                                commentload.file2.filetype,
                                                commentload.file4.filetype,
                                                0
                                              )?.duration / 60
                                            )}
                                          :
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.duration < 10 && 0}
                                          {determinevideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file4.filetype,
                                            0
                                          )?.duration >= 60
                                            ? Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.duration % 60
                                              )
                                            : Math.floor(
                                                determinevideoindex(
                                                  commentload.file1.filetype,
                                                  commentload.file2.filetype,
                                                  commentload.file4.filetype,
                                                  0
                                                )?.duration
                                              )}
                                        </span>
                                      )}
                                  </>
                                ) : (
                                  commentload.file3?.filetype === "images" && (
                                    <img
                                      className="postphoto"
                                      src={commentload.file3?.path}
                                      id={commentload.file3?.id}
                                      style={{
                                        ...imagehtml(commentload.file1),
                                        width: !commentload.file4?.path
                                          ? 110
                                          : 130,
                                        height: 95,
                                        marginTop: 4,
                                      }}
                                    />
                                  )
                                )}
                              </>
                            )}

                            {commentload.file4?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : commentload.file4.path !== ""
                                      ? 130
                                      : 110
                                  }
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[1] =
                                      commentload.file1.filetype === "videos" &&
                                      commentload.file2.filetype === "videos" &&
                                      commentload.file3.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[1][0],
                                            droppedvideoref.current[1][1],
                                            droppedvideoref.current[1][2],
                                            element,
                                          ]
                                        : commentload.file1.filetype !==
                                            "videos" &&
                                          commentload.file2.filetype !==
                                            "videos" &&
                                          commentload.file3.filetype !==
                                            "videos"
                                        ? element
                                        : commentload.file1.filetype !==
                                            "videos" ||
                                          commentload.file2.filetype !==
                                            "videos" ||
                                          commentload.file3.filetype !==
                                            "videos"
                                        ? [
                                            ...determinevideoref(
                                              commentload.file1.filetype,
                                              commentload.file2.filetype,
                                              commentload.file3.filetype,
                                              1
                                            ),
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={commentload.file4.id}
                                  style={{
                                    ...videohtml(commentload.file1),

                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) => Droppedvideotimeupdate(e, 1)}
                                >
                                  <source
                                    type="video/mp4"
                                    src={commentload.file4?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[1] !== null &&
                                  !isNaN(
                                    Array.isArray(droppedvideoref.current[1])
                                      ? droppedvideoref.current[1][0]?.duration
                                      : droppedvideoref.current[1]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "61.5%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinefourthvideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file3.filetype,
                                            1
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinefourthvideoindex(
                                        commentload.file1.filetype,
                                        commentload.file2.filetype,
                                        commentload.file3.filetype,
                                        1
                                      )?.currentTime < 10 && 0}
                                      {determinefourthvideoindex(
                                        commentload.file1.filetype,
                                        commentload.file2.filetype,
                                        commentload.file3.filetype,
                                        1
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinefourthvideoindex(
                                              commentload.file1.filetype,
                                              commentload.file2.filetype,
                                              commentload.file3.filetype,
                                              1
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinefourthvideoindex(
                                              commentload.file1.filetype,
                                              commentload.file2.filetype,
                                              commentload.file3.filetype,
                                              1
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinefourthvideoindex(
                                            commentload.file1.filetype,
                                            commentload.file2.filetype,
                                            commentload.file3.filetype,
                                            1
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinefourthvideoindex(
                                        commentload.file1.filetype,
                                        commentload.file2.filetype,
                                        commentload.file3.filetype,
                                        1
                                      )?.duration < 10 && 0}
                                      {determinefourthvideoindex(
                                        commentload.file1.filetype,
                                        commentload.file2.filetype,
                                        commentload.file3.filetype,
                                        1
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinefourthvideoindex(
                                              commentload.file1.filetype,
                                              commentload.file2.filetype,
                                              commentload.file3.filetype,
                                              1
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinefourthvideoindex(
                                              commentload.file1.filetype,
                                              commentload.file2.filetype,
                                              commentload.file3.filetype,
                                              1
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              commentload.file4?.filetype === "images" && (
                                <img
                                  src={commentload.file4?.path}
                                  className="postphoto"
                                  id={commentload.file4?.id}
                                  style={{
                                    ...imagehtml(commentload.file1),
                                    width: 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}

                            {commentload.image1 && (
                              <img
                                className="postphoto"
                                src={commentload.image1}
                                style={{
                                  width: commentload.image2 && 150,
                                  height: commentload.image2 && 150,
                                  marginLeft: commentload.image2 && 50,
                                  marginTop: commentload.image2 && 20,
                                }}
                              />
                            )}
                          </div>

                          <div className="date">
                            {commentload.year}/{commentload.month}/
                            {commentload.date}
                            <span className="Timesplit">|</span>
                            <span className="Time">
                              {commentload.hour}:{commentload.minutes}
                            </span>
                          </div>
                          <img
                            className="Rankmarked"
                            src="/RankMark.svg"
                            style={{
                              top:
                                (commentload.image1 ||
                                  commentload.file1?.path) &&
                                51,
                            }}
                          />
                          <span
                            className="dropoption"
                            style={{ height: clickedcomment && 29 }}
                          >
                            {
                              //!clickedcomment && !showdrop && (
                              <span className="commentgroup">
                                <img
                                  className="commenttap"
                                  src="./Comment1.svg"
                                  id={[commentload.id, commentload.Sender_id]}
                                  onClick={comment}
                                />
                                <span className="commentcount">
                                  {commentload.Comment_Counter > 0 &&
                                    commentload.Comment_Counter}
                                </span>
                              </span>
                              //)
                            }

                            <span className="redropline">
                              {commentload.Redropped === "done" &&
                                !clickedcomment && (
                                  <img
                                    src="./Redrop.svg"
                                    className="Redrop"
                                    //couldnt we combine the id for redrop and askunredrop
                                    onClick={AskUnredrop}
                                    id={[commentload.id, commentload.Sender_id]}
                                  />
                                )}
                              {commentload.Redropped !== "done" &&
                                !clickedcomment && (
                                  <img
                                    className="Redrop"
                                    src="./PlaneRedrop4.svg"
                                    id={[commentload.id, commentload.Sender_id]}
                                    onClick={redrop}
                                  />
                                )}
                              <span className="commentcount">
                                {commentload.Redropped_Counter == 0 ||
                                clickedcomment
                                  ? null
                                  : commentload.Redropped_Counter}
                              </span>
                            </span>
                          </span>
                          {redroppoint && commentload.id === redropid[0] && (
                            <span
                              style={{ fontSize: 8, bottom: "5%" }}
                              className="Pselect"
                            >
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  color: "white",
                                  bottom: commentload.file1?.path ? "3%" : "5%",
                                }}
                                onClick={(e) => confirmredrop(commentload)}
                              >
                                リドロップ
                              </button>
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  marginLeft: 85,
                                  bottom: commentload.file1?.path ? "3%" : "5%",
                                }}
                                onClick={(e) => {
                                  setRedroppoint(false);
                                }}
                              >
                                キャンセル
                              </button>
                            </span>
                          )}
                          {unredroppoint &&
                            commentload.id === unredropid[0] && (
                              <span
                                style={{ fontSize: 8, bottom: "5%" }}
                                className="Pselect"
                              >
                                <button
                                  style={{
                                    left: "30%",
                                    fontSize: 9,
                                    color: "white",
                                    bottom: commentload.file1?.path
                                      ? "3%"
                                      : "5%",
                                  }}
                                  onClick={(e) =>
                                    ConfirmUnredrop(
                                      commentload.OriginalTimestamp
                                    )
                                  }
                                >
                                  リドロップ解除
                                </button>
                                <button
                                  style={{
                                    left: "30%",
                                    fontSize: 9,
                                    marginLeft: 85,
                                    bottom: commentload.file1?.path
                                      ? "3%"
                                      : "5%",
                                  }}
                                  onClick={CancelUnredrop}
                                >
                                  キャンセル
                                </button>
                              </span>
                            )}
                          {blockid === commentload.id && (
                            <div className="Blockposition">
                              <Block
                                style={{ width: "80%" }}
                                // style={{ zIndex: 8 }}
                                understandblocked={understandblocked}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )
                  }
                </>
              )}

              {simdisplays[simdisplays.length - 1]?.[0]?.Deleted ? (
                <div className="drops">
                  {" "}
                  {/*<span className="textline" style={{justifyContent:"center"}}>*/}
                  <div
                    style={{ marginTop: 92, textAlign: "center", fontSize: 12 }}
                    /*ref={
                          droppedvideoref.current[2] = null
                        }*/
                  >
                    このコメントは消されているか表示できないです
                  </div>
                  {/*</span>*/}
                </div>
              ) : (
                simdisplays[simdisplays.length - 1]?.map(
                  (simdisplay, index) => (
                    <div
                      id={simdisplay.id}
                      className="drops"
                      key={simdisplay.id}
                      ref={
                        (commentbackid === simdisplay.id && scrollref) ||
                        alldisplay[alldisplay.length - 1]?.id === simdisplay.id
                          ? scrollref
                          : null
                      }
                      style={{
                        height:
                          simdisplay.file1?.path !== "" &&
                          simdisplay.file1?.path !== undefined &&
                          330,
                      }}
                    >
                      <div className="postlinetop">
                        <Link to={`/UserView/${simdisplay.Sender_id}`}>
                          <img
                            src={simdisplay.Profile_image}
                            className={
                              simdisplay.Profile_image === "/Profile3.svg"
                                ? "current3"
                                : "current1"
                            }
                          />
                        </Link>

                        {simdisplay.Comment.length >= 152 ||
                          (simdisplay.file1?.path && (
                            <div
                              className={
                                simdisplay.Comment.length >= 20
                                  ? "text"
                                  : "text1"
                              }
                              style={{
                                fontSize:
                                  simdisplay.Comment.length >= 152 && 12,
                                width:
                                  simdisplay.Comment.length >= 152 && "80%",
                                marginTop:
                                  simdisplay.Comment.length >= 152 && 8,
                                position:
                                  optionid[0] === simdisplay.id && "absolute",
                                left:
                                  optionid[0] === simdisplay.id &&
                                  simdisplay.Comment.length >= 20
                                    ? "20%"
                                    : `${49.5 - simdisplay.Comment.length}%`,
                              }}
                            >
                              {simdisplay.Comment}
                            </div>
                          ))}

                        {optionid[0] !== simdisplay.id && (
                          <img
                            src="/DropOption.svg"
                            className="postoption"
                            id={[simdisplay.id, simdisplay.Sender_id]}
                            onClick={OptionTerminal}
                          />
                        )}

                        {!deleteid && optionid[0] === simdisplay.id && (
                          <span className="Delete">
                            {(id === simdisplay.Sender_id ||
                              id === alldisplay[0].Directory) && (
                              <button
                                id={simdisplay.id}
                                onClick={(e) => setDeleteid(simdisplay.id)}
                              >
                                Delete
                              </button>
                            )}

                            <button
                              onClick={(e) => {
                                setOptionId("");
                                setDeleteid("");
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        )}

                        {deleteid === simdisplay.id && (
                          <span className="Delete">
                            <button onClick={(e) => deletecomment(1)}>
                              〇
                            </button>
                            <button onClick={(e) => setDeleteid("")}>X</button>
                          </span>
                        )}
                      </div>
                      <span className="textline">
                        <span
                          className="stylerank"
                          style={{
                            marginLeft:
                              simdisplay.Rank <= 9
                                ? 19.4
                                : simdisplay.Rank === 11
                                ? 16
                                : simdisplay.Rank <= 99
                                ? 14.5
                                : simdisplay.Rank > 99 && 10,
                            flex:
                              simdisplay.Comment.length >= 25
                                ? 0.18
                                : simdisplay.Comment.length === 20
                                ? 0.14
                                : 0.5,
                            marginTop:
                              simdisplay.Comment.length > 65 &&
                              simdisplay.file1?.path
                                ? -25
                                : simdisplay.Comment.length >= 152
                                ? -86.5
                                : null,
                          }}
                        >
                          {simdisplay.Rank}
                        </span>
                        {simdisplay.Comment.length < 152 &&
                          !simdisplay.file1?.path && (
                            <div
                              className={
                                simdisplay.Comment.length >= 20
                                  ? "text"
                                  : "text1"
                              }
                            >
                              {simdisplay.Comment}
                            </div>
                          )}
                      </span>
                      <div
                        className="alldrops"
                        onClick={(/*e*/) => display(/*e,*/ simdisplay)}
                        style={{
                          height:
                            //(post.image2 && 275) ||
                            simdisplay.file1?.path && 295,
                        }}
                      ></div>

                      <div
                        style={{
                          marginLeft:
                            !simdisplay.file3?.path && simdisplay.file2?.path
                              ? 40
                              : simdisplay.file3?.path && 60,
                          marginTop: simdisplay.file3?.path && -10,
                          justifyContent:
                            simdisplay.file3?.path &&
                            !simdisplay.file4?.path &&
                            "flex-start",
                        }}
                        className="postphotoline"
                      >
                        {simdisplay.file1?.filetype === "videos" ? (
                          <>
                            <video
                              controls={!simdisplay.file2.path && true}
                              width={
                                fullscreened
                                  ? "100%"
                                  : simdisplay.file2.path === ""
                                  ? 200
                                  : simdisplay.file4.path !== ""
                                  ? 130
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : simdisplay.file2.path === ""
                                  ? 200
                                  : simdisplay.file4?.path !== ""
                                  ? 95
                                  : simdisplay.file3.path !== ""
                                  ? 195
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[
                                  index + 1 + commentloadrefindex
                                ] = element)
                              }
                              onTimeUpdate={Videotimeupdate}
                              onEnded={
                                simdisplay.file2.path
                                  ? (e) =>
                                      Droppedvideotimeupdate(
                                        e,
                                        index + 1 + commentloadrefindex
                                      )
                                  : undefined
                              }
                              playsInline
                              muted
                              loop={!simdisplay.file2.path && true}
                              // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                              id={simdisplay.file1.id}
                              style={videohtml(
                                simdisplay?.file2,
                                simdisplay.file3,
                                simdisplay.file4
                              )}
                            >
                              <source
                                type="video/mp4"
                                src={simdisplay.file1?.path}
                              />
                            </video>
                            {!isNaN(
                              Array.isArray(
                                droppedvideoref.current[
                                  index + 1 + commentloadrefindex
                                ]
                              )
                                ? droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ][0]?.duration
                                : droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: !simdisplay.file2.filetype
                                    ? "18%"
                                    : !simdisplay.file3.filetype
                                    ? "27%"
                                    : simdisplay.file4.filetype
                                    ? "46%"
                                    : "16%",
                                  left:
                                    !simdisplay.file2.filetype ||
                                    simdisplay.file4.filetype
                                      ? "25%"
                                      : !simdisplay.file3.filetype
                                      ? "17%"
                                      : "23%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(Array.isArray(
                                  droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]
                                )
                                  ? droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ][0]
                                  : droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ]
                                )?.currentTime < 10 && 0}
                                {(Array.isArray(
                                  droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]
                                )
                                  ? droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ][0]
                                  : droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                      )
                                        ? droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                      )
                                        ? droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                    )?.duration / 60
                                  )}
                                :
                                {(Array.isArray(
                                  droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]
                                )
                                  ? droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ][0]
                                  : droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ]
                                )?.duration < 10 && 0}
                                {(Array.isArray(
                                  droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]
                                )
                                  ? droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ][0]
                                  : droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                      )
                                        ? droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]
                                      )
                                        ? droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration
                                    )}
                              </span>
                            )}
                          </>
                        ) : (
                          simdisplay.file1?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={simdisplay.file1?.path}
                              id={simdisplay.file1?.id}
                              style={imagehtml(
                                simdisplay.file2,
                                simdisplay.file3,
                                simdisplay.file4
                              )}
                            />
                          )
                        )}

                        {!simdisplay.file4?.path ? (
                          <div
                            style={{
                              display:
                                simdisplay.file3?.path &&
                                !simdisplay.file4?.path &&
                                "flex",
                              flexDirection:
                                simdisplay.file3?.path &&
                                !simdisplay.file4?.path &&
                                "column",
                            }}
                          >
                            {simdisplay.file2?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file3?.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : simdisplay.file3?.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ] =
                                      simdisplay.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file2.id}
                                  style={videohtml(simdisplay.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(
                                      e,
                                      index + 1 + commentloadrefindex
                                    )
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[
                                  index + 1 + commentloadrefindex
                                ] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]?.duration
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: !simdisplay.file3.filetype
                                          ? "27%"
                                          : "46%",
                                        left: !simdisplay.file3.filetype
                                          ? "59%"
                                          : "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file2?.path}
                                  id={simdisplay.file2?.id}
                                  style={
                                    simdisplay.file3?.path
                                      ? {
                                          ...imagehtml(simdisplay.file1),
                                          width: 110,
                                          height: simdisplay.file3.path && 95,
                                        }
                                      : imagehtml(simdisplay.file1)
                                  }
                                />
                              )
                            )}

                            {simdisplay.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={fullscreened ? "100%" : 110}
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ] =
                                      simdisplay.file1.filetype === "videos" &&
                                      simdisplay.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ][0],
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ][1],
                                            element,
                                          ]
                                        : simdisplay.file1.filetype !==
                                            "videos" &&
                                          simdisplay.file2.filetype !== "videos"
                                        ? element
                                        : simdisplay.file1.filetype !==
                                            "videos" ||
                                          simdisplay.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[
                                              index + 1 + commentloadrefindex
                                            ],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file3.id}
                                  style={{
                                    ...videohtml(simdisplay.file1),
                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(
                                      e,
                                      index + 1 + commentloadrefindex
                                    )
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[
                                  index + 1 + commentloadrefindex
                                ] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]?.duration
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1 + commentloadrefindex
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1 + commentloadrefindex
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file3?.path}
                                  id={simdisplay.file3?.id}
                                  style={{
                                    ...imagehtml(simdisplay.file1),
                                    width: !simdisplay.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <>
                            {simdisplay.file2?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file4.path !== ""
                                      ? 130
                                      : simdisplay.file3.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : simdisplay.file3.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ] =
                                      simdisplay.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file2.id}
                                  style={videohtml(simdisplay.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(
                                      e,
                                      index + 1 + commentloadrefindex
                                    )
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[
                                  index + 1 + commentloadrefindex
                                ] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]?.duration
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "46%",
                                        left: "62%",

                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (simdisplay.file1.filetype ===
                                          "videos"
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][1]
                                            : Array.isArray(
                                                droppedvideoref.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                              )
                                            ? droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ][0]
                                            : droppedvideoref?.current[
                                                index + 1 + commentloadrefindex
                                              ]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration < 10 && 0}
                                      {(simdisplay.file1.filetype === "videos"
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][1]
                                        : Array.isArray(
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ]
                                          )
                                        ? droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ][0]
                                        : droppedvideoref?.current[
                                            index + 1 + commentloadrefindex
                                          ]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index +
                                                      1 +
                                                      commentloadrefindex
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index +
                                                    1 +
                                                    commentloadrefindex
                                                ]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file2?.path}
                                  id={simdisplay.file2?.id}
                                  style={
                                    simdisplay.file3?.path
                                      ? {
                                          ...imagehtml(simdisplay.file1),
                                          width:
                                            (simdisplay.file4?.path && 130) ||
                                            (simdisplay.file3.path && 110),
                                          height: simdisplay.file3.path && 95,
                                        }
                                      : imagehtml(simdisplay.file1)
                                  }
                                />
                              )
                            )}
                            {simdisplay.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : simdisplay.file4.path !== ""
                                      ? 130
                                      : 110
                                  }
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ] =
                                      simdisplay.file1.filetype === "videos" &&
                                      simdisplay.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ][0],
                                            droppedvideoref.current[
                                              index + 1 + commentloadrefindex
                                            ][1],
                                            element,
                                          ]
                                        : simdisplay.file1.filetype !==
                                            "videos" &&
                                          simdisplay.file2.filetype !== "videos"
                                        ? element
                                        : simdisplay.file1.filetype !==
                                            "videos" ||
                                          simdisplay.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[
                                              index + 1 + commentloadrefindex
                                            ],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={simdisplay.file3.id}
                                  style={{
                                    ...videohtml(simdisplay.file1),

                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(
                                      e,
                                      index + 1 + commentloadrefindex
                                    )
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={simdisplay.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[
                                  index + 1 + commentloadrefindex
                                ] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[
                                        index + 1 + commentloadrefindex
                                      ]
                                    )
                                      ? droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0]?.duration
                                      : droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "26%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1 + commentloadrefindex
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            simdisplay.file1.filetype,
                                            simdisplay.file2.filetype,
                                            simdisplay.file4.filetype,
                                            index + 1 + commentloadrefindex
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file4.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1 + commentloadrefindex
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              simdisplay.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={simdisplay.file3?.path}
                                  id={simdisplay.file3?.id}
                                  style={{
                                    ...imagehtml(simdisplay.file1),
                                    width: !simdisplay.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </>
                        )}

                        {simdisplay.file4?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : simdisplay.file4.path !== ""
                                  ? 130
                                  : 110
                              }
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[
                                  index + 1 + commentloadrefindex
                                ] =
                                  simdisplay.file1.filetype === "videos" &&
                                  simdisplay.file2.filetype === "videos" &&
                                  simdisplay.file3.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][0],
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][1],
                                        droppedvideoref.current[
                                          index + 1 + commentloadrefindex
                                        ][2],
                                        element,
                                      ]
                                    : simdisplay.file1.filetype !== "videos" &&
                                      simdisplay.file2.filetype !== "videos" &&
                                      simdisplay.file3.filetype !== "videos"
                                    ? element
                                    : simdisplay.file1.filetype !== "videos" ||
                                      simdisplay.file2.filetype !== "videos" ||
                                      simdisplay.file3.filetype !== "videos"
                                    ? [
                                        ...determinevideoref(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1 + commentloadrefindex
                                        ),
                                        element,
                                      ]
                                    : [])
                              }
                              playsInline
                              muted
                              id={simdisplay.file4.id}
                              style={{
                                ...videohtml(simdisplay.file1),

                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) =>
                                Droppedvideotimeupdate(
                                  e,
                                  index + 1 + commentloadrefindex
                                )
                              }
                            >
                              <source
                                type="video/mp4"
                                src={simdisplay.file4?.path}
                              />
                            </video>

                            {droppedvideoref?.current[
                              index + 1 + commentloadrefindex
                            ] !== null &&
                              !isNaN(
                                Array.isArray(
                                  droppedvideoref.current[
                                    index + 1 + commentloadrefindex
                                  ]
                                )
                                  ? droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ][0]?.duration
                                  : droppedvideoref.current[
                                      index + 1 + commentloadrefindex
                                    ]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "61.5%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file3.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    ////////////////////////////////////////////////////////////////
                                    index + 1 + commentloadrefindex
                                  )?.currentTime < 10 && 0}
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1 + commentloadrefindex
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1 + commentloadrefindex
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1 + commentloadrefindex
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                    }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        simdisplay.file1.filetype,
                                        simdisplay.file2.filetype,
                                        simdisplay.file3.filetype,
                                        index + 1 + commentloadrefindex
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1 + commentloadrefindex
                                  )?.duration < 10 && 0}
                                  {determinefourthvideoindex(
                                    simdisplay.file1.filetype,
                                    simdisplay.file2.filetype,
                                    simdisplay.file3.filetype,
                                    index + 1 + commentloadrefindex
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1 + commentloadrefindex
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1 + commentloadrefindex
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          simdisplay.file4?.filetype === "images" && (
                            <img
                              src={simdisplay.file4?.path}
                              className="postphoto"
                              id={simdisplay.file4?.id}
                              style={{
                                ...imagehtml(simdisplay.file1),
                                width: 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}
                      </div>

                      <div className="date">
                        {simdisplay.year}/{simdisplay.month}/{simdisplay.date}
                        <span className="Timesplit">|</span>
                        <span className="Time">
                          {simdisplay.hour}:{simdisplay.minutes}
                        </span>
                      </div>

                      <img
                        style={{ top: simdisplay.file1?.path && 51 }}
                        className="Rankmarked"
                        src="/RankMark.svg"
                      />
                      <span className="dropoption">
                        <span className="commentgroup">
                          <img
                            className="commenttap"
                            src="./Comment1.svg"
                            id={[simdisplay.id, simdisplay.Sender_id]}
                            onClick={comment}
                          />
                          <span className="commentcount">
                            {simdisplay.Comment_Counter == 0
                              ? null
                              : simdisplay.Comment_Counter}
                          </span>
                        </span>
                        <span className="redropline">
                          {simdisplay.Redropped == "done" ? (
                            <>
                              <img
                                src="./Redrop.svg"
                                className="Redrop"
                                //couldnt we combine the id for redrop and askunredrop
                                onClick={AskUnredrop}
                                id={[simdisplay.id, simdisplay.Sender_id]}
                              />
                            </>
                          ) : (
                            <img
                              className="Redrop"
                              src="./PlaneRedrop4.svg"
                              id={[
                                simdisplay.id,
                                simdisplay.Sender_id,
                                /*
                            simpost.Rank,
                            simpost.Profile_image,
                            simpost.Comment,
                            simpost.Comment_Counter,
                            simpost.hour,
                            simpost.minutes,
                            simpost.year,
                            simpost.month,
                            simpost.date,
                            simpost.image1,
                            simpost.image2,
                            simpost.image3,
                            simpost.image4,
                            simpost.video,
                            simpost.Redropper_id,
                            simpost.Redropped_Counter,
                            simpost.Sent_id,
                            simpost.Directory,
                            simpost.This_Directory,
                            */
                              ]}
                              onClick={redrop}
                            />
                          )}
                          <span className="commentcount">
                            {simdisplay.Redropped_Counter == 0
                              ? null
                              : simdisplay.Redropped_Counter}
                          </span>
                        </span>
                      </span>
                      {redroppoint && simdisplay.id === redropid[0] && (
                        <span
                          style={{ fontSize: 8, bottom: "5%" }}
                          className="Pselect"
                        >
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              color: "white",
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) => confirmredrop(simdisplay)}
                          >
                            リドロップ
                          </button>
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) => {
                              setRedroppoint(false);
                            }}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                      {unredroppoint && simdisplay.id === unredropid[0] && (
                        <span
                          style={{ fontSize: 8, bottom: "5%" }}
                          className="Pselect"
                        >
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              color: "white",
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) =>
                              ConfirmUnredrop(simdisplay.OriginalTimestamp)
                            }
                          >
                            リドロップ解除
                          </button>
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: simdisplay.file1?.path ? "3%" : "5%",
                            }}
                            onClick={CancelUnredrop}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                      {blockid === simdisplay.id && (
                        <div className="Blockposition">
                          <Block
                            style={{ width: "80%" }}
                            // style={{ zIndex: 8 }}
                            understandblocked={understandblocked}
                          />
                        </div>
                      )}
                    </div>
                  )
                )
              )}
            </>
          ))
      }

      {clickedcomment && (
        <>
          <div
            id={clickedcomment.id}
            className="drops"
            // key={clickedcomment.id}
            style={{
              height:
                (clickedcomment.image2 && 310) ||
                ((clickedcomment.image1 ||
                  (clickedcomment.file1?.path !== "" &&
                    clickedcomment.file1?.path !== undefined)) &&
                  330),
            }}
          >
            <div className="postlinetop">
              <Link
                to={`/UserView/${
                  clickedcomment.Sender_id
                    ? clickedcomment.Sender_id
                    : clickedcomment.Directory
                }`}
              >
                <img
                  className={
                    clickedcomment.Profile_image === "/Profile3.svg"
                      ? "current3"
                      : "current1"
                  }
                  src={clickedcomment.Profile_image}
                />
              </Link>

              {(clickedcomment.image1 || clickedcomment.file1?.path) && (
                <div
                  style={{
                    fontSize:
                      (clickedcomment.text || clickedcomment.Comment)?.length >=
                        142 && 10.5,
                    position: optionid[0] === clickedcomment.id && "absolute",
                    left:
                      optionid[0] === clickedcomment.id &&
                      (clickedcomment.text || clickedcomment.Comment)?.length >=
                        23
                        ? "20%"
                        : `${
                            49.5 -
                            (clickedcomment.text || clickedcomment.Comment)
                              ?.length
                          }%`,
                  }}
                  className={
                    (clickedcomment.text || clickedcomment.Comment)?.length >=
                    23
                      ? "text"
                      : "text1"
                  }
                >
                  {clickedcomment.text || clickedcomment.Comment}
                </div>
              )}

              {optionid[0] !== clickedcomment.id && (
                <img
                  src="/DropOption.svg"
                  id={[clickedcomment.id, clickedcomment.Sender_id]}
                  className="postoption"
                  onClick={OptionTerminal}
                />
              )}

              {!deleteid && optionid[0] === clickedcomment.id && (
                <span className="Delete">
                  {(id === clickedcomment.Sender_id ||
                    id === alldisplay[0]?.Directory ||
                    id === clickedcomment.Directory) && (
                    <button
                      id={clickedcomment.id}
                      onClick={(e) => setDeleteid(clickedcomment.id)}
                    >
                      Delete
                    </button>
                  )}

                  <button
                    onClick={(e) => {
                      setOptionId("");
                      setDeleteid("");
                    }}
                  >
                    Cancel
                  </button>
                </span>
              )}

              {deleteid === clickedcomment.id && (
                <span className="Delete">
                  <button
                    id={clickedcomment.id}
                    onClick={
                      alldisplay.length === 0 ||
                      alldisplay[0].id === clickedcomment.id
                        ? deletedrop
                        : clickedcomment.id === alldisplay.at(-1).id
                        ? (e) => deletecomment(2)
                        : (e) => deletecomment(1)
                    }
                  >
                    〇
                  </button>

                  <button onClick={(e) => setDeleteid("")}>X</button>
                </span>
              )}
            </div>

            <span className="textline">
              <span
                className="stylerank"
                style={{
                  marginLeft:
                    clickedcomment.Rank <= 9
                      ? 19.4
                      : clickedcomment.Rank === 11
                      ? 16
                      : clickedcomment.Rank <= 99
                      ? 14.5
                      : clickedcomment.Rank > 99 && 10,
                  flex:
                    (clickedcomment.text || clickedcomment.Comment)?.length >=
                    25
                      ? 0.18
                      : (clickedcomment.text || clickedcomment.Comment)
                          ?.length === 20
                      ? 0.14
                      : 0.5,
                  marginTop:
                    (clickedcomment.text || clickedcomment.Comment)?.length >
                      65 &&
                    (clickedcomment.image1 || clickedcomment.file1?.path)
                      ? -25
                      : null,
                }}
              >
                {clickedcomment.Rank}
              </span>

              {!clickedcomment.image1 &&
                !clickedcomment.file1?.path &&
                (clickedcomment.text || clickedcomment.Comment)?.length <
                  152 && (
                  <div
                    className={
                      (clickedcomment.text || clickedcomment.Comment)?.length >=
                      23
                        ? "text"
                        : "text1"
                    }
                  >
                    {clickedcomment.text || clickedcomment.Comment}
                  </div>
                )}
            </span>

            <div
              style={{
                marginLeft:
                  !clickedcomment.file3?.path && clickedcomment.file2?.path
                    ? 40
                    : clickedcomment.file3?.path && 60,
                marginTop: clickedcomment.file3?.path && -10,
                justifyContent:
                  clickedcomment.file3?.path &&
                  !clickedcomment.file4?.path &&
                  "flex-start",
              }}
              className="postphotoline"
            >
              {clickedcomment.file1?.filetype === "videos" ? (
                <>
                  <video
                    controls={!clickedcomment.file2.path && true}
                    width={
                      fullscreened
                        ? "100%"
                        : clickedcomment.file2.path === ""
                        ? 200
                        : clickedcomment.file4.path !== ""
                        ? 130
                        : 150
                    }
                    height={
                      fullscreened
                        ? "40%"
                        : clickedcomment.file2.path === ""
                        ? 200
                        : clickedcomment.file4?.path !== ""
                        ? 95
                        : clickedcomment.file3.path !== ""
                        ? 195
                        : 150
                    }
                    ref={(element) => (droppedvideoref.current[0] = element)}
                    onTimeUpdate={Videotimeupdate}
                    onEnded={
                      clickedcomment.file2.path
                        ? (e) => Droppedvideotimeupdate(e, 0)
                        : undefined
                    }
                    playsInline
                    muted
                    loop={!clickedcomment.file2.path && true}
                    // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                    id={clickedcomment.file1.id}
                    style={videohtml(
                      clickedcomment.file2,
                      clickedcomment.file3,
                      clickedcomment.file4
                    )}
                  >
                    <source type="video/mp4" src={clickedcomment.file1?.path} />
                  </video>
                  {!isNaN(
                    Array.isArray(droppedvideoref.current[0])
                      ? droppedvideoref.current[0][0]?.duration
                      : droppedvideoref.current[0]?.duration
                  ) && (
                    <span
                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                      style={{
                        bottom: !clickedcomment.file2.filetype
                          ? "18%"
                          : !clickedcomment.file3.filetype
                          ? "27%"
                          : clickedcomment.file4.filetype
                          ? "46%"
                          : "16%",
                        left:
                          !clickedcomment.file2.filetype ||
                          clickedcomment.file4.filetype
                            ? "25%"
                            : !clickedcomment.file3.filetype
                            ? "17%"
                            : "23%",
                        zIndex: 1,
                        position: "absolute",
                      }}
                      className="videoduration"
                    >
                      {0 +
                        Math.floor(
                          (Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.currentTime / 60
                        )}
                      :
                      {(Array.isArray(droppedvideoref.current[0])
                        ? droppedvideoref.current[0][0]
                        : droppedvideoref.current[0]
                      )?.currentTime < 10 && 0}
                      {(Array.isArray(droppedvideoref.current[0])
                        ? droppedvideoref.current[0][0]
                        : droppedvideoref.current[0]
                      )?.currentTime >= 60
                        ? Math.floor(
                            (Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime % 60
                          )
                        : Math.floor(
                            (Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.currentTime
                          )}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
                      {0 +
                        Math.floor(
                          (Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]
                            : droppedvideoref.current[0]
                          )?.duration / 60
                        )}
                      :
                      {(Array.isArray(droppedvideoref.current[0])
                        ? droppedvideoref.current[0][0]
                        : droppedvideoref.current[0]
                      )?.duration < 10 && 0}
                      {(Array.isArray(droppedvideoref.current[0])
                        ? droppedvideoref.current[0][0]
                        : droppedvideoref.current[0]
                      )?.duration >= 60
                        ? Math.floor(
                            (Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration % 60
                          )
                        : Math.floor(
                            (Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref.current[0][0]
                              : droppedvideoref.current[0]
                            )?.duration
                          )}
                    </span>
                  )}
                </>
              ) : (
                clickedcomment.file1?.filetype === "images" && (
                  <img
                    className="postphoto"
                    src={clickedcomment.file1?.path}
                    id={clickedcomment.file1?.id}
                    style={imagehtml(
                      clickedcomment.file2,
                      clickedcomment.file3,
                      clickedcomment.file4
                    )}
                  />
                )
              )}

              {!clickedcomment.file4?.path ? (
                <div
                  style={{
                    display:
                      clickedcomment.file3?.path &&
                      !clickedcomment.file4?.path &&
                      "flex",
                    flexDirection:
                      clickedcomment.file3?.path &&
                      !clickedcomment.file4?.path &&
                      "column",
                  }}
                >
                  {clickedcomment.file2?.filetype === "videos" ? (
                    <>
                      {console.log(droppedvideoref.current)}
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : clickedcomment.file3?.path
                            ? 110
                            : 150
                        }
                        height={
                          fullscreened
                            ? "40%"
                            : clickedcomment.file3?.path
                            ? 95
                            : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[0] =
                            clickedcomment.file1.filetype === "videos"
                              ? [droppedvideoref.current[0], element]
                              : element)
                        }
                        playsInline
                        muted
                        id={clickedcomment.file2.id}
                        style={videohtml(clickedcomment.file1)}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                      >
                        <source
                          type="video/mp4"
                          src={clickedcomment.file2?.path}
                        />
                      </video>

                      {droppedvideoref?.current[0] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: !clickedcomment.file3.filetype
                                ? "27%"
                                : "46%",
                              left: !clickedcomment.file3.filetype
                                ? "59%"
                                : "65%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                (clickedcomment.file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime / 60
                              )}
                            :
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.currentTime < 10 && 0}
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.currentTime >= 60
                              ? Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime
                                )}
                            <span
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              /
                            </span>
                            {0 +
                              Math.floor(
                                (clickedcomment.file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration / 60
                              )}
                            :
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.duration < 10 && 0}
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.duration >= 60
                              ? Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    clickedcomment.file2?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={clickedcomment.file2?.path}
                        id={clickedcomment.file2?.id}
                        style={
                          clickedcomment.file3?.path
                            ? {
                                ...imagehtml(clickedcomment.file1),
                                width: 110,
                                height: clickedcomment.file3.path && 95,
                              }
                            : imagehtml(clickedcomment.file1)
                        }
                      />
                    )
                  )}

                  {clickedcomment.file3?.filetype === "videos" ? (
                    <>
                      <video
                        width={fullscreened ? "100%" : 110}
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[0] =
                            clickedcomment.file1.filetype === "videos" &&
                            clickedcomment.file2.filetype === "videos"
                              ? [
                                  droppedvideoref.current[0][0],
                                  droppedvideoref.current[0][1],
                                  element,
                                ]
                              : clickedcomment.file1.filetype !== "videos" &&
                                clickedcomment.file2.filetype !== "videos"
                              ? element
                              : clickedcomment.file1.filetype !== "videos" ||
                                clickedcomment.file2.filetype !== "videos"
                              ? [droppedvideoref?.current[0], element]
                              : [])
                        }
                        playsInline
                        muted
                        id={clickedcomment.file3.id}
                        style={{
                          ...videohtml(clickedcomment.file1),
                          marginTop: 4,
                        }}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                      >
                        <source
                          type="video/mp4"
                          src={clickedcomment.file3?.path}
                        />
                      </video>

                      {droppedvideoref?.current[0] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: "16%",
                              left: "65%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                determinevideoindex(
                                  clickedcomment.file1.filetype,
                                  clickedcomment.file2.filetype,
                                  clickedcomment.file4.filetype,
                                  0
                                )?.currentTime / 60
                              )}
                            :
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.currentTime < 10 && 0}
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.currentTime >= 60
                              ? Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime
                                )}
                            <span
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              /
                            </span>
                            {0 +
                              Math.floor(
                                determinevideoindex(
                                  clickedcomment.file1.filetype,
                                  clickedcomment.file2.filetype,
                                  clickedcomment.file4.filetype,
                                  0
                                )?.duration / 60
                              )}
                            :
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.duration < 10 && 0}
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.duration >= 60
                              ? Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    clickedcomment.file3?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={clickedcomment.file3?.path}
                        id={clickedcomment.file3?.id}
                        style={{
                          ...imagehtml(clickedcomment.file1),
                          width: !clickedcomment.file4?.path ? 110 : 130,
                          height: 95,
                          marginTop: 4,
                        }}
                      />
                    )
                  )}
                </div>
              ) : (
                <>
                  {clickedcomment.file2?.filetype === "videos" ? (
                    <>
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : clickedcomment.file4.path !== ""
                            ? 130
                            : clickedcomment.file3.path
                            ? 110
                            : 150
                        }
                        height={
                          fullscreened
                            ? "40%"
                            : clickedcomment.file3.path
                            ? 95
                            : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[0] =
                            clickedcomment.file1.filetype === "videos"
                              ? [droppedvideoref.current[0], element]
                              : element)
                        }
                        playsInline
                        muted
                        id={clickedcomment.file2.id}
                        style={videohtml(clickedcomment.file1)}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                      >
                        <source
                          type="video/mp4"
                          src={clickedcomment.file2?.path}
                        />
                      </video>

                      {droppedvideoref?.current[0] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: "46%",
                              left: "62%",

                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                (clickedcomment.file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.currentTime / 60
                              )}
                            :
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.currentTime < 10 && 0}
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.currentTime >= 60
                              ? Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.currentTime
                                )}
                            <span
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              /
                            </span>
                            {0 +
                              Math.floor(
                                (clickedcomment.file1.filetype === "videos"
                                  ? droppedvideoref?.current[0][1]
                                  : Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref?.current[0][0]
                                  : droppedvideoref?.current[0]
                                )?.duration / 60
                              )}
                            :
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.duration < 10 && 0}
                            {(clickedcomment.file1.filetype === "videos"
                              ? droppedvideoref?.current[0][1]
                              : Array.isArray(droppedvideoref.current[0])
                              ? droppedvideoref?.current[0][0]
                              : droppedvideoref?.current[0]
                            )?.duration >= 60
                              ? Math.floor(
                                  (clickedcomment.file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  (alldisplay.at(-1).file1.filetype === "videos"
                                    ? droppedvideoref?.current[0][1]
                                    : Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref?.current[0][0]
                                    : droppedvideoref?.current[0]
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    clickedcomment.file2?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={clickedcomment.file2?.path}
                        id={clickedcomment.file2?.id}
                        style={
                          clickedcomment.file3?.path
                            ? {
                                ...imagehtml(clickedcomment.file1),
                                width:
                                  (clickedcomment.file4?.path && 130) ||
                                  (clickedcomment.file3.path && 110),
                                height: clickedcomment.file3.path && 95,
                              }
                            : imagehtml(clickedcomment.file1)
                        }
                      />
                    )
                  )}
                  {clickedcomment.file3?.filetype === "videos" ? (
                    <>
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : clickedcomment.file4.path !== ""
                            ? 130
                            : 110
                        }
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[0] =
                            clickedcomment.file1.filetype === "videos" &&
                            clickedcomment.file2.filetype === "videos"
                              ? [
                                  droppedvideoref.current[0][0],
                                  droppedvideoref.current[0][1],
                                  element,
                                ]
                              : clickedcomment.file1.filetype !== "videos" &&
                                clickedcomment.file2.filetype !== "videos"
                              ? element
                              : clickedcomment.file1.filetype !== "videos" ||
                                clickedcomment.file2.filetype !== "videos"
                              ? [droppedvideoref?.current[0], element]
                              : [])
                        }
                        playsInline
                        muted
                        id={clickedcomment.file3.id}
                        style={{
                          ...videohtml(clickedcomment.file1),

                          marginTop: 4,
                        }}
                        onTimeUpdate={Videotimeupdate}
                        onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                      >
                        <source
                          type="video/mp4"
                          src={clickedcomment.file3?.path}
                        />
                      </video>

                      {droppedvideoref?.current[0] !== null &&
                        !isNaN(
                          Array.isArray(droppedvideoref.current[0])
                            ? droppedvideoref.current[0][0]?.duration
                            : droppedvideoref.current[0]?.duration
                        ) && (
                          <span
                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                            style={{
                              bottom: "16%",
                              left: "26%",
                              zIndex: 1,
                              position: "absolute",
                            }}
                            className="videoduration"
                          >
                            {0 +
                              Math.floor(
                                determinevideoindex(
                                  clickedcomment.file1.filetype,
                                  clickedcomment.file2.filetype,
                                  clickedcomment.file4.filetype,
                                  0
                                )?.currentTime / 60
                              )}
                            :
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.currentTime < 10 && 0}
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.currentTime >= 60
                              ? Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime % 60
                                )
                              : Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.currentTime
                                )}
                            <span
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              /
                            </span>
                            {0 +
                              Math.floor(
                                determinevideoindex(
                                  clickedcomment.file1.filetype,
                                  clickedcomment.file2.filetype,
                                  clickedcomment.file4.filetype,
                                  0
                                )?.duration / 60
                              )}
                            :
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.duration < 10 && 0}
                            {determinevideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file4.filetype,
                              0
                            )?.duration >= 60
                              ? Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration % 60
                                )
                              : Math.floor(
                                  determinevideoindex(
                                    clickedcomment.file1.filetype,
                                    clickedcomment.file2.filetype,
                                    clickedcomment.file4.filetype,
                                    0
                                  )?.duration
                                )}
                          </span>
                        )}
                    </>
                  ) : (
                    clickedcomment.file3?.filetype === "images" && (
                      <img
                        className="postphoto"
                        src={clickedcomment.file3?.path}
                        id={clickedcomment.file3?.id}
                        style={{
                          ...imagehtml(clickedcomment.file1),
                          width: !clickedcomment.file4?.path ? 110 : 130,
                          height: 95,
                          marginTop: 4,
                        }}
                      />
                    )
                  )}
                </>
              )}

              {clickedcomment.file4?.filetype === "videos" ? (
                <>
                  <video
                    width={
                      fullscreened
                        ? "100%"
                        : clickedcomment.file4.path !== ""
                        ? 130
                        : 110
                    }
                    height={fullscreened ? "40%" : 95}
                    ref={(element) =>
                      (droppedvideoref.current[0] =
                        clickedcomment.file1.filetype === "videos" &&
                        clickedcomment.file2.filetype === "videos" &&
                        clickedcomment.file3.filetype === "videos"
                          ? [
                              droppedvideoref.current[0][0],
                              droppedvideoref.current[0][1],
                              droppedvideoref.current[0][2],
                              element,
                            ]
                          : clickedcomment.file1.filetype !== "videos" &&
                            clickedcomment.file2.filetype !== "videos" &&
                            clickedcomment.file3.filetype !== "videos"
                          ? element
                          : clickedcomment.file1.filetype !== "videos" ||
                            clickedcomment.file2.filetype !== "videos" ||
                            clickedcomment.file3.filetype !== "videos"
                          ? [
                              ...determinevideoref(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              ),
                              element,
                            ]
                          : [])
                    }
                    playsInline
                    muted
                    id={clickedcomment.file4.id}
                    style={{
                      ...videohtml(clickedcomment.file1),

                      marginTop: 4,
                    }}
                    onTimeUpdate={Videotimeupdate}
                    onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                  >
                    <source type="video/mp4" src={clickedcomment.file4?.path} />
                  </video>

                  {droppedvideoref?.current[0] !== null &&
                    !isNaN(
                      Array.isArray(droppedvideoref.current[0])
                        ? droppedvideoref.current[0][0]?.duration
                        : droppedvideoref.current[0]?.duration
                    ) && (
                      <span
                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                        style={{
                          bottom: "16%",
                          left: "61.5%",
                          zIndex: 1,
                          position: "absolute",
                        }}
                        className="videoduration"
                      >
                        {0 +
                          Math.floor(
                            determinefourthvideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file3.filetype,
                              0
                            )?.currentTime / 60
                          )}
                        :
                        {determinefourthvideoindex(
                          clickedcomment.file1.filetype,
                          clickedcomment.file2.filetype,
                          clickedcomment.file3.filetype,
                          0
                        )?.currentTime < 10 && 0}
                        {determinefourthvideoindex(
                          clickedcomment.file1.filetype,
                          clickedcomment.file2.filetype,
                          clickedcomment.file3.filetype,
                          0
                        )?.currentTime >= 60
                          ? Math.floor(
                              determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.currentTime % 60
                            )
                          : Math.floor(
                              determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.currentTime
                            )}
                        <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
                        {0 +
                          Math.floor(
                            determinefourthvideoindex(
                              clickedcomment.file1.filetype,
                              clickedcomment.file2.filetype,
                              clickedcomment.file3.filetype,
                              0
                            )?.duration / 60
                          )}
                        :
                        {determinefourthvideoindex(
                          clickedcomment.file1.filetype,
                          clickedcomment.file2.filetype,
                          clickedcomment.file3.filetype,
                          0
                        )?.duration < 10 && 0}
                        {determinefourthvideoindex(
                          clickedcomment.file1.filetype,
                          clickedcomment.file2.filetype,
                          clickedcomment.file3.filetype,
                          0
                        )?.duration >= 60
                          ? Math.floor(
                              determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.duration % 60
                            )
                          : Math.floor(
                              determinefourthvideoindex(
                                clickedcomment.file1.filetype,
                                clickedcomment.file2.filetype,
                                clickedcomment.file3.filetype,
                                0
                              )?.duration
                            )}
                      </span>
                    )}
                </>
              ) : (
                clickedcomment.file4?.filetype === "images" && (
                  <img
                    src={clickedcomment.file4?.path}
                    className="postphoto"
                    id={clickedcomment.file4?.id}
                    style={{
                      ...imagehtml(clickedcomment.file1),
                      width: 130,
                      height: 95,
                      marginTop: 4,
                    }}
                  />
                )
              )}

              {clickedcomment.image1 && (
                <img
                  className="postphoto"
                  src={clickedcomment.image1}
                  /*
                          style={{
                            width: clickedcomment.image2 && 150,
                            height: clickedcomment.image2 && 150,
                            marginLeft: clickedcomment.image2 && 50,
                            marginTop: clickedcomment.image2 && 20,
                          }}
                          */
                />
              )}
            </div>

            <div className="date">
              {clickedcomment.year}/{clickedcomment.month}/{clickedcomment.date}
              <span className="Timesplit">|</span>
              {clickedcomment.hour}:{clickedcomment.minutes}
            </div>

            <img
              style={{
                top:
                  (clickedcomment.image1 || clickedcomment.file1?.path) && 51,
              }}
              className="Rankmarked"
              src="/RankMark.svg"
            />
          </div>

          <textarea
            className="drop"
            placeholder="返信..."
            value={send}
            onChange={(e) => setSend(e.target.value)}
            style={{
              marginTop: 0,
              height: tempfiles.length > 0 ? 350 : 100,
            }}
          />
          {tempfiles.length > 0 && (
            <div
              style={{
                justifyContent: tempfiles.length > 2 && "normal",
                // top:"52%",
                position: "relative",
                bottom: 280,
              }}
              className="commentimagecontainer"
            >
              {tempfiles.map((tempfile, index) => (
                <div
                  ref={(element) => (ref3.current[index] = element)}
                  key={tempfile.id}
                >
                  <div>
                    {tempfile.realfile.type.includes("image") && (
                      <img
                        className="dropfile"
                        style={{
                          marginLeft: tempfiles.length > 1 && 10,
                          marginRight:
                            (tempfiles.length === 2 &&
                              tempfile.id ===
                                tempfiles[tempfiles.length - 1].id &&
                              13) ||
                            (tempfiles.length > 2 &&
                              tempfile.id ===
                                tempfiles[tempfiles.length - 1].id &&
                              10),
                          width: tempfiles.length > 1 && 175,
                          height: tempfiles.length > 1 && 175,
                        }}
                        src={tempfile.file}
                      />
                    )}
                    {tempfile.realfile.type.includes("video") && (
                      <>
                        <span>
                          {controlon && (
                            <>
                              <img
                                style={{
                                  zIndex: 200,
                                  marginLeft: 5,
                                  position: "absolute",
                                  top: 5,
                                }}
                                src="/Arrow1.svg"
                                onClick={Fullscreencancel}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {!ref3.current[index]?.children[0]?.children[0]
                                  ?.children[0]?.paused && !paused ? (
                                  <img
                                    style={{
                                      zIndex: 200,

                                      position: "absolute",
                                      top: "46%",

                                      width: 15,
                                    }}
                                    src="/ConnectPause.svg"
                                    onClick={(e) => {
                                      setPaused(true);
                                      ref3.current[
                                        index
                                      ]?.children[0]?.children[0].children[4]?.pause();
                                    }}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      zIndex: 200,

                                      position: "absolute",
                                      top: "46%",

                                      width: 50,
                                    }}
                                    src="/ConnectPlay.svg"
                                    onClick={(e) => {
                                      ref3.current[
                                        index
                                      ].children[0].children[0].children[4].play();
                                      setPaused(false);
                                      setVideolocation(4);
                                    }}
                                  />
                                )}
                              </div>
                              {(!isNaN(
                                ref3.current[index]?.children[0].children[0]
                                  .children[3]?.duration
                              ) ||
                                !isNaN(
                                  ref3.current[index]?.children[0].children[0]
                                    .children[4]?.duration
                                )) && (
                                <div>
                                  <span
                                    style={{
                                      bottom: "-430px",
                                      zIndex: 1,
                                    }}
                                    className="videoduration"
                                  >
                                    {0 +
                                      Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[videolocation]
                                          ?.currentTime / 60
                                      )}
                                    :
                                    {ref3.current[index]?.children[0]
                                      .children[0].children[videolocation]
                                      ?.currentTime < 10 && 0}
                                    {ref3.current[index]?.children[0]
                                      .children[0].children[videolocation]
                                      ?.currentTime >= 60
                                      ? Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.currentTime % 60
                                        )
                                      : Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.currentTime
                                        )}
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                      }}
                                    >
                                      /
                                    </span>
                                    {0 +
                                      Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[videolocation]
                                          ?.duration / 60
                                      )}
                                    :
                                    {ref3.current[index]?.children[0]
                                      .children[0].children[videolocation]
                                      ?.duration < 10 && 0}
                                    {ref3.current[index]?.children[0]
                                      .children[0].children[videolocation]
                                      ?.duration >= 60
                                      ? Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.duration % 60
                                        )
                                      : Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.duration
                                        )}
                                  </span>
                                  <input
                                    type="range"
                                    style={{
                                      width: "100%",
                                      position: "relative",
                                      bottom: "-440px",
                                      zIndex: 1,
                                    }}
                                    min="0"
                                    max={Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[videolocation]
                                        ?.duration
                                    )}
                                    value={videotime}
                                    onChange={Videotimechange}
                                    id={tempfile.id}
                                  ></input>
                                </div>
                              )}
                            </>
                          )}
                          {fullscreened && (
                            <div
                              style={{
                                display: "flex",
                              }}
                              id={tempfile.id}
                            >
                              <div
                                className="fullscreencontrol"
                                id={[tempfile.id, "left"]}
                                onClick={fullscreenside}
                              ></div>
                              <div
                                style={{ right: 0 }}
                                className="fullscreencontrol"
                                id={[tempfile.id, "right"]}
                                onClick={fullscreenside}
                              ></div>
                            </div>
                          )}
                          <video
                            controls={tempfiles.length < 2 && true}
                            width={
                              fullscreened
                                ? "100%"
                                : tempfiles.length === 1
                                ? 200
                                : 175
                            }
                            height={
                              fullscreened
                                ? "40%"
                                : tempfiles.length === 1
                                ? 200
                                : 175
                            }
                            playsInline
                            autoPlay
                            muted
                            loop
                            id={tempfile.id}
                            onClick={
                              tempfiles.length > 1 ? fullscreen : undefined
                            }
                            onTimeUpdate={
                              tempfiles.length > 1 ? Videotimeupdate : undefined
                            }
                            style={{
                              objectFit: "cover",
                              borderRadius: 20,
                              marginLeft:
                                tempfiles.length > 1 && !fullscreened && 10,
                              marginRight:
                                (tempfiles.length === 2 &&
                                  tempfile.id ===
                                    tempfiles[tempfiles.length - 1].id &&
                                  !fullscreened &&
                                  13) ||
                                (tempfiles.length > 2 &&
                                  tempfile.id ===
                                    tempfiles[tempfiles.length - 1].id &&
                                  !fullscreened &&
                                  10),
                              position: fullscreened && "absolute",
                              top: fullscreened && "30%",
                            }}
                          >
                            <source src={tempfile.file} type="video/mp4" />
                          </video>
                          {tempfiles.length > 1 &&
                            tempfile.realfile.type.includes("video") &&
                            !fullscreened &&
                            (!isNaN(
                              ref3.current[tempfiles.length - 1]?.children[0]
                                .children[0].children[0]?.duration
                            ) ||
                              tempfiles[
                                tempfiles.length - 1
                              ].realfile.type.includes("image")) && (
                              <span className="videoduration">
                                {0 +
                                  Math.floor(
                                    ref3.current[index]?.children[0].children[0]
                                      .children[0].currentTime / 60
                                  )}
                                :
                                {ref3.current[index]?.children[0].children[0]
                                  .children[0].currentTime < 10 && 0}
                                {ref3.current[index]?.children[0].children[0]
                                  .children[0].currentTime >= 60
                                  ? Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].currentTime %
                                        60
                                    )
                                  : Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].currentTime
                                    )}
                                <span
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    ref3.current[index]?.children[0].children[0]
                                      .children[0].duration / 60
                                  )}
                                :
                                {ref3.current[index]?.children[0].children[0]
                                  .children[0].duration < 10 && 0}
                                {ref3.current[index]?.children[0].children[0]
                                  .children[0].duration >= 60
                                  ? Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].duration % 60
                                    )
                                  : Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].duration
                                    )}
                              </span>
                            )}
                        </span>
                      </>
                    )}

                    {(tempfiles[tempfiles.length - 1].realfile.type.includes(
                      "image"
                    ) ||
                      !isNaN(
                        ref3.current[tempfiles.length - 1]?.children[0]
                          .children[0].children[0].duration
                      ) ||
                      tempfiles.length === 1) && (
                      <img
                        style={{
                          width: 25,
                          position: "relative",
                          left:
                            (tempfiles.length === 1 && -35) ||
                            (tempfiles.length > 1 &&
                              tempfile.realfile.type.includes("video") &&
                              95) ||
                            (tempfiles.length > 1 && 150),

                          bottom:
                            (tempfiles.length === 1 && 170) ||
                            (tempfiles.length > 1 && 173),
                        }}
                        src="/Xout.svg"
                        onClick={filedelete}
                        id={tempfile.id}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            style={{
              position: "relative",
              bottom: tempfiles.length === 1 ? 300 : 310,
            }}
          >
            <Progressbar percent={progresspercent} />
          </div>

          <span
            style={{
              bottom: tempfiles?.length === 0 ? 38 : 30,
              marginTop: tempfiles.length > 0 && -258,
            }}
            className="CommentCameraline"
          >
            <label>
              <img className="camera" src="./Photo.svg" />
              <input
                style={{ display: "none" }}
                type="file"
                multiple="multiple"
                onChange={fileedit}
              />
            </label>
            <span style={{ marginLeft: 4, marginBottom: 4 }}>|</span>
            <button
              style={{ marginLeft: 4, marginBottom: 4 }}
              className="Commentsendbutton"
              onClick={
                (tempfiles.length > 0 || send) && !disablepress //dropref.current
                  ? SendComment
                  : undefined
              }
            >
              送信
            </button>
          </span>
        </>
      )}

      {/*notificationtype === "Follow" &&
        followers.map((follower) => (
          <div
            style={{
              //height: "max-content",
              //display: "flex",
              //alignItems: notification.Type !== "Redrop" && "center",
              background: "linear-gradient(#63f0f5, #cecaf0)",
            }}
            key={follower.id}
          //  className="drops"
            /* ref={
                  alldisplay[0]?.notificationid === notification.id
                    ? scrollref
                    : null
                }
         // >

          
           
          {//map to the bottom one arraystyle1
          }
            <img  src={follower.Profile_image} />
           
            {follower.Rank}
            {follower.Name}

            {/*<div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 18,
                              //maybe change the flex : 1 in the arraystyle function instead of marginRight
                            }}
                          >
                          
                            </div>
        ))}
                          
                          */}

      {notificationtype === "Follow" && (
        /* <div
          style={{
            display: "flex",
            flexDirection: "column",
            //alignItems: "center",
            //marginRight: 18,
            //maybe change the flex : 1 in the arraystyle function instead of marginRight
          }}
        >*/
        <div style={{ zIndex: 1, display: "flex" }}>
          {arraystyle1([
            followers.Profile_image,
            followers.Rank,
            followers.Name,
          ]).map((element, secondindex) => (
            <div key={secondindex}>
              {
                //put it in an object in the arraystyle1 no need for ternarys of wheteher its an image or ranks or whatever
                //or 3 で区切る with flex
              }
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //flexWrap:secondindex % 3 === 0 && "wrap"
                }}
              >
                <div>
                  {element === "/Profile3.svg" ||
                  element
                    .toString()
                    .includes(
                      "https://firebasestorage.googleapis.com" /*this could also be the word images? as string*/
                    ) ? (
                    <Link
                      to={`/UserView/${
                        followers.Directory[0 + secondindex / 3]
                      }`}
                    >
                      <img
                        style={{
                          border: "1.5px solid rgb(84, 188, 226)",
                          borderRadius: "50%",
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                        }}
                        src={element}
                      />
                    </Link>
                  ) : typeof element === "number" ? (
                    <span>{element}</span>
                  ) : (
                    <span>{element}</span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        //</div>
      )}
    </div>
  );
}

export default Notification;
